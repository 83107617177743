
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AssetNodeActionMenu from '@/components/asset-services/asset-edit/asset-node-action-menu.vue';
import RenameEntityModal from '@/components/asset-services/asset-edit/modals/rename-entity-modal.vue';
import AssetHelperMethods from '@/components/asset-services/business-logic/asset-helper-methods';
import { AssetNodeAction, NodeType } from '@/components/asset-services/models/asset-enums';
import {
  AssetInstanceProperty,
  IAssetNode,
  IAssetInstanceFull,
  AssetInstance
} from '@/components/asset-services/models/asset-interfaces';
import {
  BlueprintProperties,
  BlueprintPropertySourceType,
  BlueprintPropertyTypeGroup
} from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint, IBlueprintProperty } from '@/components/asset-services/models/blueprint-interfaces';
import CheckboxListModal from '@/components/common/checkbox-list-modal.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { getBlueprintProperties } from '@/utils/blueprints-helper';

@Component({
  name: 'asset-hierarchy-node',
  components: {
    AssetNodeActionMenu,
    CheckboxListModal,
    RenameEntityModal
  }
})
export default class AssetHierarchyNode extends Vue {
  @Prop({ required: true })
  public node: IAssetNode;

  public expanded: boolean = false;
  private NodeType = NodeType;
  private childrenLoaded: boolean = false;
  private showLoader: boolean = false;
  private showRenameEntityModal: boolean = false;
  private focusNodes: string[] = [];

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private focusId(id): string {
    // replacing characters because css . operator
    return `${id
      .replaceAll('.', '-')
      .replaceAll('[', '')
      .replaceAll(']', '')}`;
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get isSelected(): boolean {
    return this.node.nodeId === this.assetStore.selectedNode?.nodeId;
  }

  private get isRootNode(): boolean {
    return this.node.elementId === this.assetStore.selectedAssetContext.entityId;
  }

  private get nodeName(): string {
    if (this.node.type === NodeType.Asset) {
      return (this.node.element as IAssetInstanceFull).name?.value;
    }
    return this.node.propName;
  }

  private get blueprint(): IBlueprint {
    return this.blueprintStore.allAssetBlueprints.find((blueprint) => blueprint.id === this.node.blueprintId);
  }

  private get optionalPropertyOptions(): Array<{ text: string; value: IBlueprintProperty }> {
    return getBlueprintProperties(this.blueprint)
      .filter((prop) => prop.isOptional)
      .map((blueprint) => ({ text: blueprint.name, value: blueprint }))
      .sort((a, b) => HelperMethods.sortString(a.text, b.text));
  }

  private get selectedOptionalProperties(): IBlueprintProperty[] {
    const blueprintProperties = getBlueprintProperties(this.blueprint);
    const selected = blueprintProperties.filter((prop) => prop.isOptional && this.node.element[prop.name]);
    return selected.map((blueprint) => blueprint);
  }

  private get nodeActionOptions(): AssetNodeAction[] {
    const options: Array<{ order: number; value: AssetNodeAction }> = [];
    if (this.node.type === NodeType.Asset) {
      options.push({ order: 3, value: AssetNodeAction.EditName });
    } else if (this.node.type === NodeType.ListProperty) {
      options.push({ order: 1, value: AssetNodeAction.AddListItem });
    } else if (this.node.type === NodeType.ListItem) {
      options.push({ order: 4, value: AssetNodeAction.RemoveListItem });
    }
    if (this.node.type !== NodeType.ListProperty && this.optionalPropertyOptions.length > 0) {
      options.push({ order: 2, value: AssetNodeAction.EditOptionalProperties });
    }

    options.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });

    return options.map((item) => item.value);
  }

  private get childNodes(): IAssetNode[] {
    return [...this.propertyNodes, ...this.listItemNodes];
  }

  private get listItemNodes(): IAssetNode[] {
    if (this.node.type !== NodeType.ListProperty) {
      return [];
    }
    return (this.node.element as AssetInstanceProperty[]).map((property, index) => ({
      nodeId: `${this.node.nodeId}.[${index}]`,
      blueprintId: this.node.blueprintId,
      type: NodeType.ListItem,
      element: property.value as IAssetInstanceFull,
      propName: this.node.propName,
      propOptional: false,
      listIndex: index
    }));
  }

  private get propertyNodes(): IAssetNode[] {
    if (this.node.type === NodeType.ListProperty) {
      return [];
    }
    return getBlueprintProperties(this.blueprint)
      .filter(
        (property) =>
          property.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint &&
          property.name !== BlueprintProperties.Formulas &&
          property.name !== BlueprintProperties.Tags &&
          this.node.element[property.name] != null
      )
      .map((property) => {
        const assetProp: AssetInstanceProperty | AssetInstanceProperty[] = this.node.element[property.name];
        const propertyType = AssetHelperMethods.getPropertyType(property, assetProp);
        return {
          nodeId: `${this.node.nodeId}.${property.name}`,
          blueprintId: propertyType.blueprintType.type,
          type: property.isList ? NodeType.ListProperty : NodeType.Property,
          element: property.isList
            ? (assetProp as AssetInstanceProperty[])
            : ((assetProp as AssetInstanceProperty).value as IAssetInstanceFull),
          propName: property.name,
          propOptional: property.isOptional
        };
      })
      .sort(this.sortPropertyNodes);
  }

  private mounted(): void {
    if (this.isRootNode) {
      this.expanded = true;
      this.childrenLoaded = true;
    }
  }

  private async expandCollapse(): Promise<void> {
    if (!this.childrenLoaded) {
      this.showLoader = true;
      await HelperMethods.delay(200);
      this.childrenLoaded = true;
      this.showLoader = false;
    }
    this.$nextTick(() => {
      this.expanded = !this.expanded;
    });
  }

  private async expandChildren(childIds: string[]): Promise<void> {
    const assetHierarchyNodes = this.$refs.assetHierarchyNode as AssetHierarchyNode[];
    const childNodes = assetHierarchyNodes?.filter((assetHierarchyNode) =>
      childIds.includes(assetHierarchyNode.node.elementId)
    );
    if (childNodes) {
      await Promise.all(
        childNodes.map(async (childNode) => {
          childNode.expanded = true;
        })
      );
    }
  }

  private sortPropertyNodes(first: IAssetNode, second: IAssetNode): number {
    const firstSortKey = first.propName;
    const secondSortKey = second.propName;
    return firstSortKey.localeCompare(secondSortKey, undefined, { numeric: true });
  }

  private selectNode(): void {
    if (this.node.type !== NodeType.ListProperty) {
      this.assetStore.setSelectedNode(this.node);
    }
  }

  private handleMenuSelection(action: AssetNodeAction): void {
    switch (action) {
      case AssetNodeAction.EditName:
        this.showRenameEntityModal = true;
        break;
      case AssetNodeAction.EditOptionalProperties:
        (this.$refs['checkbox-list-modal'] as CheckboxListModal).displayModal();
        break;
      case AssetNodeAction.AddListItem:
        this.addListItem();
        break;
      case AssetNodeAction.RemoveListItem:
        this.$emit('remove', this.node.listIndex);
        break;
      default:
        return;
    }
  }

  private editName(newName: string): void {
    Vue.set((this.node.element as IAssetInstanceFull).name, 'value', newName);
    // This will re-init the table and cause the form values to be updated
    this.assetStore.setSelectedNode(this.assetStore.selectedNode);
  }

  private focusOnNode(id: string): void {
    if (!this.childrenLoaded && this.showLoader && !this.expanded) {
      this.focusNodes.push(id);
    } else {
      const nodeInfoSelector = `#${id} > .node-info`;
      const optionalSelector = `#${id} > .node-info > .optional`;
      const newNode = this.$el.querySelector(nodeInfoSelector);
      const newProperty = this.$el.querySelector(optionalSelector);

      if (newProperty) {
        newProperty.classList.add('fade-out-active');
        this.$nextTick(() => {
          newProperty.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // remove the class so the animation does not appear again the timing = duration in the css animation.
          HelperMethods.delay(5000).then(() => newProperty.classList.remove('fade-out-active'));
        });
      }
      if (newNode) {
        newNode.classList.add('fade-out-active');
        this.$nextTick(() => {
          newNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // remove the class so the animation does not appear again the timing = duration in the css animation.
          HelperMethods.delay(5000).then(() => newNode.classList.remove('fade-out-active'));
        });
      }
    }
  }

  @Watch('childrenLoaded')
  @Watch('expanded')
  @Watch('showLoader')
  private focusAfterNodesLoaded() {
    if (this.childrenLoaded && !this.showLoader && this.expanded && this.focusNodes.length > 0) {
      this.focusNodes.forEach((id) => this.focusOnNode(this.focusId(id)));
      this.focusNodes = [];
    }
  }

  private addListItem(): void {
    const listEntryValue = new AssetInstance();
    AssetHelperMethods.setAssetProperties(listEntryValue, this.blueprint, this.blueprintStore.allAssetBlueprints);
    const listEntryProperty = new AssetInstanceProperty({
      value: listEntryValue,
      propertySource: BlueprintPropertySourceType.Static,
      propertyTypeGroup: BlueprintPropertyTypeGroup.Blueprint,
      propertyType: {
        basicType: null,
        blueprintType: {
          blueprintIdentifier: this.blueprint.id,
          referenceType: 'Import',
          version: this.blueprint.version,
          type: this.blueprint.id
        }
      },
      tagName: null,
      formula: null
    });
    (this.node.element as AssetInstanceProperty[]).push(listEntryProperty);
    if (!this.expanded) {
      this.expandCollapse();
    }
    this.$nextTick(() => {
      this.focusOnNode(
        `${this.focusId(this.node.nodeId)}-${(this.node.element as AssetInstanceProperty[]).length - 1}`
      );
    });
  }

  private removeListItem(index: number): void {
    (this.node.element as AssetInstanceProperty[]).splice(index, 1);
    // Unselect node incase we were nested underneath
    this.assetStore.setSelectedNode(null);
  }

  private editOptionalProperties(selectedOptions: IBlueprintProperty[]): void {
    const optionsToAdd = selectedOptions.filter((option) => !this.selectedOptionalProperties.includes(option));
    const optionsToRemove = this.selectedOptionalProperties.filter((option) => !selectedOptions.includes(option));
    optionsToAdd.forEach((option) => this.addProperty(option));
    optionsToRemove.forEach((option) => this.removeProperty(option.name));
    if (this.node.nodeId === this.assetStore.selectedNode?.nodeId) {
      // This will re-init the table and cause the form values to be updated
      this.assetStore.setSelectedNode(this.assetStore.selectedNode);
    } else if (optionsToRemove.some((prop) => prop.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint)) {
      // Unselect node incase we were nested underneath
      this.assetStore.setSelectedNode(null);
    }
  }

  private addProperty(blueprintProperty: IBlueprintProperty): void {
    const entity = this.node.element as IAssetInstanceFull;
    const allAssetBlueprints = this.blueprintStore.allAssetBlueprints;
    AssetHelperMethods.setAssetProperty(entity, this.blueprint, blueprintProperty, allAssetBlueprints, false);
    if (!this.expanded) {
      this.expandCollapse();
    }
    this.$nextTick(() => {
      this.focusOnNode(`${this.focusId(this.node.nodeId)}-${blueprintProperty.name}`);
    });
  }

  private removeProperty(propName: string): void {
    Vue.delete(this.node.element, propName);
  }
}
