
import { Component, Vue } from 'vue-property-decorator';
import BreadCrumb, { IBreadCrumbItem } from '@/components/common/bread-crumb.vue';
import store, { getAssetServiceStoreModule, getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetServiceModule from '@/store/asset-service-store/asset-service-store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'asset-service-landing-page',
  components: {
    BreadCrumb
  }
})
export default class AssetServiceLandingPage extends Vue {
  private get sBreadCrumbItems(): IBreadCrumbItem[] {
    return [
      {
        text: this.$t('global.utilities', { action: '' }).toString(),
        href: `${this.getOrigin()}/utilities`
      },
      {
        text: this.$t('assetService.title', { action: '' }).toString(),
        to: '/'
      }
    ];
  }

  private get currentRoute(): string {
    return this.$route.name;
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetServiceStore(): AssetServiceModule {
    return getAssetServiceStoreModule(store);
  }

  private async created(): Promise<void> {
    // Load user
    await this.assetServiceStore.getUser().catch((err) => showError(err));

    // Load blueprints
    this.blueprintStore.setBlueprintsLoading(true);
    this.blueprintStore
      .fetchBlueprintSchema()
      .catch((err) => showError(err))
      .finally(() => this.blueprintStore.setBlueprintsLoading(false));

    // TODO: Load assets
    // this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.loading'));
    // this.assetStore
    //   .loadAssets(this.assetServiceStore.user?.activeCustomerKey)
    //   .catch((err) => showError(err))
    //   .finally(() => this.assetStore.setAssetsLoadingMessage(null));
  }

  private getOrigin(): string {
    if (!window.location.origin) {
      const port = window.location.port ? `:${window.location.port}` : '';
      return `${window.location.protocol}//${window.location.hostname}${port}`;
    }
    return window.location.origin;
  }

  private navigate(navKey: string): void {
    this.$router.push(navKey);
  }
}
