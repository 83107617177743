
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'onpoint-modal'
})
export default class OnpointModal extends Vue {
  @Prop({ required: true })
  private id?: string;
  @Prop({ required: true, default: false })
  private isOpen: boolean;
  @Prop({ required: false })
  private title?: string;
  @Prop({ required: false })
  private buttonOk?: string;
  @Prop({ required: false })
  private buttonCancel?: string;
  @Prop({ required: false })
  private size?: string;
  @Prop({ required: false })
  private onOk?: () => {};
  @Prop({ required: false })
  private onCancel?: () => {};
  @Prop({ required: false })
  private onClose?: () => {};
  @PropSync('progress', { required: false })
  private showProgress?: boolean;
}
