export enum AssetType {
  Heater = 'Heater'
}

export enum AssetState {
  Draft = 'Draft',
  Published = 'Published'
}

export enum ResourceType {
  Undefined = 'Undefined',
  Entity = 'Entity',
  Relationship = 'Relationship'
}

export enum NodeType {
  Asset = 'Asset',
  ListItem = 'ListItem',
  ListProperty = 'ListProperty',
  Property = 'Property'
}

export enum AssetNodeAction {
  AddListItem = 'AddListItem',
  EditName = 'EditName',
  EditOptionalProperties = 'EditOptionalProperties',
  RemoveListItem = 'RemoveListItem'
}
