
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import {
  IBurnerViewModel,
  IDrillingViewModel,
  IManifoldViewModel, IPortViewModel,
  ITipViewModel
} from '../../view-models/burner-view-model';
import SearchBox from '../search/SearchBox.vue';
import { v4 as uuid } from 'uuid';
import { ValidationField, validateForm } from '../../utils/Validator';
import Loading from '../common/Loading.vue';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component({
  components: {
    SearchBox,
    Loading
  }
})
export default class BurnerCreation extends Vue {
  public showPicker: boolean = false;
  public isSaving: boolean = false;
  private selectedAsset = store.state.assetState.selectedAsset;
  private burners: string[] = [];
  private burnerTypes = ['flowelements.burner_k_factor'];
  private burnerToCreate: IBurnerViewModel = {
    burnerKey: uuid(),
    burnerName: '',
    burnerDetail: {
      zoneType: '',
      config: {
        airRegisters: [
          {
            damperArea: 1,
            number: 0,
            damperKValues: [],
            damperKValue: 1,
            damperAreas: [],
            isPrimary: true,
            name: 'Air Register - 1',
            subGroupKey: ''
          }
        ],
        customFields: [
          {
            key: 'BurnerArea',
            value: '0'
          },
          {
            key: 'BurnerK',
            value: '0'
          },
          {
            key: 'Uncertainty',
            value: '0'
          }
        ],
      },
      drilling: [this.emptyHeader()]
    }
  };
  private validateForm = validateForm;
  private validations: {[key: string]: ValidationField} = {
    burnerKey: {
      valid: true,
      warning: false,
      value: () => this.burnerToCreate.burnerKey,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    burnerName: {
      valid: true,
      warning: false,
      value: () => this.burnerToCreate.burnerName,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    zoneType: {
      valid: true,
      warning: false,
      value: () => this.burnerToCreate.burnerDetail.zoneType,
      validator: (value: string) => value !== '',
      setWarning: () => false
    }
  };

  constructor() {
    super();
    if (typeof this.selectedAsset !== 'undefined' && typeof this.selectedAsset.burnerList !== 'undefined') {
      this.burners = this.selectedAsset.burnerList.map((x) => x.burnerName);
      this.burnerTypes = this.selectedAsset.burnerList.map((x) => x.burnerDetail.zoneType) as string[];
      this.burnerTypes.push('flowelements.burner_k_factor');
      this.burnerTypes = Array.from(new Set(this.burnerTypes));
    }
  }

  public hidePicker(): void {
    if (this.showPicker === true) {
      this.showPicker = false;
    }
  }

  @Watch('burnerToCreate.burnerName')
  private onBurnerNameChange(): void {
    this.validateForm(this.validations, ['burnerName']);
  }

  private cancel(): void {
    if (!this.isSaving) {
      this.clearBurnerToCreate();
      this.$router.push(store.state.navState.prevNavKey);
      store.state.navState.prevNavKey = 'burner-creation';
    }
  }

  private async createBurner(): Promise<void> {
    if (this.isSaving) {
      return;
    } else {
      this.isSaving = true;
      if (this.validateForm(this.validations, Object.keys(this.validations)) &&
          this.selectedAsset && this.selectedAsset.burnerList) {
        this.selectedAsset.burnerList.push(this.burnerToCreate);
        try {
          await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
          store.commit('assetState/updateSelectedAsset', this.selectedAsset.key);
          store.commit('assetState/updateSelectedBurner', this.burnerToCreate);
          this.clearBurnerToCreate();
          store.state.navState.prevNavKey = 'burner-creation';
          await this.$router.push('asset-details');
        } catch (err) {
          // Need to remove burnerToCreate from burnerList
          if (typeof this.selectedAsset !== 'undefined' && typeof this.selectedAsset.burnerList !== 'undefined') {
            this.selectedAsset.burnerList.pop();
          }
          showError(err.message);
        }
      } else {
        store.commit('errorState/setError', {
          error: 'Form Validation Failed: Please input a valid value for all fields.',
          config: {
            sendLog: false
          },
          uiError: true
        });
      }
      this.isSaving = false;
    }
  }

  private clearBurnerToCreate(): void {
    this.burnerToCreate = {
      burnerKey: uuid(),
      burnerName: '',
      burnerDetail: {
        config: {
          customFields: [
            {
              key: 'BurnerArea',
              value: '0'
            },
            {
              key: 'BurnerK',
              value: '0'
            },
            {
              key: 'DamperArea',
              value: '0'
            },
            {
              key: 'Uncertainty',
              value: '0'
            }
          ],
        },
        drilling: [this.emptyHeader()]
      }
    };
  }

  private emptyHeader(): IDrillingViewModel {
    return {
      headerKey: uuid(),
      name: 'Fuel Gas - 1',
      isCriticalFuelLine: true,
      grouping: '',
      manifolds: this.emptyManifold(),
      subGroupKey: '',
      referenceKey: ''
    };
  }

  private emptyManifold(): IManifoldViewModel[] {
    return [
      {
        name: 'Manifold Name',
        doubleDrop: false,
        diameter: '2.067',
        diameterUnit: 'INCH',
        doubleDropDiameterUnit: 'INCH',
        doubleDropCd: '0.9',
        doubleDropDiameter: '0.003175',
        tips: this.emptyTip()
      }
    ];
  }

  private emptyTip(): ITipViewModel[] {
    return [
      {
        name: 'Tip Name',
        diameterUnit: 'INCH',
        diameter: '1.063',
        cd: '0.85',
        number: 1,
        doubleDrop: false,
        doubleDropDiameterUnit: 'INCH',
        doubleDropDiameter: '0.003175',
        doubleDropCd: '0.9',
        ports: this.emptyPort()
      }
    ];
  }

  private emptyPort(): IPortViewModel[] {
    return [
      {
        name: 'PortName',
        diameterUnit: 'INCH',
        diameter: '0.0015875',
        number: 1
      }
    ];
  }
}
