
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IAsset } from '../../view-models/assets-view-models';
import SearchBox from '../search/SearchBox.vue';
import HelperMethods from '@/shared/helper-methods';
import { EntityType } from '@/view-models/agreement-view-model';

Vue.use(BootstrapVue);

@Component({
  components: {SearchBox}
})
export default class ShowAll extends Vue {
  private recent: IAsset[] = [];
  private displayCount = 10;
  private totalCount = 0;
  private sites: string[] = [];
  private selectedSite = '';
  private searchQuery = '';
  private thStyle = {
    backgroundColor: '#2A496D',
    border: 0,
    cursor: 'pointer'
  };
  private recentFields = [
    {
      key: 'equipmentName',
      label: 'Asset',
      thStyle: this.thStyle,
      sortable: true
    },
    {
      key: 'site',
      label: 'Site',
      thStyle: this.thStyle,
      sortable: true
    },
    {
      key: 'customerName',
      label: 'Customer',
      thStyle: this.thStyle
    },
    {
      key: 'lastUpdatedDate',
      label: 'Last Updated',
      thStyle: this.thStyle,
      sortable: true
    },
    {
      key: 'lastUpdatedBy',
      label: 'Last Updated By',
      thStyle: this.thStyle,
      sortable: true
    }
  ];

  public async created(): Promise<void> {
    if (HelperMethods.isArrayEmpty(store.state.assetState.availableAssignments)) {
      await store.dispatch('assetState/retrieveServiceConfig');
      await store.dispatch('assetState/retrieveAssets');
    }
    const availableSites = store.state.assetState.availableAssignments
      .filter((assignment) => assignment.type === EntityType.CustomerSite);
    this.sites = this.sites.concat(availableSites.map((x) => x.name));
    this.sites.sort(HelperMethods.sortString);
    this.sites.unshift('All Sites');
    this.selectedSite = 'All Sites';
  }

  public mounted(): void {
    this.filter();
  }

  private async assetDetails(assetKey: string): Promise<void> {
    store.commit('assetState/updateSelectedAsset', assetKey);
    await this.$router.push('asset-details');
    store.state.navState.prevNavKey = 'show-all';
  }

  private assetCreation(): void {
    this.$router.push('asset-creation');
    store.state.navState.prevNavKey = 'show-all';
  }

  private back(): void {
    this.$router.push('/');
    store.state.navState.prevNavKey = 'show-all';
  }

  @Watch('selectedSite')
  @Watch('displayCount')
  private filter(): void {
    this.recentAssets();
    this.recent = this.recent.filter((x) =>
      x.equipmentName.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1);
    if (this.selectedSite !== '' && this.selectedSite !== 'All Sites') {
      this.recent = this.recent.filter((x) => x.site === this.selectedSite);
    }
    this.totalCount = this.recent.length;
    if (this.displayCount < this.totalCount) {
      this.recent = this.recent.slice(0, this.displayCount);
    }
  }

  @Watch('store.state.assetState.assets.length', { immediate: true, deep: true })
  private recentAssets(): void {
    store.state.assetState.assets.sort((x, y) => new Date(y.lastUpdatedDate).getTime()
      - new Date(x.lastUpdatedDate).getTime());
    this.recent = store.state.assetState.assets;
  }
}
