
import OnpointModal from '../common/OnpointModal.vue';
import {
  DamperKConfig,
  IAirRegister,
  IBurnerViewModel,
  IDamperValuesViewModel,
  IFilterOptions
} from '@/view-models/burner-view-model';
import store from '../../store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '../utility/Dropdown.vue';
import { showError } from '@/utils/StoreHelper';
import HelperMethods from '@/shared/helper-methods';
import { IBurnerLayoutViewModel } from '@/view-models/burner-layout-view-model';
import EditDamperValues from './EditDamperValues.vue';

@Component({
  name: 'AirRegister',
  components: { EditDamperValues, Dropdown, OnpointModal }
})
export default class AirRegister extends Vue {
  private selectedAsset = store.state.assetState.selectedAsset;
  private formFocussed: boolean = true;
  private pasteModalIsOpen: boolean = false;
  private pastedDamperSettings: string = '';
  private config: DamperKConfig = {
    Step: 0
  };
  private options = [
    { value: 0, text: 'Please select an option' },
    { value: 0.25, text: '0.25' },
    { value: 0.5, text: '0.5' },
    { value: 1, text: '1' }
  ];
  private damperArea?: number = 1;
  private damperKValue?: number = 1;
  private validationsSet: boolean = false;
  @Prop({ required: true })
  private airRegister: IAirRegister;
  @Prop({ required: true })
  private selectedBurnerData: IBurnerViewModel;
  private editName: boolean = false;
  private filterOptions: IFilterOptions[] = [];
  private currentAirRegisterName = '';
  private showErrorMessage: boolean = false;
  private showPrimaryModal: boolean = false;
  private errorMessage: string = '';
  private deletePrimarMessage: string = '';
  private showConfirm = false;

  get airRegistersList(): IAirRegister[] {
    return this.selectedBurner.burnerDetail.config.airRegisters;
  }

  get airRegisterNumber(): number {
    return this.airRegister.number;
  }

  get airRegisterName(): string {
    return this.currentAirRegisterName;
  }

  set airRegisterName(val: string) {
    this.currentAirRegisterName = val;
  }

  get subGroupKeyValue(): string {
    if (
      this.hasMappingSubGroups &&
      this.selectedBurner.burnerDetail.mappingSubGroups.some((map) => map.key === this.airRegister.subGroupKey)
    ) {
      return this.airRegister.subGroupKey;
    }
    return '';
  }

  set subGroupKeyValue(val: string) {
    this.airRegister.subGroupKey = val;
  }

  get selectedBurner(): IBurnerViewModel {
    return store.state.assetState.selectedBurner;
  }

  get hasMappingSubGroups(): boolean {
    return this.selectedBurner.burnerDetail.mappingSubGroups
      ? this.selectedBurner.burnerDetail.mappingSubGroups.length > 0
      : false;
  }

  get getFilterOptions(): IFilterOptions[] {
    if (this.hasMappingSubGroups) {
      this.selectedBurner.burnerDetail.mappingSubGroups.forEach((item) => {
        this.filterOptions.push({
          value: item.key,
          text: item.name
        });
      });
    }
    this.filterOptions.sort((a, b) => {
      return HelperMethods.sortString(a.text, b.text);
    });
    this.filterOptions.unshift({
      value: '',
      text: 'Select a Subgroup'
    });
    this.filterOptions = this.filterOptions.filter(
      (obj, index, item) => item.findIndex((item1) => item1.value === obj.value) === index
    );
    return this.filterOptions;
  }

  get primarySelection(): string {
    return this.airRegister.isPrimary ? this.airRegister.key || this.airRegister.name : '';
  }

  get burnerLayout(): IBurnerLayoutViewModel[] {
    return store.state.assetState.selectedBurner.burnerDetail.burnerLayout;
  }

  get hasBurnerLayout(): boolean {
    return this.burnerLayout !== null;
  }

  private pasteDamperSettings(): void {
    const splitString = this.pastedDamperSettings.split('\n');
    const conversionErrors: string[] = [];
    const settings = this.convertToKeyValuePairs(splitString, conversionErrors);
    if (!HelperMethods.isArrayEmpty(conversionErrors)) {
      store.commit('errorState/setError', {
        error: conversionErrors.join('\n'),
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
    if (this.validateSettings(settings)) {
      if (this.hasMultipleDamperK()) {
        store.commit('assetState/updateAirRegisterDamperK', {
          damperK: settings,
          airRegisterNumber: this.airRegisterNumber
        });
        this.saveSelectedAsset();
      } else {
        store.commit('assetState/updateAirRegisterDamperAreas', {
          damperAreas: settings,
          airRegisterNumber: this.airRegisterNumber
        });
        this.saveSelectedAsset();
      }
    }
  }

  private validateSettings(settings: IDamperValuesViewModel[]): boolean {
    const isValid: boolean = true;
    let error: string = null;
    if (settings.length <= 0) {
      error = "No valid settings found.";
    } else if (!this.isSorted(settings)) {
      error = "Settings must be numerically sorted and have unique Keys.";
    } else if (!this.allKeysAndValuesAreNumbers(settings)) {
      error = "Keys and Values must all be numeric.";
    }
    if (error != null) {
      store.commit('errorState/setError', {
        error,
        config: {
          sendLog: false
        },
        uiError: true
      });
      return false;
    }

    this.pasteModalIsOpen = false;
    return isValid;
  }

  private allKeysAndValuesAreNumbers(arr: IDamperValuesViewModel[]): boolean {
    let allAreNumbers = true;

    arr.forEach((pair) => {
      if (isNaN(parseFloat(pair.key)) || isNaN(parseFloat(pair.value || ''))) {
        allAreNumbers = false;
      }
    });

    return allAreNumbers;
  }

  private isSorted(arr: IDamperValuesViewModel[]): boolean {
    let sorted: boolean = true;
    for (let i = 0; i < arr.length - 1; i++) {
      if (Number(arr[i].key) >= Number(arr[i + 1].key)) {
        sorted = false;
        break;
      }
    }
    return sorted;
  }

  private convertToKeyValuePairs(damperArray: string[], errors: string[]): IDamperValuesViewModel[] {
    const keyValuePairs: IDamperValuesViewModel[] = [];
    const regex = /-?\d*\.?\d+/g;
    damperArray.forEach((line: string, index: number) => {
      const matches = line.match(regex);
      if (matches.length != 2) {
        errors.push(`Invalid number of numeric inputs found on line ${index + 1}. Expected 2, but found ${matches.length}: ${matches}.`);
      } else {
        const key = HelperMethods.roundDecimalString(matches[0], 2);
        const value = HelperMethods.roundDecimalString(matches[1], 2);
        keyValuePairs.push({ key, value });
      }
    });
    return keyValuePairs;
  }

  private closePasteModal(): void {
    this.pasteModalIsOpen = false;
  }

  private resetDamperSettings(): void {
    this.validationsSet = false;
    this.airRegister.subGroupKey = '';
    if (this.selectedBurner && this.selectedBurner.burnerDetail.config) {
      if (this.hasMultipleDamperK()) {
        this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperKValues = [];
      } else {
        this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperAreas = [];
      }
      this.saveSelectedAsset();
    }
  }

  private updateDamperArea(): void {
    store.commit('assetState/updateDamperArea', {
      damperArea: this.damperArea,
      airRegisterNumber: this.airRegisterNumber
    });
    this.saveSelectedAsset();
  }

  private updateDamperKValue(): void {
    store.commit('assetState/updateDamperKValue', {
      damperKValue: this.damperKValue,
      airRegisterNumber: this.airRegisterNumber
    });
    this.saveSelectedAsset();
  }

  private confirmUpdateName(): void {
    if (this.airRegister.name !== this.airRegisterName) {
      this.showConfirm = true;
    } else {
      this.updateName();
    }
  }

  private updateName(): void {
    this.airRegisterName = this.airRegisterName.replace(/\s+/g, ' ').trim();
    const newNameIndex = this.airRegistersList.findIndex((item) =>
      HelperMethods.equalsIgnoreCase(item.name ? item.name : '', this.airRegisterName ? this.airRegisterName : '')
    );
    const currentName = this.airRegistersList.find((item) => item.number === this.airRegister.number).name;
    if (
      (newNameIndex > -1 && !HelperMethods.equalsIgnoreCase(this.airRegisterName, currentName)) ||
      HelperMethods.isStringEmpty(this.airRegisterName)
    ) {
      this.showEditName();
    } else if (
      !HelperMethods.equalsIgnoreCase(this.airRegisterName, currentName) &&
      !HelperMethods.isStringEmpty(this.airRegisterName)
    ) {
      store.commit('assetState/updateAirRegisterName', {
        name: this.airRegisterName,
        airRegisterNumber: this.airRegisterNumber
      });
      this.updateAirRegisterName();
      this.saveSelectedAsset();
      this.editName = false;
    } else {
      this.editName = false;
    }
    this.showConfirm = false;
  }

  private validateName(): void {
    const newNameIndex = this.airRegistersList.findIndex((item) =>
      HelperMethods.equalsIgnoreCase(item.name ? item.name : '', this.airRegisterName ? this.airRegisterName : '')
    );
    const currentName = this.airRegistersList.find((item) => item.number === this.airRegister.number).name;
    if (this.airRegisterName === '') {
      this.showErrorMessage = true;
      this.errorMessage = 'Air Register name is required';
    } else if (newNameIndex > -1 && !HelperMethods.equalsIgnoreCase(this.airRegisterName, currentName)) {
      this.showErrorMessage = true;
      this.errorMessage = 'Air Register name already exists';
    } else {
      this.showErrorMessage = false;
    }
  }

  private setSubGroupValue(data: string): void {
    this.subGroupKeyValue = data;
    store.commit('assetState/updateSubGroup', { name: data, airRegisterNumber: this.airRegisterNumber });
    this.saveSelectedAsset();
  }

  private showEditName(): void {
    this.editName = true;
    this.$nextTick().then(() => {
      const input: HTMLElement = this.$refs.AirRegisterName as HTMLElement;
      input.focus();
    });
  }

  get getSorted(): IDamperValuesViewModel[] {
    if (this.hasMultipleDamperK()) {
      if (
        this.selectedBurner &&
        this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperKValues.length > 0
      ) {
        return this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperKValues.sort(
          (a, b) => Number(a.key) - Number(b.key)
        );
      }
    } else {
      if (
        this.selectedBurner &&
        this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperAreas.length > 0
      ) {
        return this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperAreas.sort(
          (a, b) => Number(a.key) - Number(b.key)
        );
      }
    }
  }

  private validateDamperField(value: string): boolean {
    return this.validationsSet && value === '0';
  }

  private updateDamperSettings(damperSettings: IDamperValuesViewModel[]): void {
    if (this.hasMultipleDamperK()) {
      store.commit('assetState/updateAirRegisterDamperK', {
        damperK: damperSettings,
        airRegisterNumber: this.airRegisterNumber
      });
    } else {
      store.commit('assetState/updateAirRegisterDamperAreas', {
        damperAreas: damperSettings,
        airRegisterNumber: this.airRegisterNumber
      });
    }
    this.saveSelectedAsset();
  }

  private fieldFocused(): void {
    this.formFocussed = true;
  }

  private unfocusForm(): void {
    this.formFocussed = false;
    setTimeout(() => {
      if (!this.formFocussed) {
        this.validationsSet = true;
      }
    }, 0);
  }

  private formatDamperSetting(value: any): boolean {
    return value === '' ? '0' : value.toString();
  }

  private async saveSelectedAsset(): Promise<void> {
    try {
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
    } catch (err) {
      showError(err.message);
    }
  }

  private pasteVal(): void {
    this.pasteModalIsOpen = true;
  }

  private deleteAirRegister(): void {
    if (this.airRegister.isPrimary && this.airRegistersList.length === 1) {
      this.showPrimaryModal = true;
      this.deletePrimarMessage = `You are about to delete a primary air register : ${this.airRegister.name}.
        This would make given burner type : ${this.selectedBurner.burnerName} assigned without any air register. Do you wish to continue?`;
    } else if (this.airRegister.isPrimary && this.airRegistersList.length > 1) {
      this.showPrimaryModal = true;
      this.deletePrimarMessage = `You are about to delete a primary air register : ${this.airRegister.name}.
        System recommends to set another air register as primary before this operation else it would self-assign first air register from the available list as primary. Do you wish to continue?`;
    } else {
      this.deleteConfirmed();
    }
  }

  private deleteConfirmed(): void {
    if (this.airRegister.isPrimary && this.airRegistersList.length > 1) {
      store.commit('assetState/selectPrimaryAirRegister', {
        isChecked: true,
        airRegisterNumber: this.airRegisterNumber === 0 ? 1 : 0
      });
    }
    store.commit('assetState/deleteAirRegister', this.airRegisterNumber);
    this.updateBurnerLayout();
    this.updateSelectedAsset();
    this.saveSelectedAsset();
    this.closePrimaryModal();
    if (this.hasBurnerLayout) {
      this.$emit('update-air-register', true);
    }
  }

  private updateBurnerLayout(): void {
    if (this.hasBurnerLayout) {
      store.commit('assetState/updateBurnerLayoutWithDeleteAirRegisterFuelLineChanges', this.airRegister.key);
    }
  }

  private updateSelectedAsset(): void {
    store.commit('assetState/updateSelectedAssetWithBurner');
  }

  @Watch('airRegistersList')
  private watchAirRegisters(): void {
    if (this.airRegistersList.length > 0) {
      this.airRegisterName = this.airRegister.name;
    }
  }

  private mounted(): void {
    if (this.selectedBurner) {
      if (this.hasMultipleDamperK()) {
        this.damperArea = this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperArea;
      } else {
        this.damperKValue = this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperKValue;
      }
      if (this.getSorted != null && this.getSorted.length > 0) {
        this.validationsSet = true;
        if (this.hasMultipleDamperK()) {
          this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperKValues = this.getSorted;
        } else {
          this.selectedBurner.burnerDetail.config.airRegisters[this.airRegisterNumber].damperAreas = this.getSorted;
        }
      }
    }
    if ('name' in this.airRegister) {
      if (this.airRegister.name !== '') {
        this.airRegisterName = this.airRegister.name;
      } else {
        this.airRegisterName = this.getAirRegisterName();
        this.airRegister.name = this.airRegisterName;
      }
    }
  }

  private hasMultipleDamperK(): boolean {
    return !(
      this.selectedBurner &&
      this.selectedBurner.burnerDetail.zoneType &&
      this.airRegisterNumber > 0 &&
      this.selectedBurner.burnerDetail.zoneType === 'flowelements.burner_lpmw'
    );
  }

  private saveAsPrimary(): void {
    store.commit('assetState/selectPrimaryAirRegister', { isChecked: true, airRegisterNumber: this.airRegisterNumber });
    this.saveSelectedAsset();
  }

  private getAirRegisterName(): string {
    if (this.airRegister.number === 0) {
      return 'Primary Air Register';
    } else if (this.airRegister.number === 1) {
      return 'Secondary Air Register';
    } else if (this.airRegister.number === 2) {
      return 'Tertiary Air Register';
    }
    return 'Air Register';
  }

  private updateAirRegisterName(): void {
    if (this.hasBurnerLayout) {
      store.commit('assetState/updateBurnerLayoutWithAirRegisterNameChanges', this.airRegister);
      this.updateSelectedAsset();
    }
  }

  private closePrimaryModal(): void {
    this.showPrimaryModal = false;
  }

  private reset(): void {
    this.showConfirm = false;
    this.airRegisterName = this.airRegister.name ?? this.getAirRegisterName();
    this.editName = false;
  }
}
