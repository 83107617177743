
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { ISolverCustomFieldViewModel } from '../../view-models/flow-network-view-model';
import { NetworkFlowBooleanEnums, NetworkFlowValueTypeEnums} from '../../enums/networkFlowEnums';
import {showError} from '@/utils/StoreHelper';
import { IAsset } from '@/view-models/assets-view-models';
import { cloneDeep } from 'lodash';

Vue.use(BootstrapVue);

@Component
export default class AddKey extends Vue {
  private selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
  private numeric: boolean = false;
  private isActive: boolean = false;
  private option: ISolverCustomFieldViewModel = {
    key: '',
    type: '',
    value: ''
  };

  public mounted(): void {
    this.option = {
      key: '',
      type: NetworkFlowValueTypeEnums.Boolean,
      value: NetworkFlowBooleanEnums.False
    };
  }

  private async addCustomField(): Promise<void> {
    if (!this.selectedAsset || !this.selectedAsset.flowNetwork) {
      this.resetAndHideForm();
      return;
    }
    if (!this.selectedAsset.flowNetwork.options) {
      this.selectedAsset.flowNetwork.options = [];
    }
    if (this.option.key !== '' && (this.option.value || this.option.value === '0')) {
      this.selectedAsset.flowNetwork.options.push(this.option);
      try {
        this.$emit('addKey', this.selectedAsset);
      } catch (err) {
        showError(err.message);
      }
      this.resetAndHideForm();
    } else {
      store.commit('errorState/setError',
        {error: 'Please enter a value for all fields.', uiError: true});
    }
  }

  private resetAndHideForm(): void {
    this.$bvModal.hide('addOption');
    this.option = {
      key: '',
      type: 'boolean',
      value: NetworkFlowBooleanEnums.False
    };
    this.isActive = false;
    this.numeric = false;
  }

  private toggleValueField(): void {
    this.numeric = !this.numeric;
    // to toggle type based on selection
    this.option.type = this.option.type === NetworkFlowValueTypeEnums.Boolean ?
          NetworkFlowValueTypeEnums.Numeric : NetworkFlowValueTypeEnums.Boolean;
    // to toggle value based on selection
    this.option.value = this.option.type === NetworkFlowValueTypeEnums.Numeric ? '0': this.isActive + '';
  }

  private toggleBooleanField(value: string): void {
    this.isActive = value === NetworkFlowBooleanEnums.False ? false : true;
    this.option.value = this.isActive + '';
  }
}
