
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

export interface IChipItem<T> {
  text: string;
  value: T;
}

@Component({
  name: 'chip-list-item'
})
export default class ChipListItem<T> extends Vue {
  @Prop({ required: true })
  private item: IChipItem<T>;
  @Prop({ default: true, type: Boolean })
  private removable: boolean;
  @Prop({ default: 'primary' })
  private variant: string;
  @Prop({ default: 'md' })
  private size: string;

  @Emit('remove')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private remove(chip: IChipItem<T>): void {}
}
