import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { onInitValidValues, onInitValue } from '@/components/asset-services/business-logic/asset-details-logic';
import {
  AssetInstance,
  AssetInstanceProperty,
  AssetPropertyDisplayModel,
  IAssetInstanceContext,
  IAssetInstanceFull
} from '@/components/asset-services/models/asset-interfaces';
import {
  BlueprintProperties,
  BlueprintPropertySourceType,
  BlueprintPropertyType,
  BlueprintPropertyTypeGroup
} from '@/components/asset-services/models/blueprint-enums';
import {
  IBlueprint,
  IBlueprintProperty,
  IBlueprintPropertyTypes
} from '@/components/asset-services/models/blueprint-interfaces';
import HelperMethods from '@/shared/helper-methods';
import { getBlueprintProperties } from '@/utils/blueprints-helper';

export default class AssetHelperMethods {
  public static getPropertyType(
    blueprintProp: IBlueprintProperty,
    assetProp: AssetInstanceProperty | AssetInstanceProperty[]
  ): IBlueprintPropertyTypes {
    if (HelperMethods.isNullOrUndefined(assetProp)) {
      return blueprintProp.propertyTypes[0];
    }
    if (blueprintProp.isList || Array.isArray(assetProp)) {
      const assetPropList = assetProp as AssetInstanceProperty[];
      if (HelperMethods.isArrayEmpty(assetPropList)) {
        return blueprintProp.propertyTypes[0];
      }
      return assetPropList[0].propertyType;
    }
    return assetProp.propertyType;
  }

  public static getPropertyValue(prop: AssetPropertyDisplayModel): any {
    switch (prop.propertySource) {
      case BlueprintPropertySourceType.Formula:
        return prop.formula;
      case BlueprintPropertySourceType.CustomerGenerated:
      case BlueprintPropertySourceType.OnPointGenerated:
        return prop.tagName;
      default:
        return prop.value;
    }
  }

  public static getPropertySource(
    blueprintProp: IBlueprintProperty,
    assetProp: AssetInstanceProperty | AssetInstanceProperty[]
  ): BlueprintPropertySourceType {
    if (HelperMethods.isNullOrUndefined(assetProp)) {
      return blueprintProp.defaultPropertySource;
    }
    if (blueprintProp.isList) {
      const assetList = assetProp as AssetInstanceProperty[];
      return HelperMethods.isArrayEmpty(assetList) ? blueprintProp.defaultPropertySource : assetList[0].propertySource;
    }
    return (assetProp as AssetInstanceProperty).propertySource;
  }

  public static cleanAssetForBundle(assetContext: IAssetInstanceContext): IAssetInstanceContext {
    const cleanedAssetContext = cloneDeep(assetContext);
    cleanedAssetContext.entity.Formulas = [];
    cleanedAssetContext.entity.Tags = [];
    return cleanedAssetContext;
  }

  public static setAssetProperties(
    asset: IAssetInstanceFull,
    blueprint: IBlueprint,
    nestedBlueprints: IBlueprint[]
  ): void {
    // console.log(asset);
    // console.log(blueprint);
    // console.log(nestedBlueprints);
    const blueprintProperties = getBlueprintProperties(blueprint);
    blueprintProperties.forEach((property) => this.setAssetProperty(asset, blueprint, property, nestedBlueprints));
  }

  public static setAssetProperty(
    asset: IAssetInstanceFull,
    blueprint: IBlueprint,
    property: IBlueprintProperty,
    nestedBlueprints: IBlueprint[],
    ignoreOptionalProperties = true
  ): void {
    // Ignore optional properties
    if (ignoreOptionalProperties && property.isOptional) {
      return;
    }
    let defaultValue = null;
    // Custom Business Logic
    const customValue = onInitValue(blueprint.id, property.name);
    if (customValue) {
      defaultValue = customValue;
    }
    const value = property.isList
      ? []
      : new AssetInstanceProperty({
          value: defaultValue,
          propertySource: property.defaultPropertySource,
          propertyTypeGroup: property.propertyTypeGroup,
          propertyType: property.propertyTypes[0],
          tagName: null,
          formula: null
        });
    Vue.set(asset, property.name, value);
    if (!property.isList && property.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
      Vue.set(asset[property.name], 'value', new AssetInstance());
      const correspondingBlueprint = nestedBlueprints.find((nestedBlueprint) => {
        return nestedBlueprint.id === property.propertyTypes[0].blueprintType?.blueprintIdentifier;
      });
      if (!HelperMethods.isNullOrUndefined(correspondingBlueprint)) {
        this.setAssetProperties(
          (asset[property.name] as AssetInstanceProperty).value,
          correspondingBlueprint,
          nestedBlueprints
        );
      }
    }
  }

  public static handleFormulaChange(asset: IAssetInstanceFull, prop: AssetPropertyDisplayModel, newValue: any): void {
    prop.formula = newValue;
    prop.value = null;
    prop.tagName = null;
    const assetProp = asset[prop.name] as AssetInstanceProperty;
    if (assetProp != null) {
      assetProp.formula = newValue;
      assetProp.value = null;
      assetProp.tagName = null;
    }
  }

  public static handleTagNameChange(asset: IAssetInstanceFull, prop: AssetPropertyDisplayModel, newValue: any): void {
    prop.tagName = newValue;
    prop.value = null;
    prop.formula = null;
    const assetProp = asset[prop.name] as AssetInstanceProperty;
    if (assetProp != null) {
      assetProp.tagName = newValue;
      assetProp.value = null;
      assetProp.formula = null;
    }
  }

  public static handleStaticChange(asset: IAssetInstanceFull, prop: AssetPropertyDisplayModel, newValue: any): void {
    prop.value = newValue;
    prop.tagName = null;
    prop.formula = null;
    if (prop.isList) {
      Vue.set(asset, prop.name, newValue);
    } else if (asset[prop.name] != null) {
      Vue.set(asset[prop.name], 'value', newValue);
      Vue.set(asset[prop.name], 'tagName', null);
      Vue.set(asset[prop.name], 'formula', null);
    }
  }

  public static clearFormulasTags(entity: IAssetInstanceFull): void {
    for (const propertyName in entity) {
      if (entity[propertyName].propertyTypeGroup === BlueprintPropertyTypeGroup.Basic) {
        entity[propertyName].formula = null;
        entity[propertyName].tagName = null;
      } else if (entity[propertyName].propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
        this.clearFormulasTags(entity[propertyName].value as IAssetInstanceFull);
      }
    }
  }

  public static generateAssetData(asset: IAssetInstanceFull, blueprint: IBlueprint, allAssetBlueprints: IBlueprint[]) {
    getBlueprintProperties(blueprint)
      .filter(
        (property) =>
          !property.isReadOnly &&
          property.name !== BlueprintProperties.Formulas &&
          property.name !== BlueprintProperties.Tags
      )
      .forEach((property) => {
        const assetProp = asset[property.name];
        if (!assetProp) {
          return;
        }
        if (property.propertyTypeGroup === BlueprintPropertyTypeGroup.Basic) {
          this.generateBasicTypeDate(asset, property, blueprint);
        } else if (property.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
          const type = AssetHelperMethods.getPropertyType(property, assetProp);
          const currentBlueprint = allAssetBlueprints.find((bp) => bp.id === type.blueprintType?.type);
          if (!property.isList) {
            this.generateAssetData(
              asset[property.name].value as IAssetInstanceFull,
              currentBlueprint,
              allAssetBlueprints
            );
          } else {
            assetProp.forEach((prop) =>
              this.generateAssetData(prop.value as IAssetInstanceFull, currentBlueprint, allAssetBlueprints)
            );
          }
        }
      });
  }

  private static generateBasicTypeDate(asset: IAssetInstanceFull, property: IBlueprintProperty, blueprint: IBlueprint) {
    if (
      (asset[property.name].propertySource === BlueprintPropertySourceType.OnPointGenerated ||
        asset[property.name].propertySource === BlueprintPropertySourceType.CustomerGenerated) &&
      HelperMethods.isStringEmpty(asset[property.name].tagName)
    ) {
      asset[property.name].tagName = property.validValues?.[0] ?? '123';
    } else if (
      asset[property.name].propertySource === BlueprintPropertySourceType.Formula &&
      HelperMethods.isStringEmpty(asset[property.name].formula)
    ) {
      asset[property.name].formula = property.validValues?.[0] ?? '123';
    } else if (
      asset[property.name].propertySource === BlueprintPropertySourceType.Static &&
      (asset[property.name].value === null || asset[property.name].value === '')
    ) {
      // Custom Business Logic
      const customValidValues = onInitValidValues(blueprint.id, property.name);
      if (customValidValues) {
        asset[property.name].value = customValidValues[0];
      } else {
        asset[property.name].value = this.generateDataFromType(property);
      }
    }
  }

  private static generateDataFromType(propertyType: IBlueprintProperty) {
    // Custom value for specific properties
    if (propertyType.name === 'Uncertainty') {
      return 0;
    }
    // Standard values
    switch (propertyType.propertyTypes[0].basicType) {
      case BlueprintPropertyType.bool:
        return true;
      case BlueprintPropertyType.double:
        return 123;
      case BlueprintPropertyType.int:
        return propertyType.validValues?.[0] ?? 123;
      case BlueprintPropertyType.dateTime:
        return '2022-03-30 12:00:00 am';
      default:
        return propertyType.validValues?.[0] ?? '123';
    }
  }
}
