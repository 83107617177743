import {
  BlueprintConstraintType,
  BlueprintPermissions,
  BlueprintProperties,
  BlueprintPropertyType,
  BlueprintPropertyTypeGroup
} from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint, IBlueprintProperty } from '@/components/asset-services/models/blueprint-interfaces';
import { Validator } from 'jsonschema';
import store from '../store';

const priorityProps = [BlueprintProperties.name];
const reservedKeywords = [BlueprintProperties.Formulas, BlueprintProperties.Tags];

export function canViewBlueprints(): boolean {
  if (store.state.assetState.user) {
    return store.state.assetState.user.permissions.includes(BlueprintPermissions.ViewBlueprints);
  } else {
    return false;
  }
}

export function canManageBlueprints(): boolean {
  if (store.state.assetState.user) {
    return store.state.assetState.user.permissions.includes(BlueprintPermissions.ManageSystemBlueprints);
  } else {
    return false;
  }
}

export function canManageNonSystemBlueprint(): boolean {
  if (store.state.assetState.user) {
    return store.state.assetState.user.permissions.includes(BlueprintPermissions.ManageNonSystemBlueprint);
  } else {
    return false;
  }
}

export function getBlueprintProperties(blueprint: IBlueprint): IBlueprintProperty[] {
  return blueprint?.structProperties ?? [];
}

export function sortBlueprintTypes(blueprint: IBlueprint): void {
  const sortedProps: IBlueprintProperty[] = [];
  priorityProps.forEach((propName: string) => {
    const propIndex = blueprint.structProperties.findIndex((prop) => prop.name === propName);
    if (propIndex >= 0) {
      const foundProp = blueprint.structProperties[propIndex];
      sortedProps.push(foundProp);
      blueprint.structProperties.splice(propIndex, 1);
    }
  });
  blueprint.structProperties = sortedProps.concat(blueprint.structProperties);
}

export function isReservedKeyword(name: string): boolean {
  return reservedKeywords.some((reserved) => reserved.toLowerCase() === name.toLowerCase());
}

export function buildFromJsonSchema(item: any, validator: Validator): any {
  let results = [];
  if (item.properties) {
    results = Object.entries(item.properties).map(([key, value]) => {
      // TODO make these types later on.
      const ref = (value as any)?.properties?.value?.$ref || (value as any)?.properties.value?.items?.$ref;
      const type = (value as any)?.properties?.propertyType?.default;
      const validValues = (value as any)?.properties?.value?.enum ?? null;
      const version = (validator.schemas[ref] as any)?.version ?? '1.0.0';
      const isSystem = (value as any)?.isSystem === 'true';
      const isOptional = !item.required.includes(key);
      const defaultPropertySource = (value as any)?.properties.propertySource.default;
      const propertySources = (value as any)?.properties.propertySource.enum;
      // TODO replace key to be name later when its added as a property.
      return {
        id: key, // (item).$id
        name: key,
        isList: type === BlueprintPropertyType.array,
        isReadOnly: false, // TODO we need this in the blueprint schema
        validValues,
        propertyTypes: ref
          ? [
              {
                basicType: null,
                blueprintType: {
                  referenceType: 'Import',
                  blueprintIdentifier: ref,
                  version,
                  type: ref
                }
              }
            ]
          : [
              {
                basicType: type,
                blueprintType: null
              }
            ],
        propertyTypeGroup: ref ? BlueprintPropertyTypeGroup.Blueprint : BlueprintPropertyTypeGroup.Basic,
        constraintType: BlueprintConstraintType.None,
        propertySources,
        defaultPropertySource,
        isOptional,
        isSystem
      } as IBlueprintProperty;
    });
  }
  return results;
}
