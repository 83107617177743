
import { Component, PropSync, Vue, Prop } from 'vue-property-decorator';
import CustomDatepicker from 'vue-custom-datepicker';

@Component({
  components: {
    'custom-datepicker': CustomDatepicker
  }
})
export default class CustomDateSelector extends Vue {
  @PropSync('show', { required: true })
  private showDatePicker: any;
  @PropSync('date', { required: true })
  private selectedDate: any;
  @Prop({ required: false, default: () => ({ start: null, end: null }) })
  private dateLimit: { start: string; end: string };

  private setDate(date: any): void {
    this.selectedDate = date;
    this.showDatePicker = false;
  }
}
