import HelperMethods from '@/shared/helper-methods';
import store from '@/store';

export function showError(error: any): void {
  // Do not show errors for canceled API requests
  if (error.__CANCEL__) {
    return;
  }
  // Get error message to display in modal
  let errorMessage: string = null;
  if (!HelperMethods.isStringEmpty(error.response?.data?.message)) {
    errorMessage = error.response.data.message;
  } else if (!HelperMethods.isStringEmpty(error.message)) {
    errorMessage = error.message;
  } else {
    errorMessage = error;
  }
  store.commit('errorState/setError', {
    error: errorMessage,
    config: {
      sendLog: false
    },
    uiError: true
  });
}
