
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import TextEntry from './TextEntry.vue';
import store from '../../store';
import { ValidationField, validateForm } from '../../utils/Validator';
import { wholeNumberFormatter } from '../../utils/Formatter';
import { caeEventBus } from '@/eventBus/asset-event-bus';
import { BurnerDrillingEnums } from '@/enums/burnerDrillingEnums';
import { IPortViewModel } from '@/view-models/burner-view-model';
import Diameter from './Diameter.vue';

@Component({
  components: { Diameter, TextEntry }
})
export default class Port extends Vue {
  @Prop({ required: true })
  private port: IPortViewModel;
  @Prop({ required: true })
  private saveAsset: () => void;
  @Prop({ required: true })
  private upsertPortInState: (
    config: any,
    headerIndex: number,
    manifoldIndex: number,
    tipIndex: number,
    portIndex: number
  ) => void;
  @Prop({ required: true })
  private headerIndex: number;
  @Prop({ required: true })
  private manifoldIndex: number;
  @Prop({ required: true })
  private tipIndex: number;
  @Prop({ required: true })
  private portIndex: number;
  @Prop({ required: true })
  private portList: IPortViewModel[];

  private isExpanded = false;
  private unitButtons = ['mm', 'inch'];
  private selectedUnit = 0;
  private store = store;
  private isFormDirty = false;
  private saving = false;
  private selectedBurner = store.state.assetState.selectedBurner;
  private parentTipExistsInState: boolean = false;
  private validateForm = validateForm;
  private validations: { [key: string]: ValidationField } = {
    name: {
      valid: true,
      warning: false,
      value: () => this.port.name,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    diameter: {
      valid: true,
      warning: false,
      value: () => this.port.diameter,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    diameterUnit: {
      valid: true,
      warning: false,
      value: () => this.port.diameterUnit,
      validator: (value: string) => value != null,
      setWarning: () => false
    },
    number: {
      valid: true,
      warning: false,
      value: () => this.port.number,
      validator: (value: number) => value != null && typeof value === 'number',
      setWarning: (value: number) => value === 0
    }
  };

  private wholeNumberFormatter = wholeNumberFormatter;

  public created(): void {
    this.selectedUnit = this.setUnit(this.port.diameterUnit);
  }

  private mounted(): void {
    this.updateApplyButton();

    caeEventBus.$on(BurnerDrillingEnums.UpdateApplyButtons, () => {
      this.updateApplyButton();
    });
    caeEventBus.$on(BurnerDrillingEnums.CollapseAll, () => {
      this.isExpanded = false;
    });
    caeEventBus.$on(BurnerDrillingEnums.ExpandAll, () => {
      this.isExpanded = true;
    });
  }

  private beforeDestroy(): void {
    caeEventBus.$off(BurnerDrillingEnums.UpdateApplyButtons);
    caeEventBus.$off(BurnerDrillingEnums.CollapseAll);
    caeEventBus.$off(BurnerDrillingEnums.ExpandAll);
  }

  @Watch('selectedUnit')
  private updateUnit(): void {
    this.port.diameterUnit = this.selectedUnit === 0 ? 'mm' : 'inch';
  }

  private setUnit(unit: string): number {
    for (let i = 0; i < this.unitButtons.length; i++) {
      if (unit && unit.toLowerCase() === this.unitButtons[i]) {
        return i;
      }
    }
    return 1;
  }

  private toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  public apply(save: boolean): void {
    this.saving = true;
    const validKeys = Object.keys(this.validations);

    if (this.validateForm(this.validations, validKeys)) {
      const configToSave = {
        name: this.port.name,
        diameter: this.port.diameter,
        diameterUnit: this.port.diameterUnit,
        number: this.port.number
      };
      this.upsertPortInState(configToSave, this.headerIndex, this.manifoldIndex, this.tipIndex, this.portIndex);
      if (save) {
        this.saveAsset();
      }
      this.isFormDirty = false;
      store.commit('navState/updateBurnerTabEnabled', true);
    } else {
      store.commit('errorState/setError', {
        error: 'Please enter a value for all fields.',
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
    this.saving = false;
  }

  @Watch('port.name', { deep: true })
  @Watch('port.diameter', { deep: true })
  @Watch('port.diameterUnit', { deep: true })
  @Watch('port.number', { deep: true })
  private formDirty(): void {
    this.isFormDirty = true;
    store.commit('navState/updateBurnerTabEnabled', false);
  }

  private updateApplyButton(): void {
    if (
      !this.selectedBurner.burnerDetail.drilling[this.headerIndex] ||
      !this.selectedBurner.burnerDetail.drilling[this.headerIndex].manifolds[this.manifoldIndex] ||
      !this.selectedBurner.burnerDetail.drilling[this.headerIndex].manifolds[this.manifoldIndex].tips[this.tipIndex]
    ) {
      this.parentTipExistsInState = false;
    } else {
      this.parentTipExistsInState = true;
    }
  }

  private resetValues(): void {
    Object.assign(this.port, {
      name: 'PortName',
      diameterUnit: 'INCH',
      diameter: '0.0015875',
      number: 1
    });
    this.selectedUnit = 1;
  }

/* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit()
  private deleteConfirm(
    unsavedComponentArray: any,
    componentToDelete: any,
    headerIndex?: number,
    manifoldIndex?: number,
    tipIndex?: number,
    portIndex?: number
  ): void {}
}
