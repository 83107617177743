
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IPurchaseViewModel, ISubsHistory } from '../../view-models/subscription-view-model';

Vue.use(BootstrapVue);

@Component
export default class SubscriptionHistory extends Vue {
  private asset = store.state.assetState.selectedAsset;
  private selected = store.state.assetState.selectedSubsType;
  private subsHistory: ISubsHistory[] = [];
  private filteredHistory: ISubsHistory[] = [];
  private thStyle = {
    backgroundColor: '#2A496D',
    border: 0
  };
  private subscriptionFields = [
    {
      key: 'subscription',
      label: 'Subscription',
      thStyle: this.thStyle
    },
    {
      key: 'expirationDate',
      label: 'Expires',
      thStyle: this.thStyle
    },
    {
      key: 'length',
      label: 'Duration',
      thStyle: this.thStyle
    },
    {
      key: 'createDate',
      label: 'Date Created',
      thStyle: this.thStyle
    },
    {
      key: 'savedBy',
      label: 'Saved By',
      thStyle: this.thStyle
    }
  ];

  public mounted(): void {
    this.getPurchaseHistory();
    this.onSelectedChanged();
  }

  private getPurchaseHistory(): void {
    if (typeof this.asset !== 'undefined' && this.asset.subscription) {
      let purchaseRecord: ISubsHistory[] = [];
      if (this.asset.subscription.fuelSide) {
          this.asset.subscription.fuelSide.purchases.forEach((x) => {
            purchaseRecord = this.addSubscription(purchaseRecord, 'Fuel Side', x);
          });
      }
      if (this.asset.subscription.airSide) {
          this.asset.subscription.airSide.purchases.forEach((x) => {
            purchaseRecord = this.addSubscription(purchaseRecord, 'Air Side', x);
          });
      }
      if (this.asset.subscription.flowNetwork) {
        this.asset.subscription.flowNetwork.purchases.forEach((x) => {
          purchaseRecord = this.addSubscription(purchaseRecord, 'Flow Network', x);
        });
      }
      this.subsHistory = purchaseRecord;
    }
  }

  private addSubscription(purchaseRecord: ISubsHistory[], type: string, x: IPurchaseViewModel): ISubsHistory[] {
    const msInDay = 24 * 60 * 60 * 1000;
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const expiration = new Date(new Date(x.purchaseDate).getTime() + x.length * msInDay);
    purchaseRecord.push({
      subscription: type,
      expirationDate: expiration.toLocaleString('en-US', dateOptions),
      length: x.length,
      createDate: new Date(x.purchaseDate).toLocaleString('en-US', dateOptions),
      savedBy: 'Admin',
    } as ISubsHistory);
    return purchaseRecord;
  }

  @Watch('selected', { immediate: true, deep: true })
  private onSelectedChanged(): void {
    if (this.selected && this.selected !== '') {
      this.filteredHistory = this.subsHistory.filter((record) => record.subscription === this.selected);
    } else {
      this.filteredHistory = this.subsHistory;
    }
  }

  private back(): void {
    store.commit('assetState/updateSelectedSubscription', '');
    this.$router.push('asset-details');
  }
}
