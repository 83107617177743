
import VueRouter, { Route } from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import PageNotFound from '@/components/pageNotFound/PageNotFound.vue';
import HomePage from '@/components/homePage/HomePage.vue';
import ShowAll from '@/components/homePage/ShowAll.vue';
import SubscriptionHistory from '@/components/subscriptionsPage/ViewHistory.vue';
import BurnersPage from '@/components/burnersPage/Burners.vue';
import AssetDetailsHeader from '@/components/assetDetails/AssetDetailsHeader.vue';
import Error from '@/components/Error/Error.vue';
import Toaster from '@/components/toast/Toaster.vue';
import CreateAssetPage from '@/components/assetCreation/CreateAssetPage.vue';
import EditAsset from '@/components/assetCreation/EditAsset.vue';
import BurnerCreation from '@/components/burnerCreation/BurnerCreation.vue';
import BurnerEdit from '@/components/burnerCreation/BurnerEdit.vue';
import BurnerDetails from '@/components/burnersPage/BurnerDetailsHeader.vue';
import Loading from '@/components/common/Loading.vue';
import { authGuard } from '@/services/auth';
import { Vue, Component } from 'vue-property-decorator';
import store, { getAssetServiceStoreModule, getBlueprintStoreModule } from '@/store';
import { appRouteBase } from '@/utils/FromParent';
import i18n from '@/i18n';
import { assetServiceGuard, blueprintServiceGuard } from '@/components/asset-services/guards/asset-services-guard';
import AssetServiceModule from '@/store/asset-service-store/asset-service-store';
import BlueprintEditPage from '@/components/asset-services/blueprint-edit/blueprint-edit-page.vue';
import AssetListPage from '@/components/asset-services/asset-list/asset-list-page.vue';
import BlueprintListPage from '@/components/asset-services/blueprint-list/blueprint-list-page.vue';
import AssetEditPage from '@/components/asset-services/asset-edit/asset-edit-page.vue';
import VeeValidate from '@/vee-validate';
import HelperMethods from '@/shared/helper-methods';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import moment from 'moment';
import AssetServiceLandingPage from '@/components/asset-services/asset-service-landing-page.vue';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VeeValidate);

Vue.filter('formatDate', (value: number) => {
  if (value) {
    return moment.utc(String(value)).local().format('LLL');
  }
});

const routes = [
  { path: '/', component: HomePage, name: 'LegacyHome' },
  { path: '*', component: PageNotFound },
  { path: '/show-all', component: ShowAll },
  { path: '/subscription-history', component: SubscriptionHistory },
  { path: '/burners', component: BurnersPage },
  { path: '/asset-details', component: AssetDetailsHeader },
  { path: '/asset-creation', component: CreateAssetPage },
  { path: '/asset-edit', component: EditAsset },
  { path: '/burner-creation', component: BurnerCreation },
  { path: '/burner-edit', component: BurnerEdit },
  { path: '/burner-details', component: BurnerDetails },
  {
    path: '/asset-services',
    component: AssetServiceLandingPage,
    redirect: { name: 'AssetList' },
    children: [
      {
        path: 'blueprint',
        component: BlueprintListPage,
        name: 'BlueprintList',
        beforeEnter: assetServiceGuard
      },
      {
        path: 'asset',
        component: AssetListPage,
        name: 'AssetList',
        beforeEnter: assetServiceGuard
      }
    ]
  },
  {
    path: '/asset-services/blueprint/edit/:blueprintKey/:blueprintState',
    component: BlueprintEditPage,
    name: 'EditBlueprint',
    beforeEnter: blueprintServiceGuard
  },
  {
    path: '/asset-services/asset/create/:blueprintKey',
    component: AssetEditPage,
    name: 'CreateAsset',
    beforeEnter: assetServiceGuard
  },
  {
    path: '/asset-services/asset/edit/:blueprintKey/:assetKey',
    component: AssetEditPage,
    name: 'EditAsset',
    beforeEnter: assetServiceGuard
  }
];

export const customerEquipmentRouter = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

customerEquipmentRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  router: customerEquipmentRouter,
  components: { Loading, Error, Toaster },
  i18n
})
export default class App extends Vue {
  private store = store;

  private get assetServiceStore(): AssetServiceModule {
    return getAssetServiceStoreModule(this.store);
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(this.store);
  }

  private created(): void {
    this.$router.afterEach(() => {
      store.commit('app/updateIsLoading', false);
    });
  }

  private deactivated(): void {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    store.commit('app/updateIsLoading', true);
    if (!HelperMethods.isNullOrUndefined((window as any).eftEventBus)) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private async mounted(): Promise<void> {
    // Handle route changes from parent
    if (!HelperMethods.isNullOrUndefined((window as any).eftEventBus)) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private handleRouteChange(parentRoute: Route): void {
    if (parentRoute.path.startsWith(appRouteBase())) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(appRouteBase(), '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  private get isLoading(): boolean {
    return store.getters['app/isLoading'];
  }
}
