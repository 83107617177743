
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import AssetDetails from '@/components/asset-services/asset-edit/asset-details.vue';
import AssetHierarchyNode from '@/components/asset-services/asset-edit/asset-hierarchy-node.vue';
import ExportModal from '@/components/asset-services/asset-edit/modals/export-modal.vue';
import ImportModal from '@/components/asset-services/asset-edit/modals/import-modal.vue';
import AssetHelperMethods from '@/components/asset-services/business-logic/asset-helper-methods';
import { NodeType } from '@/components/asset-services/models/asset-enums';
import { IAssetInstanceContext, IAssetNode } from '@/components/asset-services/models/asset-interfaces';
import IconButton from '@/components/common/dsm/icon-button.vue';
import Loading from '@/components/common/Loading.vue';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import SearchBar from '@/components/common/search-bar.vue';
import store, { getAssetServiceStoreModule, getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetServiceModule from '@/store/asset-service-store/asset-service-store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'asset-data-step',
  components: {
    AssetDetails,
    AssetHierarchyNode,
    ExportModal,
    IconButton,
    ImportModal,
    Loading,
    LoadingOverlay,
    SearchBar
  }
})
export default class AssetDataStep extends Vue {
  private get loading(): boolean {
    return this.blueprintStore.blueprintsLoading || !this.selectedAssetContext || this.loadingMessage != null;
  }

  private get loadingMessage(): string {
    return this.assetStore.assetsLoadingMessage;
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetServiceStore(): AssetServiceModule {
    return getAssetServiceStoreModule(store);
  }

  private get assetServiceGenerateDataEnabled(): boolean {
    return this.assetServiceStore.assetServiceGenerateData;
  }

  private get selectedAssetContext(): IAssetInstanceContext {
    return this.assetStore.selectedAssetContext;
  }

  private get rootNode(): IAssetNode {
    return {
      nodeId: this.selectedAssetContext.entityId,
      blueprintId: this.selectedAssetContext.blueprintId,
      type: NodeType.Asset,
      element: this.selectedAssetContext.entity,
      elementId: this.selectedAssetContext.entityId
    };
  }

  private async generateDataAll(): Promise<void> {
    const currentTopLevelBlueprint = this.blueprintStore.allAssetBlueprints.find(
      (blueprint) => blueprint.id === this.selectedAssetContext.blueprintId
    );
    AssetHelperMethods.generateAssetData(
      this.selectedAssetContext.entity,
      currentTopLevelBlueprint,
      this.blueprintStore.allAssetBlueprints
    );
    // This will re-init the table and cause the form values to be updated
    this.assetStore.setSelectedNode(this.assetStore.selectedNode);
  }
}
