
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component
export default class DeleteSubscription extends Vue {
  private store = store;

  get selectedSubsType(): string {
    return store.getters['assetState/getSelectedSubsType'];
  }

  private async deleteSubscription(): Promise<void> {
    store.commit('assetState/deleteSubscription', this.selectedSubsType);
    try {
      await store.dispatch('assetState/saveSelectedAsset', store.state.assetState.selectedAsset);
    } catch (err) {
      showError(err.message);
    }
    this.$bvModal.hide('deleteSubscription');
  }
}
