export const blueprints = {
  blueprints: [
    {
      id: 'BurnerAirFlowResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:BurnerAirFlowResults',
      state: 'Published',
      version: '1.0.0',
      name: 'BurnerAirFlowResults',
      description: 'System Blueprint for BurnerAirFlowResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:45',
      updatedDate: '2022-02-23T10:52:45',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'BurnerSubstoich',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BurnerLambdas',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'RecommendedDamperSetting',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BurnerOutOfControllableRange',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AdiabaticFlameTemperature',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'OverallLambda',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'UsedEstimatedDraft',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'CompositionComponent2zyHx',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:CompositionComponent2zyHx',
      state: 'Published',
      version: '1.0.0',
      name: 'CompositionComponent',
      description: null,
      isSystem: false,
      systemVersion: null,
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-01-21T19:26:55',
      updatedDate: '2022-01-21T19:26:55',
      createdBy: 'kori decosta',
      updatedBy: 'kori decosta',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'CO2',
            'H2',
            'N2',
            'H2O',
            'C2H6',
            'C5H12,n-pentane',
            'C4H10,n-butane'
          ],
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'HeaderPropertyResult',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:HeaderPropertyResult',
      state: 'Published',
      version: '1.0.0',
      name: 'HeaderPropertyResult',
      description: 'System Blueprint for HeaderPropertyResult',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:36',
      updatedDate: '2022-02-23T10:52:36',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'HeaderKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Values',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelPropertiesResultValues',
                version: '1.0.0',
                type: 'FuelPropertiesResultValues'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelPropertiesResultValues',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelPropertiesResultValues',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelPropertiesResultValues',
      description: 'System Blueprint for FuelPropertiesResultValues',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:35',
      updatedDate: '2022-02-23T10:52:35',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'MW',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LHV',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LHV_btuscf',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'EncounteredInvalidComponent',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Fluidppqjb',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Fluidppqjb',
      state: 'Published',
      version: '1.0.0',
      name: 'Fluid',
      description: null,
      isSystem: false,
      systemVersion: null,
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-01-21T14:55:13',
      updatedDate: '2022-01-21T19:33:53',
      createdBy: 'kori decosta',
      updatedBy: 'kori decosta',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Pressure',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Temperature',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Massflow',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Composition',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'CompositionComponent2zyHx',
                version: '1.0.0',
                type: 'CompositionComponent2zyHx'
              }
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AirSideResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AirSideResults',
      state: 'Published',
      version: '1.0.0',
      name: 'AirSideResults',
      description: 'System Blueprint for AirSideResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:20',
      updatedDate: '2022-02-23T10:52:20',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'UsedAmbientTemperatureForCombustion',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SubStoichWarning',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'JZCleanTipFlowResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirFlowResults',
                version: '1.0.0',
                type: 'AirFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomerFlowResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirFlowResults',
                version: '1.0.0',
                type: 'AirFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'SethSamTest26VjtAm',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:SethSamTest26VjtAm',
      state: 'Published',
      version: '1.0.0',
      name: 'Seth Sam Test 2',
      description: 'New Test',
      isSystem: false,
      systemVersion: null,
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2021-12-08T20:08:55',
      updatedDate: '2021-12-08T20:08:55',
      createdBy: 'Seth Hadley',
      updatedBy: 'Seth Hadley',
      searchTags: [],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'SethQATest123h4aym',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'NewProp',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: [
            1
          ],
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AirPropertiesResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AirPropertiesResults',
      state: 'Published',
      version: '1.0.0',
      name: 'AirPropertiesResults',
      description: 'System Blueprint for AirPropertiesResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:16',
      updatedDate: '2022-02-23T10:52:16',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'MW',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Pressure',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Pressure_psia',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Density',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Density_lbmft3',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Viscosity',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'WeatherDataUsed',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'WeatherStationUsed',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'WeatherStationUsedAddress',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Port',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Port',
      state: 'Published',
      version: '1.0.0',
      name: 'Port',
      description: 'System Blueprint for Port',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:49',
      updatedDate: '2022-02-23T10:52:49',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Number',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Diameter',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'BpsConfiguration',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:BpsConfiguration',
      state: 'Published',
      version: '1.0.0',
      name: 'BpsConfiguration',
      description: 'System Blueprint for BpsConfiguration',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:40',
      updatedDate: '2022-02-23T10:52:40',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'AirRegisterKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BpsSensorKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'KeyValuePair',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:KeyValuePair',
      state: 'Published',
      version: '1.0.0',
      name: 'KeyValuePair',
      description: 'System Blueprint for KeyValuePair',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:17',
      updatedDate: '2022-02-23T10:52:17',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'System327UoU5T',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:System327UoU5T',
      state: 'Published',
      version: '1.0.0',
      name: 'System32',
      description: null,
      isSystem: false,
      systemVersion: null,
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-01-21T19:24:44',
      updatedDate: '2022-01-21T19:24:44',
      createdBy: 'Seth Hadley',
      updatedBy: 'Seth Hadley',
      searchTags: [
        'newtag'
      ],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'AirRegister6e3Jjn',
            version: null,
            type: null
          },
          type: 'Parent'
        },
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'AirRegisterSetting6wvu8n',
            version: null,
            type: null
          },
          type: 'Parent'
        },
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'AirSideResults5VoNxU',
            version: null,
            type: null
          },
          type: 'Parent'
        },
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'AirFlowResultsXzsB6',
            version: null,
            type: null
          },
          type: 'Parent'
        },
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'AirPropertiesResultsqpXWW',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'New1',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'New2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: [
            1
          ],
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'New3',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            '2'
          ],
          isSystem: false,
          propertySources: [
            'OnPointGenerated'
          ],
          defaultPropertySource: 'OnPointGenerated'
        }
      ]
    },
    {
      id: 'Agreement',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Agreement',
      state: 'Published',
      version: '1.0.0',
      name: 'Agreement',
      description: 'System Blueprint for Agreement',
      isSystem: true,
      systemVersion: '0.2',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:12',
      updatedDate: '2022-02-23T10:52:12',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'AcceptedAgreement',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AgreementDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AgreementType',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'Site-Specific',
            'Click-Through'
          ],
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Burner',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Burner',
      state: 'Published',
      version: '1.0.0',
      name: 'Burner',
      description: 'System Blueprint for Burner',
      isSystem: true,
      systemVersion: '0.7',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:47',
      updatedDate: '2022-02-23T10:52:47',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'Heater',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'Description',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'OppScoreParams',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'OpportunityScoreParameters',
                version: '1.0.0',
                type: 'OpportunityScoreParameters'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'EloName',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DamperSetting',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'DamperSetting',
                version: '1.0.0',
                type: 'DamperSetting'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Fuels',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelSetting',
                version: '1.0.0',
                type: 'FuelSetting'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AirRegisterSettings',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirRegisterSetting',
                version: '1.0.0',
                type: 'AirRegisterSetting'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelLineSettings',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelLineSetting',
                version: '1.0.0',
                type: 'FuelLineSetting'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BpsConfigurations',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'BpsConfiguration',
                version: '1.0.0',
                type: 'BpsConfiguration'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BpsValues',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'BpsValue',
                version: '1.0.0',
                type: 'BpsValue'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AirSide',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'BurnerAirSideResults',
                version: '1.0.0',
                type: 'BurnerAirSideResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelSide',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelSideResults',
                version: '1.0.0',
                type: 'FuelSideResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_AssetId',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Tags',
          isOptional: false,
          isList: false,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Tags',
                version: '1.0.0',
                type: 'Tags'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Formulas',
          isOptional: false,
          isList: true,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Formula',
                version: '1.0.0',
                type: 'Formula'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'DoubleDrop',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:DoubleDrop',
      state: 'Published',
      version: '1.0.0',
      name: 'DoubleDrop',
      description: 'System Blueprint for DoubleDrop',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:48',
      updatedDate: '2022-02-23T10:52:48',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'DoubleDropCd',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Diameter',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FlowNetwork',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FlowNetwork',
      state: 'Published',
      version: '1.0.0',
      name: 'FlowNetwork',
      description: 'System Blueprint for FlowNetwork',
      isSystem: true,
      systemVersion: '0.2',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:24',
      updatedDate: '2022-02-23T10:52:24',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Config',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Options',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FlowNetworkOption',
                version: '1.0.0',
                type: 'FlowNetworkOption'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SolverName',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'L-BFGS-B',
            'Nelder-Mead',
            'TNC',
            'Powell',
            'CG',
            'BFGS'
          ],
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Measurement',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Measurement',
      state: 'Published',
      version: '1.0.0',
      name: 'Measurement',
      description: 'System Blueprint for Measurement',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:14',
      updatedDate: '2022-02-23T10:52:14',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Unit',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Uncertainty',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'UncertaintyPercent',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Location',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Location',
      state: 'Published',
      version: '1.0.0',
      name: 'Location',
      description: 'System Blueprint for Location',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:25',
      updatedDate: '2022-02-23T10:52:25',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Elevation',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Latitude',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Longitude',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelFlowResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelFlowResults',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelFlowResults',
      description: 'System Blueprint for FuelFlowResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:31',
      updatedDate: '2022-02-23T10:52:31',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'HR',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'HR_MMBTUhr',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'HR_Gcalhr',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelMassFlowRate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelSetting',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelSetting',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelSetting',
      description: 'System Blueprint for FuelSetting',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:43',
      updatedDate: '2022-02-23T10:52:43',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'FuelName',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BurnerFiring',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BurnerFiringLastReading',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'BpsValue',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:BpsValue',
      state: 'Published',
      version: '1.0.0',
      name: 'BpsValue',
      description: 'System Blueprint for BpsValue',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:41',
      updatedDate: '2022-02-23T10:52:41',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'BpsSensorKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BatteryLevel',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelPropertiesResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelPropertiesResults',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelPropertiesResults',
      description: 'System Blueprint for FuelPropertiesResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:37',
      updatedDate: '2022-02-23T10:52:37',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Fuels',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'HeaderPropertyResult',
                version: '1.0.0',
                type: 'HeaderPropertyResult'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Values',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelPropertiesResultValues',
                version: '1.0.0',
                type: 'FuelPropertiesResultValues'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AnyOfTest3t7zSp',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AnyOfTest3t7zSp',
      state: 'Published',
      version: '1.0.0',
      name: 'Any_Of Test',
      description: null,
      isSystem: false,
      systemVersion: null,
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-01-21T19:29:36',
      updatedDate: '2022-01-21T19:29:36',
      createdBy: 'Lynette Hardinger',
      updatedBy: 'Lynette Hardinger',
      searchTags: [
        'lynette'
      ],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'Heater6cf7pC',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'Blueprint-Any_Of',
          isOptional: false,
          isList: false,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Agreement3qYWzZ',
                version: '1.0.0',
                type: 'Agreement3qYWzZ'
              }
            },
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirFlowResultsXzsB6',
                version: '1.0.0',
                type: 'AirFlowResultsXzsB6'
              }
            },
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirPropertiesResultsqpXWW',
                version: '1.0.0',
                type: 'AirPropertiesResultsqpXWW'
              }
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Blueprint-List-Any_Of',
          isOptional: false,
          isList: true,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'ATestBlueprint5AfDTT',
                version: '1.0.0',
                type: 'ATestBlueprint5AfDTT'
              }
            },
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Agreement3qYWzZ',
                version: '1.0.0',
                type: 'Agreement3qYWzZ'
              }
            },
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirFlowResultsXzsB6',
                version: '1.0.0',
                type: 'AirFlowResultsXzsB6'
              }
            }
          ],
          validValues: null,
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'String-Any_Of',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'This',
            'Is',
            'A',
            'Test'
          ],
          isSystem: false,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'BurnerDetail',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:BurnerDetail',
      state: 'Published',
      version: '1.0.0',
      name: 'BurnerDetail',
      description: 'System Blueprint for BurnerDetail',
      isSystem: true,
      systemVersion: '0.5',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:53',
      updatedDate: '2022-02-23T10:52:53',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'Burner',
            version: null,
            type: null
          },
          type: 'Parent'
        },
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'Heater',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'ZoneType',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AirRegisters',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirRegister',
                version: '1.0.0',
                type: 'AirRegister'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomFields',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'KeyValuePair',
                version: '1.0.0',
                type: 'KeyValuePair'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Drilling',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Header',
                version: '1.0.0',
                type: 'Header'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_AssetId',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Tags',
          isOptional: false,
          isList: false,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Tags',
                version: '1.0.0',
                type: 'Tags'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Formulas',
          isOptional: false,
          isList: true,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Formula',
                version: '1.0.0',
                type: 'Formula'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Header',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Header',
      state: 'Published',
      version: '1.0.0',
      name: 'Header',
      description: 'System Blueprint for Header',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:52',
      updatedDate: '2022-02-23T10:52:52',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ReferenceKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'IsCriticalFuelLine',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Grouping',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SortOrder',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Manifolds',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Manifold',
                version: '1.0.0',
                type: 'Manifold'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'HeaderResult',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:HeaderResult',
      state: 'Published',
      version: '1.0.0',
      name: 'HeaderResult',
      description: 'System Blueprint for HeaderResult',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:32',
      updatedDate: '2022-02-23T10:52:32',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'HeaderKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CleanTipResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelFlowResults',
                version: '1.0.0',
                type: 'FuelFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomerFuelFlowResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelFlowResults',
                version: '1.0.0',
                type: 'FuelFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'TipHealthIndicator',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'BurnerFiringUsed',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'NotFiringReason',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelComposition',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelComposition',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelComposition',
      description: 'System Blueprint for FuelComposition',
      isSystem: true,
      systemVersion: '0.2',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:30',
      updatedDate: '2022-02-23T10:52:30',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'H2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CH4',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C2H2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C2H4',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C2H6',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C3H4_Allene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C3H4_Propyne',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C3H6_Propylene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C3H8',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H6_2_Butyne',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H6_Butadiene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H6_Cyclo',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H8_1_Butene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H8_Cis2_Butene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H8_Isobutene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H8_Tr2_Butene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H8_Cyclo',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H10_IsoButane',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C4H10_N_Butane',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_1_Pentene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_Cyclo',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_Tr2_Pentene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_Cis2_Pentene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_2Methyl2Butene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H10_3Methyl1Butene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H12_N_Pentane',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C5H12_I_Pentane',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C6H12_1_Hexene',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'C6H14_N_Hexane',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CH3OH',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CO',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CO2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'NH3',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'H2O',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'O2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'N2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'BurnerAirSideResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:BurnerAirSideResults',
      state: 'Published',
      version: '1.0.0',
      name: 'BurnerAirSideResults',
      description: 'System Blueprint for BurnerAirSideResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:46',
      updatedDate: '2022-02-23T10:52:46',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'BurnerAirFlowRate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DamperSettingUsed',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirRegisterSetting',
                version: '1.0.0',
                type: 'AirRegisterSetting'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'UsedAmbientTemperatureForCombustion',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'UsedEstimatedDraft',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SubStoichWarning',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'JZCleanTipFlowResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'BurnerAirFlowResults',
                version: '1.0.0',
                type: 'BurnerAirFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Tip',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Tip',
      state: 'Published',
      version: '1.0.0',
      name: 'Tip',
      description: 'System Blueprint for Tip',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:50',
      updatedDate: '2022-02-23T10:52:50',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Number',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Cd',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Diameter',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'HasDoubleDrop',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DoubleDrop',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'DoubleDrop',
                version: '1.0.0',
                type: 'DoubleDrop'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Ports',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Port',
                version: '1.0.0',
                type: 'Port'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AirRegister',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AirRegister',
      state: 'Published',
      version: '1.0.0',
      name: 'AirRegister',
      description: 'System Blueprint for AirRegister',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:18',
      updatedDate: '2022-02-23T10:52:18',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Number',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SortOrder',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Int',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'IsPrimary',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DamperArea',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DamperKValue',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DamperKValues',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'KeyValuePair',
                version: '1.0.0',
                type: 'KeyValuePair'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Subscription',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Subscription',
      state: 'Published',
      version: '1.0.0',
      name: 'Subscription',
      description: 'System Blueprint for Subscription',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:27',
      updatedDate: '2022-02-23T10:52:27',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ExpirationAlert',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ExpirationDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Purchases',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Purchase',
                version: '1.0.0',
                type: 'Purchase'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FlowNetworkOption',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FlowNetworkOption',
      state: 'Published',
      version: '1.0.0',
      name: 'FlowNetworkOption',
      description: 'System Blueprint for FlowNetworkOption',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:22',
      updatedDate: '2022-02-23T10:52:22',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Type',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Manifold',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Manifold',
      state: 'Published',
      version: '1.0.0',
      name: 'Manifold',
      description: 'System Blueprint for Manifold',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:51',
      updatedDate: '2022-02-23T10:52:51',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Diameter',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'HasDoubleDrop',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'DoubleDrop',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'DoubleDrop',
                version: '1.0.0',
                type: 'DoubleDrop'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Tips',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Tip',
                version: '1.0.0',
                type: 'Tip'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AirRegisterSetting',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AirRegisterSetting',
      state: 'Published',
      version: '1.0.0',
      name: 'AirRegisterSetting',
      description: 'System Blueprint for AirRegisterSetting',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:19',
      updatedDate: '2022-02-23T10:52:19',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'AirRegisterKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Value',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AmbientProperties',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AmbientProperties',
      state: 'Published',
      version: '1.0.0',
      name: 'AmbientProperties',
      description: 'System Blueprint for AmbientProperties',
      isSystem: true,
      systemVersion: '0.4',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:39',
      updatedDate: '2022-02-23T10:52:39',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [
        {
          blueprintReference: {
            referenceType: 'Undefined',
            blueprintIdentifier: 'Heater',
            version: null,
            type: null
          },
          type: 'Parent'
        }
      ],
      structProperties: [
        {
          name: 'AmbientPressure',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AmbientTemperature',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AmbientRelativeHumidity',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_AssetId',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Tags',
          isOptional: false,
          isList: false,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Tags',
                version: '1.0.0',
                type: 'Tags'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Formulas',
          isOptional: false,
          isList: true,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Formula',
                version: '1.0.0',
                type: 'Formula'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Purchase',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:Purchase',
      state: 'Published',
      version: '1.0.0',
      name: 'Purchase',
      description: 'System Blueprint for Purchase',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:26',
      updatedDate: '2022-02-23T10:52:26',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Length',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'PurchaseDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelSideResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelSideResults',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelSideResults',
      description: 'System Blueprint for FuelSideResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:33',
      updatedDate: '2022-02-23T10:52:33',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'Fuels',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'HeaderResult',
                version: '1.0.0',
                type: 'HeaderResult'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CleanTipResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelFlowResults',
                version: '1.0.0',
                type: 'FuelFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomerFuelFlowResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelFlowResults',
                version: '1.0.0',
                type: 'FuelFlowResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'TipHealthIndicator',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'WeatherData',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:WeatherData',
      state: 'Published',
      version: '1.0.0',
      name: 'WeatherData',
      description: 'System Blueprint for WeatherData',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:29',
      updatedDate: '2022-02-23T10:52:29',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'AmbientPressure',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AmbientRelativeHumidity',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AmbientTemperature',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CacheDateTime',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'WeatherStation',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'WeatherStationAddress',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'FuelLineSetting',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:FuelLineSetting',
      state: 'Published',
      version: '1.0.0',
      name: 'FuelLineSetting',
      description: 'System Blueprint for FuelLineSetting',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:44',
      updatedDate: '2022-02-23T10:52:44',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'HeaderKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'IsFiring',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'AirFlowResults',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:AirFlowResults',
      state: 'Published',
      version: '1.0.0',
      name: 'AirFlowResults',
      description: 'System Blueprint for AirFlowResults',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:15',
      updatedDate: '2022-02-23T10:52:15',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'OverallLambda',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LeakageAirFlowRate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LeakageAirFlowRate_lbmhr',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LeakageAirMinArea',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LeakageAirMaxArea',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ExpectedWetO2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ExpectedDryO2',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Measurement',
                version: '1.0.0',
                type: 'Measurement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'OpportunityScoreParameters',
      arn: 'arn:aws:minerva:us-east-1:747390438150:blueprint:OpportunityScoreParameters',
      state: 'Published',
      version: '1.0.0',
      name: 'OpportunityScoreParameters',
      description: 'System Blueprint for OpportunityScoreParameters',
      isSystem: true,
      systemVersion: '0.1',
      isRoot: false,
      allowedEquipmentTypes: [
        'Heater'
      ],
      createdDate: '2022-02-23T10:52:28',
      updatedDate: '2022-02-23T10:52:28',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      allowedRelationships: [],
      structProperties: [
        {
          name: 'OpportunityPriority',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LowerTolerance',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'UpperTolerance',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    },
    {
      id: 'Heater',
      state: 'Published',
      version: '1.0.0',
      title: 'Heater',
      description: 'System Blueprint for Heater',
      isSystem: true,
      isRoot: true,
      createdDate: '2022-02-23T10:52:38',
      updatedDate: '2022-02-23T10:52:38',
      createdBy: 'System',
      updatedBy: 'System',
      searchTags: [],
      structProperties: [
        {
          name: 'Agreement',
          isOptional: true,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Agreement',
                version: '1.0.0',
                type: 'Agreement'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AllowedToLog',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomerKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'CustomerName',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'EmberName',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'EquipmentSubType',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'Ethylene',
            'Platformer',
            'VC',
            'Box',
            'Cabin',
            'Reformer'
          ],
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'EquipmentType',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FlowNetwork',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FlowNetwork',
                version: '1.0.0',
                type: 'FlowNetwork'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Location',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Location',
                version: '1.0.0',
                type: 'Location'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'LoggingLevel',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: [
            'Debug',
            'Info',
            'Warning',
            'Error',
            'Critical'
          ],
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ParserVersion',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ShowEmberRecommendations',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Site',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'SiteKey',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Subscriptions',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Subscription',
                version: '1.0.0',
                type: 'Subscription'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'OppScoreType',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'OppScoreParams',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'OpportunityScoreParameters',
                version: '1.0.0',
                type: 'OpportunityScoreParameters'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Weather',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'WeatherData',
                version: '1.0.0',
                type: 'WeatherData'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelComposition',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelComposition',
                version: '1.0.0',
                type: 'FuelComposition'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Draft',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'RetrieveWeatherData',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'IgnoreInvalidComponents',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Boolean',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'ZoneO2',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'TargetO2',
          isOptional: false,
          isList: true,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'CustomerGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AirSideResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirSideResults',
                version: '1.0.0',
                type: 'AirSideResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelSideResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelSideResults',
                version: '1.0.0',
                type: 'FuelSideResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'OpportunityScore',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'Double',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'OnPointGenerated',
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'FuelPropertiesResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'FuelPropertiesResults',
                version: '1.0.0',
                type: 'FuelPropertiesResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'AirPropertiesResults',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'AirPropertiesResults',
                version: '1.0.0',
                type: 'AirPropertiesResults'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_Key',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'name',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_CreatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedBy',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'String',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: '_UpdatedDate',
          isOptional: false,
          isList: false,
          constraintType: 'None',
          propertyTypeGroup: 'Basic',
          propertyTypes: [
            {
              basicType: 'DateTime',
              blueprintType: null
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Tags',
          isOptional: false,
          isList: false,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Tags',
                version: '1.0.0',
                type: 'Tags'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        },
        {
          name: 'Formulas',
          isOptional: false,
          isList: true,
          constraintType: 'AnyOf',
          propertyTypeGroup: 'Blueprint',
          propertyTypes: [
            {
              basicType: null,
              blueprintType: {
                referenceType: 'Import',
                blueprintIdentifier: 'Formula',
                version: '1.0.0',
                type: 'Formula'
              }
            }
          ],
          validValues: null,
          isSystem: true,
          propertySources: [
            'Static'
          ],
          defaultPropertySource: 'Static'
        }
      ]
    }
  ],
  isSummary: false,
  nextToken: null
};

export const blueprintSchemas = {
  schemas: [
    {
      $id: 'HeaterConfig.schema.json',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      title: 'HeaterConfig',
      description: 'System Blueprint for HeaterConfig',
      version: '1.0.0',
      state: 'Published',
      createdDate: '07/18/2022 09:45:00',
      createdBy: 'System',
      updatedDate: '',
      updatedBy: '',
      isSystem: true,
      isRoot: false,
      type: 'object',
      required: [ 'allowedToLog', 'emberName', 'equipmentSubType' ],
      properties: {
        allowedToLog: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'bool',
              enum: [ 'bool' ]
            },
            value: {
              type: 'bool'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        emberName: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'string',
              enum: [ 'string' ]
            },
            value: {
              type: 'string'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        equipmentSubType: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'string',
              enum: [ 'string' ]
            },
            value: {
              enum: [ 'None', 'Ethylene', 'Platformer', 'VC', 'Box', 'Cabin', 'Reformer' ]
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        }
      }
    },
    {
      $id: 'HeaterHierarchy.schema.json',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      title: 'HeaterHierarchy',
      description: 'System Blueprint for HeaterHierarchy',
      version: '1.0.0',
      state: 'Published',
      createdDate: '07/18/2022 09:45:00',
      createdBy: 'System',
      updatedDate: '',
      updatedBy: '',
      isSystem: true,
      isRoot: true,
      type: 'object',
      required: [ 'ignoreInvalidComponents', 'settingLoss' ],
      properties: {
        agreement: {
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'object',
              enum: [ 'object' ]
            },
            value: {
              type: 'object',
              $ref: 'Agreement.schema.json'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        ignoreInvalidComponents: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'bool',
              enum: [ 'bool' ]
            },
            value: {
              type: 'bool'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        settingLoss: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'object',
              enum: [ 'object' ]
            },
            value: {
              $ref: '/schemas/FractionPercentageMeasurement.schema.json'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        solveForDuctDampers: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'bool',
              enum: [ 'bool' ]
            },
            value: {
              type: 'bool'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        zones: {
          isSystem: true,
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'array',
              enum: [ 'array' ]
            },
            value: {
              type: 'array',
              items: {
                $ref: '/schemas/Zones.schema.json'
              }
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
      }
    },
    {
      $id: 'DistanceMeasurement.schema.json',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      title: 'DistanceMeasurement',
      description: 'System Blueprint for Distance Measurement',
      version: '1.0.0',
      state: 'Published',
      createdDate: '07/18/2022 09:45:00',
      createdBy: 'System',
      updatedDate: '',
      updatedBy: '',
      isSystem: true,
      isRoot: false,
      type: 'object',
      required: [ 'value', 'unit', 'uncertainty' ],
      properties: {
        value: {
          type: 'object',
          properties: {
            propertySource: {
              default: 'CustomerGenerated',
              enum: [ 'CustomerGenerated', 'Formula', 'Static' ]
            },
            propertyType: {
              default: 'float',
              enum: [ 'float' ]
            },
            tagName: {
              type: 'string'
            },
            formula: {
              type: 'string'
            },
            value: {
              type: 'number'
            }
          },
          required: [ 'propertySource', 'propertyType' ],
          allOf: [
            {
              if: {
                properties: { propertySource: { const: 'CustomerGenerated' } }
              },
              then: { required: [ 'tagName' ] }
            },
            {
              if: {
                properties: { propertySource: { const: 'Formula' } }
              },
              then: { required: [ 'formula' ] }
            },
            {
              if: {
                properties: { propertySource: { const: 'Static' } }
              },
              then: { required: [ 'value' ] }
            }
          ]
        },
        unit: {
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'string',
              enum: [ 'string' ]
            },
            value: {
              enum: [ 'ft', 'in', 'm', 'mm' ]
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        },
        uncertainty: {
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'float',
              enum: [ 'float' ]
            },
            value: {
              type: 'number'
            }
          },
          required: [ 'propertySource', 'propertyType', 'value' ]
        }
      }
    },
    {
      $id: 'Agreement.schema.json',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      title: 'Agreement',
      description: 'System Blueprint for Agreement',
      version: '1.0.0',
      state: 'Published',
      createdDate: '07/18/2022 09:45:00',
      createdBy: 'System',
      updatedDate: '',
      updatedBy: '',
      isSystem: true,
      isRoot: false,
      type: 'object',
      required: [ 'acceptedAgreement', 'agreementDate', 'agreemenType' ],
      properties: {
        acceptedAgreement: {
          type: 'object',
          isSystem: true,
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'bool',
              enum: [ 'bool' ]
            },
            value: {
              type: 'bool'
            }
          }
        },
        agreementDate: {
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'date',
              enum: [ 'date' ]
            },
            value: {
              type: 'date-time'
            }
          }
        },
        agreementType: {
          type: 'object',
          properties: {
            propertySource: {
              default: 'Static',
              enum: [ 'Static' ]
            },
            propertyType: {
              default: 'string',
              enum: [ 'string' ]
            },
            value: {
              type: 'string', enum: ['Site-Specific', 'Click-Through']},
          }
        }
      }
    }
  ]
};
