import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { AppStore, IAppStoreState } from './app/appStore';
import { AssetStore, IAssetStoreState } from './assetStore/assetStore';
import { ErrorStore, IErrorStoreState } from './errorStore/errorStore';
import { NavStore, INavStoreState } from './navStore/navStore';
import AssetServiceModule from './asset-service-store/asset-service-store';
import { getModule } from 'vuex-module-decorators';
import BlueprintModule from './asset-service-store/blueprint-store';
import AssetModule from './asset-service-store/asset-store';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  assetState: IAssetStoreState;
  errorState: IErrorStoreState;
  navState: INavStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    assetState: AssetStore,
    errorState: ErrorStore,
    navState: NavStore,
    assetStore: AssetModule,
    assetServiceStore: AssetServiceModule,
    blueprintStore: BlueprintModule
  }
});

export function getAssetServiceStoreModule(store: Store<IRootState>): AssetServiceModule {
  return getModule(AssetServiceModule, store);
}

export function getAssetStoreModule(store: Store<IRootState>): AssetModule {
  return getModule(AssetModule, store);
}

export function getBlueprintStoreModule(store: Store<IRootState>): BlueprintModule {
  return getModule(BlueprintModule, store);
}
