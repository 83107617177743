
import { Component, Emit } from 'vue-property-decorator';
import { BlueprintEditStep } from '@/components/asset-services/models/blueprint-enums';
import ChipSearch from '@/components/common/chip-search.vue';
import IconButton from '@/components/common/dsm/icon-button.vue';
import NavigationStep from '@/components/common/navigation/navigation-step';
import HelperMethods from '@/shared/helper-methods';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'blueprint-details-step',
  components: {
    ChipSearch,
    IconButton
  }
})
export default class BlueprintDetailsStep extends NavigationStep {
  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get blueprintDescription(): string {
    return this.blueprintStore.selectedBlueprint.description;
  }

  private set blueprintDescription(value: string) {
    this.blueprintStore.selectedBlueprint.description = HelperMethods.isStringEmpty(value) ? null : value;
  }

  private mounted(): void {
    this.onMount(BlueprintEditStep.Details);
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onMount(index: BlueprintEditStep): void {}
}
