var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"typeaheaddropdown",staticClass:"type-ahead-dropdown__container",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"type-ahead-input__container",on:{"click":_vm.toggleDropdown}},[_c('b-form-input',{class:[
        'text-input',
        'type-ahead-input',
        { 'type-ahead-input-active': _vm.showDropdown },
        { 'has-error': _vm.hasError }
      ],attrs:{"value":_vm.value,"title":_vm.value,"placeholder":_vm.selectPlaceholder,"type":"text","disabled":_vm.disabled,"readonly":true}}),_c('span',{staticClass:"type-ahead__dropdown"})],1),(_vm.showDropdown)?_c('div',{class:['type-ahead-dropdown-options__container', { 'type-ahead-dropdown-options-active': _vm.showDropdown }]},[(_vm.showSearchBar)?_c('search-bar',{staticClass:"type-ahead-dropdown__search",attrs:{"placeholder":_vm.searchPlaceholder},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),_c('div',{staticClass:"type-ahead-dropdown__options scrollbar"},[_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option[_vm.idProperty],staticClass:"choice cut-off",attrs:{"title":option[_vm.displayField]}},[_c('div',{staticClass:"type-ahead-dropdown__option",on:{"click":function($event){return _vm.select(option)}}},[_vm._v(" "+_vm._s(option[_vm.displayField])+" ")])])}),(_vm.hasAddType)?_c('div',{staticClass:"type-ahead-dropdown-addtype"},[_c('b-button',{on:{"click":function($event){_vm.$emit('addNewTypeClick');
            _vm.hideDropDown();}}},[_vm._v(" "+_vm._s(_vm.addOptionText)+" ")])],1):_vm._e()],2)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }