import Axios, { AxiosInstance, Method, CancelTokenSource } from 'axios';
import { values } from 'lodash';
export default class HttpClient {
  private cancelTokens: { [key: string]: CancelTokenSource } = {};
  constructor(private readonly axios: AxiosInstance, private readonly baseUri: string) {}

  public async get<T>(uri: string, query?: {}, cancelToken?: string): Promise<T> {
    let options;

    const queryConfig = query ? { params: query } : undefined;

    options = this.getCancelToken(cancelToken);

    const config = !!queryConfig || !!options ? { ...queryConfig, ...options } : undefined;
    return (await this.axios.get<T>(this.uri(uri), config)).data;
  }

  public async post<T>(uri: string, payload?: any, config: any = null, cancelToken?: string): Promise<T> {
    let options;

    options = this.getCancelToken(cancelToken);

    const configuration = !!config || !!options ? { ...config, ...options } : undefined;

    if (configuration) {
      return (await this.axios.post<T>(this.uri(uri), payload, configuration)).data;
    }
    return (await this.axios.post<T>(this.uri(uri), payload)).data;
  }

  public async put<T>(uri: string, payload?: any): Promise<T> {
    return (await this.axios.put<T>(this.uri(uri), payload)).data;
  }

  public async delete<T>(uri: string): Promise<T> {
    return (await this.axios.delete<T>(this.uri(uri))).data;
  }

  public async request<T>(method: Method, uri: string, data?: any): Promise<T> {
    return (await this.axios.request<T>({ data, method, url: uri })).data;
  }

  protected uri(uri?: string): string {
    return uri ? `${this.baseUri}/${uri}` : this.baseUri;
  }

  public cancelAll(): void {
    const cancelTokens = values(this.cancelTokens);
    cancelTokens.map((value) => {
      value.cancel();
    });
  }

  public cancel(tokens: string[]): void {
    if (tokens.length > 0) {
      tokens.map((key) => this.cancelTokens[key]?.cancel());
    }
  }

  private getCancelToken(tokenId): { cancelToken: string } {
    let options;

    if (this.cancelTokens[tokenId] != null) {
      this.cancelTokens[tokenId].cancel();
    }

    if (Axios?.CancelToken && tokenId) {
      this.cancelTokens[tokenId] = Axios.CancelToken.source();
      options = {
        cancelToken: this.cancelTokens[tokenId].token
      };
    }
    return options;
  }
}
