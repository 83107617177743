
import { Vue, Component } from 'vue-property-decorator';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import AddKey from '@/components/burnersPage/AddKey.vue';
import AirRegister from '@/components/burnersPage/AirRegister.vue';
import { IConfigCustomFieldViewModel, DamperKConfig, IBurnerViewModel } from '../../view-models/burner-view-model';
import {showError} from '@/utils/StoreHelper';
import { IBurnerLayoutViewModel } from '@/view-models/burner-layout-view-model';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { ControlTypes } from '@/enums/subGroupTypes';

Vue.use(BootstrapVue);

@Component({
  components: {
    AddKey,
    AirRegister,
    OnpointModal
  }
})
export default class BurnerConfiguration extends Vue {
  private selectedAsset = store.state.assetState.selectedAsset;
  private airRegisters = store.state.assetState.selectedBurner.burnerDetail.config.airRegisters;
  private config: DamperKConfig = {
    Step: 0,
  };
  private validationsSet: boolean = false;
  private showModal: boolean = false;

  get selectedBurner(): IBurnerViewModel {
    return store.state.assetState.selectedBurner;
  }

  get burnerLayout(): IBurnerLayoutViewModel[] {
    return store.state.assetState.selectedBurner.burnerDetail.burnerLayout;
  }

  get hasBurnerLayout(): boolean {
    return this.burnerLayout !== null;
  }

  private validateDamperKField(value: string): boolean {
    return this.validationsSet && value === '0';
  }

  private deleteKey(customKey: IConfigCustomFieldViewModel): void {
    if (this.selectedBurner && this.selectedBurner.burnerDetail.config.customFields) {
      const index = this.selectedBurner.burnerDetail.config.customFields.indexOf(customKey, 0);
      if (index > -1) {
        this.selectedBurner.burnerDetail.config.customFields.splice(index, 1);
        this.saveSelectedAsset();
      }
    }
  }

  private zeroOrBlank(value: any): boolean {
    return value === '0' || value === '';
  }

  private saveCustomField(field: any): void {
    if (!field.value) {
      field.value = '0';
    }
    this.saveSelectedAsset();
  }

  private async saveSelectedAsset(value?: boolean): Promise<void> {
    try {
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
      if (value && this.hasBurnerLayout) {
        this.showModal = true;
      }
    } catch (err) {
      showError(err.message);
    }
  }

  private hasMultipleDamperK(): boolean {
    return !(
        this.selectedBurner &&
        this.selectedBurner.burnerDetail.zoneType &&
        this.selectedBurner.burnerDetail.zoneType === 'flowelements.burner_lpmw'
    );
  }

  private addAirRegister(): void {
    if (this.hasMultipleDamperK()) {
      store.commit('assetState/addDamperKAirRegister', this.getAirRegisterName());
    } else {
      store.commit('assetState/addDamperAreasAirRegister', this.getAirRegisterName());
    }
    this.updateBurnerLayout();
    this.updateSelectedAsset();
    this.saveSelectedAsset(true);
  }

  private updateBurnerLayout(): void {
    if (this.hasBurnerLayout) {
      store.commit('assetState/updateBurnerLayoutWithAirRegisterOrFuelLines', ControlTypes.AirRegister);
    }
  }

  private updateSelectedAsset(): void {
    store.commit('assetState/updateSelectedAssetWithBurner');
  }

  private getAirRegisterName(): string {
    const arList = this.airRegisters.map((item) => {
      return item.name ? parseInt(item.name.split('-')[item.name.split('-').length - 1].trim(), 10) || 0 : 0;
    });
    const arName = arList.length > 0 ? (Math.max(...arList) + 1) : 1;
    return 'Air Register - ' + arName;
  }

  private hideModal(): void {
    this.showModal = false;
  }

  private updateAirRegister(value: boolean): void {
    this.showModal = value;
  }
}
