import { render, staticRenderFns } from "./BurnerDrilling.vue?vue&type=template&id=23e29aad&scoped=true&"
import script from "./BurnerDrilling.vue?vue&type=script&lang=ts&"
export * from "./BurnerDrilling.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./BurnerDrilling.vue?vue&type=style&index=0&id=23e29aad&prod&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23e29aad",
  null
  ,true
)

export default component.exports