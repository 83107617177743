
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import Burners from '../burnersPage/Burners.vue';
import Agreements from '../agreementsPage/Agreements.vue';
import SubcriptionsPage from '../subscriptionsPage/Subscriptions.vue';
import AssetInformation from '../assetDetails/AssetInformation.vue';
import Location from '../locationPage/Location.vue';
import FlowNetwork from '../flowNetworkPage/FlowNetwork.vue';
import OnpointModal from '../common/OnpointModal.vue';

Vue.use(BootstrapVue);

@Component({
  components: {
    AssetInformation,
    SubcriptionsPage,
    Burners,
    Agreements,
    Location,
    FlowNetwork,
    OnpointModal
  }
})
export default class AssetDetailsHeader extends Vue {
  private store = store;
  private selectedAsset = store.state.assetState.selectedAsset;
  private allowLeave: boolean = false;
  private leaveModalOpen: boolean = false;
  private destinationTab: string = '';

  public mounted(): void {
    if (!this.selectedAsset) {
      this.$router.push('/');
    }
  }

  public destroyed(): void {
    store.dispatch('assetState/updateUnsavedChanges', false);
  }

  get unsavedChanges(): boolean {
    return store.getters['assetState/getUnsavedChanges'];
  }

  private leaveModalOk: () => void = () => false;

  private navigate(navKey: string): void {
    if (this.unsavedChanges) {
      this.leaveModalOk = () => this.navigate(navKey);
      this.showModal();
      return;
    }

    if (navKey === '/') {
      store.state.navState.selectedAssetTab = 'info';
    }
    this.$router.push(navKey);
    store.state.navState.prevNavKey = 'asset-details';
  }

  private checkSelectedTab(tabKey: string): boolean {
    return store.state.navState.selectedAssetTab === tabKey;
  }

  private setSelectedTab(tabKey: string): void {
    this.destinationTab = tabKey;
    if (this.unsavedChanges) {
      this.showModal();
    } else {
      store.state.navState.selectedAssetTab = tabKey;
    }
  }

  private showModal(): void {
    this.leaveModalOpen = true;
  }

  private hideLeaveModal(): void {
    this.leaveModalOpen = false;
  }

  private async leavePage(): Promise<void> {
    store.state.navState.selectedAssetTab = this.destinationTab;
    await store.dispatch('assetState/updateUnsavedChanges', false);
    store.commit('assetState/updateSelectedAssetSaved', '');
    if (this.leaveModalOk) {
      this.leaveModalOk();
    }
    this.hideLeaveModal();
  }

}
