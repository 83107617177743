
import Vue from 'vue';
import { Component, Emit, Model, Prop, Watch } from 'vue-property-decorator';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { BlueprintPropertyType } from '@/components/asset-services/models/blueprint-enums';
import HelperMethods from '@/shared/helper-methods';
import DateTimePickerModal from '@/components/common/date-time-picker-modal.vue';

@Component({
  name: 'list-definition',
  components: {
    DateTimePickerModal,
    OnpointModal
  }
})
export default class ListDefinition extends Vue {
  @Prop({ required: true })
  private type: BlueprintPropertyType;
  @Prop({ required: true })
  private title: string;
  @Prop({ required: false })
  private buttonLabel: string;
  @Prop({ required: true })
  private uniqueId: string;
  @Prop({ default: false })
  private noDuplicates: boolean;
  @Prop({ default: false })
  private hasError: boolean;
  @Prop({ default: false })
  private disabled: boolean;
  @Model('change')
  private items: any[];

  private showListModal: boolean = false;
  private localInput: string = null;
  private localItems: any[] = [];
  private BlueprintPropertyType = BlueprintPropertyType;

  private get validationRules(): object {
    return {
      integerIsValid: this.type === BlueprintPropertyType.int,
      doubleIsValid: this.type === BlueprintPropertyType.double,
      excluded: this.noDuplicates ? this.localItems : []
    };
  }

  private get showAddButton(): boolean {
    return ![BlueprintPropertyType.bool, BlueprintPropertyType.dateTime].includes(this.type);
  }

  private async onEnterPress(): Promise<void> {
    const isValid = await this.$validator.validateAll();
    if (!HelperMethods.isStringEmpty(this.localInput) && isValid) {
      const inputCopy = JSON.parse(JSON.stringify(this.localInput));
      switch (this.type) {
        case BlueprintPropertyType.double:
        case BlueprintPropertyType.int:
          this.localItems.push(Number(inputCopy));
          break;
        default:
          this.localItems.push(inputCopy);
      }
      this.localInput = null;
    }
  }

  private addItem(value: any): void {
    if (value != null && !(this.noDuplicates && this.localItems.includes(value))) {
      this.localItems.push(value);
    }
  }

  private deleteItem(index: number): void {
    this.localItems.splice(index, 1);
  }

  private saveItems(): void {
    this.emitChange(this.localItems);
    this.close();
  }

  private close(): void {
    this.showListModal = false;
    this.localInput = null;
    this.onValueChange(this.items);
    this.$nextTick(() => this.$validator.reset());
  }

  @Watch('items', { deep: true, immediate: true })
  private onValueChange(newItems: any[]): void {
    this.localItems = HelperMethods.isNullOrUndefined(newItems) ? [] : JSON.parse(JSON.stringify(newItems));
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitChange(items: any[]): void {}
}
