
import { Component, Vue, Prop, Emit, Model, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { BFormInput } from 'bootstrap-vue';
import HelperMethods from '@/shared/helper-methods';
import VueI18n from 'vue-i18n';

@Component({
  name: 'search-bar'
})
export default class SearchBar extends Vue {
  @Model('input')
  private value?: string;
  @Prop({ default: i18n.t('global.search') })
  private placeholder: string;
  @Prop({ default: false })
  private showAddButton: boolean;
  @Prop({ default: false })
  private doValidation: boolean;
  @Prop({ default: '' })
  private validationRule: string | object;

  private localSearchTerm: string = null;

  private get searchTermIsEmpty(): boolean {
    return HelperMethods.isStringEmpty(this.localSearchTerm);
  }

  private onEnterPress(): void {
    if (this.doValidation && this.$validator.errors.any()) {
      return;
    }
    const input = this.$refs.searchInput as BFormInput;
    this.emitKeyPressEnter(input.localValue);
    input.focus();
  }

  private validate(): Promise<boolean> {
    return this.$validator.validateAll();
  }

  private errorByRule(errors, field: string): VueI18n.TranslateResult {
    return HelperMethods.errorByRule(errors, field, this.$i18n, 'Blueprint');
  }

  @Watch('value')
  private onValueChange(newValue: string): void {
    this.localSearchTerm = newValue;
  }

  @Watch('localSearchTerm')
  private onLocalSearchTermChange(newLocalSearch: string): void {
    this.emitSearchTerm(newLocalSearch);
  }

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitSearchTerm(val: string): void {}

  @Emit('enter-pressed')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitKeyPressEnter(currentText: string): void {}
}
