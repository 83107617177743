import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IBurnerConfigHierarchy } from '@/view-models/hierarchy-view-model';

export class HierarchyService extends BaseService {
  constructor(axios: AxiosInstance, hbApiUrl: string) {
    super(hbApiUrl, axios);
  }

  public async getBurnerConfigHierarchies(
    assetKey: string,
    burnerConfigKey: string
  ): Promise<IBurnerConfigHierarchy[]> {
    return this.httpClient.get<IBurnerConfigHierarchy[]>(
      `hierarchies/assets/${assetKey}/burnerConfigHierarchies/${burnerConfigKey}`
    );
  }
}
