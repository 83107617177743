
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'icon-button'
})
export default class IconButton extends Vue {
  @Prop({ default: false })
  private readonly disabled: boolean;
  @Prop({ required: true })
  private readonly iconClass: string;
  @Prop()
  private readonly text?: string;

  @Emit('click')
  private onClick(eventEmit: MouseEvent): void {}
}
