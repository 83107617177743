
import { BvTableFieldArray } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import CreateAssetModal from '@/components/asset-services/asset-list/create-asset-modal.vue';
import { AssetDisplayModel } from '@/components/asset-services/models/asset-interfaces';
import IconButton from '@/components/common/dsm/icon-button.vue';
import Loading from '@/components/common/Loading.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import SearchBar from '@/components/common/search-bar.vue';
import Dropdown from '@/components/utility/Dropdown.vue';
import store, { getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'asset-list-page',
  components: {
    CreateAssetModal,
    Dropdown,
    IconButton,
    Loading,
    OnpointModal,
    SearchBar
  }
})
export default class AssetListPage extends Vue {
  private assetFilter: string = '';
  private perPageItems = 10;
  private currentPage = 1;
  private confirmationModal = { show: false, title: '', body: '', assetId: '' };

  private get freezeDeleteFlag(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetFields(): BvTableFieldArray {
    return [
      {
        label: this.$t('assets.fields.name').toString(),
        key: 'name',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        label: this.$t('assets.fields.status').toString(),
        key: 'state',
        sortable: true,
        thStyle: { width: '10%' }
      },
      {
        label: this.$t('assets.fields.createdBy').toString(),
        key: 'createdBy',
        sortable: true,
        thStyle: { width: '15%' }
      },
      {
        label: this.$t('assets.fields.createdDate').toString(),
        key: 'createdDate',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        label: this.$t('assets.fields.lastModified').toString(),
        key: 'updatedDate',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        label: this.$t('assets.fields.version').toString(),
        key: 'version',
        sortable: true,
        thStyle: { width: '10%' }
      },
      {
        label: '',
        key: 'menu',
        thStyle: { width: '5%' }
      }
    ];
  }

  private get assetDisplayModels(): AssetDisplayModel[] {
    return this.assetStore.assetContexts.map((context) => {
      const asset = context.entity;
      return new AssetDisplayModel({
        assetId: context.entityId,
        name: asset.name.value,
        state: context.entityState,
        link: `/asset-services/asset/edit/${context.blueprintId}/${context.entityId}`,
        createdBy: asset._CreatedBy.value,
        createdDate: asset._CreatedDate.value,
        updatedBy: asset._UpdatedBy.value,
        updatedDate: asset._UpdatedDate.value,
        version: context.entityVersion?.toString()
      });
    });
  }

  private get isLoading(): boolean {
    return this.assetStore.assetsLoadingMessage != null;
  }

  private get filteredRows(): AssetDisplayModel[] {
    const filterStringFormatted = this.assetFilter.toLowerCase();
    return this.assetDisplayModels.filter((model) => {
      return model.name?.toLowerCase().includes(filterStringFormatted);
    });
  }

  private resetConfirmationModal(): void {
    this.confirmationModal = { show: false, title: '', body: '', assetId: '' };
  }

  private openConfirmationModal(asset: AssetDisplayModel): void {
    const title = this.$t('assets.confirmation.delete.title').toString();
    const body = this.$t('assets.confirmation.delete.body', { name: asset.name }).toString();
    this.confirmationModal = { show: true, title, body, assetId: asset.assetId };
  }

  private confirm(): void {
    const assetId = this.confirmationModal.assetId;
    this.resetConfirmationModal();
    this.deleteAsset(assetId);
  }

  private deleteAsset(assetId: string): void {
    this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.deleting'));
    this.assetStore
      .deleteAsset(assetId)
      .then(() => {
        this.assetStore.removeAsset(assetId);
        this.$bvToast.toast(this.$t('toast.tenSecondsToReflect').toString(), {
          title: this.$t('toast.deleteSuccess').toString(),
          toaster: 'b-toaster-top-right',
          variant: 'success',
          noCloseButton: true
        });
      })
      .catch((err) => showError(err))
      .finally(() => this.assetStore.setAssetsLoadingMessage(null));
  }

  private filterAssets(value: string): void {
    this.assetFilter = value;
  }

  private addAsset(): void {
    (this.$refs.createAssetModal as CreateAssetModal).showCreateModal();
  }
}
