
import { Vue, Component } from 'vue-property-decorator';
import AssetDetailsReferences from '@/components/asset-services/asset-edit/asset-details-references.vue';
import AssetDetailsTable from '@/components/asset-services/asset-edit/asset-details-table.vue';
import AssetProperty from '@/components/asset-services/asset-edit/asset-property.vue';
import { BlueprintPropertyTypeGroup } from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint } from '@/components/asset-services/models/blueprint-interfaces';
import { NodeType } from '@/components/asset-services/models/asset-enums';
import { IAssetInstanceFull, IAssetNode } from '@/components/asset-services/models/asset-interfaces';
import Loading from '@/components/common/Loading.vue';
import store, { getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { getBlueprintProperties } from '@/utils/blueprints-helper';

@Component({
  name: 'asset-details',
  components: {
    AssetDetailsReferences,
    AssetDetailsTable,
    AssetProperty,
    Loading
  }
})
export default class AssetDetails extends Vue {
  private NodeType = NodeType;
  private loading: number = 0;

  private get versionDetailFlag(): boolean {
    return process.env.NODE_ENV !== 'production';
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get selectedNode(): IAssetNode {
    return this.assetStore.selectedNode;
  }

  private get nodeName(): string {
    if (this.selectedNode.type === NodeType.Asset) {
      return (this.selectedNode.element as IAssetInstanceFull).name?.value;
    }
    return this.selectedNode.propName;
  }

  private get blueprint(): IBlueprint {
    return this.blueprintStore.allAssetBlueprints.find((blueprint) => blueprint.id === this.selectedNode.blueprintId);
  }

  private get noBasicProperties(): boolean {
    const blueprintProperties = getBlueprintProperties(this.blueprint);
    return !blueprintProperties?.some((prop) => prop.propertyTypeGroup === BlueprintPropertyTypeGroup.Basic);
  }

  // TODO: References logic
  private get hasChildReferences(): boolean {
    return false;
  }

  private get hasParentReference(): boolean {
    return false;
  }

  private get parentReferenceName(): string {
    return '';
  }
}
