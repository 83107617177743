
import { Vue, Component, Prop, PropSync, Watch, Model } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component
export default class Diameter extends Vue {
  @Model('update')
  private selectedItem: any;
  @PropSync('unit', { required: true, default: 0 })
  private selectedUnit: any;
  @Prop({ required: true })
  private header: string;
  @Prop({ required: true })
  private placeholder: string;
  @Prop({ required: true })
  private units: string[];
  @Prop({ default: true })
  private validState: boolean;
  @Prop({ default: false })
  private warningState: boolean;

  private valueToShow: number = 0;

  public created(): void {
    if (this.units[this.selectedUnit].toLowerCase() === 'inch') {
      this.valueToShow = Number(((this.selectedItem * 1000) / 25.4).toPrecision(8));
    } else {
      this.valueToShow = Number(this.selectedItem * 1000);
    }
  }

  @Watch('valueToShow')
  private updateSelectedItem(): void {
    let value = '';
    if (this.units[this.selectedUnit].toLowerCase() === 'mm') {
      value = (this.valueToShow / 1000).toPrecision(8);
    } else {
      value = ((this.valueToShow * 25.4) / 1000).toPrecision(8);
    }

    // Avoid invoking any watchers if the value is unchanged
    if (parseFloat(this.selectedItem).toPrecision(8) !== value) {
      this.$emit('update', value);
    }
  }

  private updateUnit(i: number): void {
    if (this.units[this.selectedUnit].toLowerCase() === 'mm' && i !== this.selectedUnit) {
      this.valueToShow = Number((this.valueToShow / 25.4).toPrecision(8));
    } else if (i !== this.selectedUnit) {
      this.valueToShow = Number((this.valueToShow * 25.4).toPrecision(8));
    }
    this.selectedUnit = i;
    this.$emit('update:unit', i);
  }
}
