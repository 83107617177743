
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import TextEntry from './TextEntry.vue';
import Diameter from './Diameter.vue';
import store from '../../store';
import { ValidationField, validateForm, resetValidations } from '../../utils/Validator';
import { wholeNumberFormatter } from '../../utils/Formatter';
import { caeEventBus } from '@/eventBus/asset-event-bus';
import { BurnerDrillingEnums } from '@/enums/burnerDrillingEnums';
import { ITipViewModel, IPortViewModel } from '@/view-models/burner-view-model';
import Port from './Port.vue';
import AddDrillingComponent from './AddDrillingComponent.vue';

@Component({
  components: { AddDrillingComponent, Diameter, Port, TextEntry }
})
export default class Tip extends Vue {
  @Prop({ required: true })
  private tip: ITipViewModel;
  @Prop({ required: true })
  private saveAsset: () => void;
  @Prop({ required: true })
  private upsertTipInState: (config: any, headerIndex: number, manifoldIndex: number, tipIndex: number) => void;
  @Prop({ required: true })
  private headerIndex: number;
  @Prop({ required: true })
  private manifoldIndex: number;
  @Prop({ required: true })
  private tipIndex: number;
  @Prop({ required: true })
  private tipList: ITipViewModel;

  private isExpanded: boolean = false;
  private unitButtons = ['mm', 'inch'];
  private choiceButtons = ['yes', 'no'];
  private selectedUnit = 0;
  private doubleUnit = 0;
  private selectedChoice = 1;
  private store = store;
  private isFormDirty = false;
  private saving = false;
  private selectedBurner = store.state.assetState.selectedBurner;
  private parentManifoldExistsInState: boolean = false;

  private validateForm = validateForm;
  private resetValidations = resetValidations;
  private validations: { [key: string]: ValidationField } = {
    name: {
      valid: true,
      warning: false,
      value: () => this.tip.name,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    cd: {
      valid: true,
      warning: false,
      value: () => this.tip.cd,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    diameter: {
      valid: true,
      warning: false,
      value: () => this.tip.diameter,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    diameterUnit: {
      valid: true,
      warning: false,
      value: () => this.tip.diameterUnit,
      validator: (value: string) => value != null,
      setWarning: () => false
    },
    number: {
      valid: true,
      warning: false,
      value: () => this.tip.number,
      validator: (value: number) => value != null && typeof value === 'number',
      setWarning: (value: number) => value === 0
    },
    doubleDropCd: {
      valid: true,
      warning: false,
      value: () => this.tip.doubleDropCd,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    doubleDropDiameter: {
      valid: true,
      warning: false,
      value: () => this.tip.doubleDropDiameter,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    doubleDropDiameterUnit: {
      valid: true,
      warning: false,
      value: () => this.tip.doubleDropDiameterUnit,
      validator: (value: string) => value != null,
      setWarning: () => false
    }
  };

  private wholeNumberFormatter = wholeNumberFormatter;

  public created(): void {
    this.selectedChoice = this.tip.doubleDrop === true ? 0 : 1;
    this.selectedUnit = this.setUnit(this.tip.diameterUnit);
    this.doubleUnit = this.setUnit(this.tip.doubleDropDiameterUnit);
  }

  private mounted(): void {
    this.updateApplyButton();

    caeEventBus.$on(BurnerDrillingEnums.UpdateApplyButtons, () => {
      this.updateApplyButton();
    });
    caeEventBus.$on(BurnerDrillingEnums.CollapseAll, () => {
      this.isExpanded = false;
    });
    caeEventBus.$on(BurnerDrillingEnums.ExpandAll, () => {
      this.isExpanded = true;
    });
  }

  private beforeDestroy(): void {
    caeEventBus.$off(BurnerDrillingEnums.UpdateApplyButtons);
    caeEventBus.$off(BurnerDrillingEnums.CollapseAll);
    caeEventBus.$off(BurnerDrillingEnums.ExpandAll);
  }

  public applyAll(): void {
    this.apply(false);
    this.tip.ports?.forEach((port, portIndex) => {
      const portReference = `port-${this.headerIndex}-${this.manifoldIndex}-${this.tipIndex}-${portIndex}`;
      const portElement = this.$refs[portReference] as Port[];
      portElement[0].apply(false);
    });
  }

  private setUnit(unit: string): number {
    for (let i = 0; i < this.unitButtons.length; i++) {
      if (unit && unit.toLowerCase() === this.unitButtons[i]) {
        return i;
      }
    }
    return 1;
  }

  private toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  private doubleDrop(index: number): void {
    this.selectedChoice = index;
    if (this.tip.doubleDrop) {
      this.resetValidations(this.validations, ['doubleDropCd', 'doubleDropDiameter', 'doubleDropDiameterUnit']);
    }
    this.tip.doubleDrop = index === 0;
  }

  public apply(save: boolean): void {
    this.saving = true;
    const validKeys = this.tip.doubleDrop
      ? Object.keys(this.validations)
      : ['name', 'cd', 'diameter', 'diameterUnit', 'number'];

    if (this.validateForm(this.validations, validKeys)) {
      const configToSave = {
        name: this.tip.name,
        cd: this.tip.cd,
        diameter: this.tip.diameter,
        diameterUnit: this.tip.diameterUnit,
        number: this.tip.number,
        doubleDropCd: this.tip.doubleDropCd,
        doubleDropDiameter: this.tip.doubleDropDiameter,
        doubleDropDiameterUnit: this.tip.doubleDropDiameterUnit,
        doubleDrop: this.tip.doubleDrop
      };
      this.upsertTipInState(configToSave, this.headerIndex, this.manifoldIndex, this.tipIndex);
      if (save) {
        this.saveAsset();
      }
      this.isFormDirty = false;
      store.commit('navState/updateBurnerTabEnabled', true);
    } else {
      store.commit('errorState/setError', {
        error: 'Please enter a value for all fields.',
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
    this.saving = false;
  }

  private updateApplyButton(): void {
    if (
      !this.selectedBurner.burnerDetail.drilling[this.headerIndex] ||
      !this.selectedBurner.burnerDetail.drilling[this.headerIndex].manifolds[this.manifoldIndex]
    ) {
      this.parentManifoldExistsInState = false;
    } else {
      this.parentManifoldExistsInState = true;
    }
  }

  private resetValues(): void {
    Object.assign(this.tip, {
      name: 'Tip Name',
      diameterUnit: 'INCH',
      diameter: '1.063',
      cd: '0.85',
      number: 1,
      doubleDrop: false,
      doubleDropDiameterUnit: 'INCH',
      doubleDropDiameter: '0.003175',
      doubleDropCd: '0.9'
    });
    this.selectedUnit = 1;
    this.doubleUnit = 1;
    this.selectedChoice = 1;
  }

  private emptyPort(): IPortViewModel[] {
    return [
      {
        name: 'PortName',
        diameterUnit: 'INCH',
        diameter: '0.0015875',
        number: 1
      }
    ];
  }

  private upsertPortInState(
    config: any,
    headerIndex: number,
    manifoldIndex: number,
    tipIndex: number,
    portIndex: number
  ): void {
    const portToUpdate = this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds[manifoldIndex].tips[tipIndex]
      .ports[portIndex];
    const parentTip = this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds[manifoldIndex].tips[tipIndex];
    if (portToUpdate) {
      Object.assign(portToUpdate, config);
    } else {
      const newPort = config;
      parentTip.ports.push(newPort);
    }
  }

  @Watch('selectedUnit')
  private updateUnit(): void {
    this.tip.diameterUnit = this.selectedUnit === 0 ? 'mm' : 'inch';
  }

  @Watch('doubleUnit')
  private updateDoubleUnit(): void {
    this.tip.doubleDropDiameterUnit = this.doubleUnit === 0 ? 'mm' : 'inch';
  }

  @Watch('tip.name', { deep: true })
  @Watch('tip.cd', { deep: true })
  @Watch('tip.diameter', { deep: true })
  @Watch('tip.diameterUnit', { deep: true })
  @Watch('tip.number', { deep: true })
  @Watch('tip.doubleDropCd', { deep: true })
  @Watch('tip.doubleDropDiameter', { deep: true })
  @Watch('tip.doubleDropDiameterUnit', { deep: true })
  @Watch('tip.doubleDrop', { deep: true })
  private formDirty(): void {
    this.isFormDirty = true;
    store.commit('navState/updateBurnerTabEnabled', false);
  }

/* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit()
  private deleteConfirm(
    unsavedComponentArray: any,
    componentToDelete: any,
    headerIndex?: number,
    manifoldIndex?: number,
    tipIndex?: number,
    portIndex?: number
  ): void {}
}
