import { AxiosInstance } from 'axios';
import HttpClient from './http-client';

export default class BaseService {
  protected httpClient: HttpClient;

  constructor(private readonly base: string, private readonly http: AxiosInstance) {
    this.base = base;
    this.http = http;
    this.http.defaults.baseURL = this.base;
    this.httpClient = new HttpClient(this.http, '');
  }

  protected getUrl(uri: string): string {
    return uri;
  }

  public cancelAll(): void {
    this.httpClient.cancelAll();
  }

  public cancel(tokens: string[]): void {
    this.httpClient.cancel([...tokens]);
  }
}
