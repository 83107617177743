
import { Vue, Component, Prop, Model, Watch, Emit } from 'vue-property-decorator';
import SearchBar from '@/components/common/search-bar.vue';
import ChipListItem, { IChipItem } from '@/components/common/chip-list-item.vue';
import HelperMethods from '@/shared/helper-methods';
import i18n from '@/i18n';

@Component({
  name: 'chip-search',
  components: {
    ChipListItem,
    SearchBar
  }
})
export default class ChipSearch extends Vue {
  @Model('change')
  private searchTags: string[];
  @Prop({ default: i18n.t('searchTags.placeholder') })
  private placeholder: string;
  @Prop({ default: i18n.t('searchTags.title') })
  private title: string;
  @Prop({ default: null })
  private tagline: string;
  @Prop({ default: true })
  private removable: boolean;

  private currentSearchValue: string = null;
  private localSearchTags: string[] = [];

  private get chipSearchItems(): Array<IChipItem<string>> {
    return this.localSearchTags.map((searchTerm) => ({
      text: searchTerm,
      value: searchTerm
    }));
  }

  private onSearchEnter(searchTerm: string): void {
    const arrSearchTerms = searchTerm.split(',');

    arrSearchTerms.forEach((searchTag: string) => {
      const formattedSearchTag = searchTag?.trim().toLowerCase();
      if (!HelperMethods.isStringEmpty(formattedSearchTag) && !this.localSearchTags.includes(formattedSearchTag)) {
        this.localSearchTags.push(formattedSearchTag);
        this.emitChange([...this.localSearchTags]);
      }
    });

    this.$nextTick(() => (this.currentSearchValue = null));
  }

  private removeChip(chip: IChipItem<string>): void {
    this.localSearchTags = this.localSearchTags.filter((searchTerm) => searchTerm !== chip.value);
    this.emitChange([...this.localSearchTags]);
  }

  @Watch('searchTags', { deep: true, immediate: true })
  private onSearchTagsChange(searchTags: string[]): void {
    this.localSearchTags = [...searchTags];
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitChange(searchTags: string[]): void {}
}
