
import {Vue, Component, Watch} from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import {showError} from '@/utils/StoreHelper';
import { IAsset } from '@/view-models/assets-view-models';
import { cloneDeep } from 'lodash';

Vue.use(BootstrapVue);

@Component
export default class AssetDetailsHeader extends Vue {
  private selectedAsset: IAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
  private isDirty = false;
  
  public async mounted(): Promise<void> {
    try {
      await store.dispatch('assetState/retrieveAssetUnits', this.selectedAsset);
    } catch (err) {
      showError(err.message);
    }
  }

  @Watch('selectedAsset', {deep: true})
  private async markUnsavedChanges(): Promise<void> {
    this.isDirty = await store.dispatch('assetState/hasUnsavedChanges', this.selectedAsset);
  }

  private setEquipmentType(type: string): void {
    if (this.selectedAsset) {
      this.selectedAsset.equipmentType = type;
      this.selectedAsset.equipmentSubtype = 'Ethylene';
    }
  }

  private revertSelectedAsset(): void {
    if (this.selectedAsset) {
      this.selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
      store.commit('assetState/updateSelectedAssetSaved', '');
      this.isDirty = false;
    }
  }  

  private setEquipmentSubType(type: string): void {
    if (this.selectedAsset) {
      this.selectedAsset.equipmentSubtype = type;
    }
  }

  private eqTypeSelection(eqType: string): 'selection-selected' | 'selection' {
    if (this.selectedAsset) {
      return this.selectedAsset.equipmentType === eqType ? 'selection-selected' : 'selection';
    } else {
      return 'selection';
    }
  }

  private eqSubTypeSelection(eqType: string): 'selection-selected' | 'selection' {
    if (this.selectedAsset) {
      return this.selectedAsset.equipmentSubtype === eqType ? 'selection-selected' : 'selection';
    } else {
      return 'selection';
    }
  }

  private debugSelection(debugLevel: number): 'selection-selected' | 'selection' {
    if (this.selectedAsset) {
      return this.selectedAsset.loggingLevel === debugLevel ? 'selection-selected' : 'selection';
    } else {
      return 'selection';
    }
  }

  private setLoggingLevel(level: number): void {
    if (this.selectedAsset) {
      this.selectedAsset.loggingLevel = level;
    }
  }

  private saveUnitName(): void {
    if (this.selectedAsset) {
      this.selectedAsset.unitKey = null;
      this.selectedAsset.unitName = null;
    }
  }

  private async saveSelectedAsset(): Promise<void> {
    store.commit('app/updateIsLoading', true);
    try {
      this.saveUnitName();
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
      this.isDirty = false;
      await store.dispatch('assetState/updateUnsavedChanges', false);
    } catch (err) {
      showError(err.message);
    } finally {
      store.commit('app/updateIsLoading', false);
    }
  }

  private async createUnit(unitName: string, unitKey: string): Promise<void> {
    if (this.selectedAsset && this.canCreateUnit()) {
      await store.dispatch('assetState/createUnit',
        {unitName, unitKey, customerKey: this.selectedAsset.customerKey, siteKey: this.selectedAsset.siteKey});
      await store.dispatch('assetState/retrieveAssetUnits', this.selectedAsset);
      this.$forceUpdate();
    }
  }

  private canCreateUnit(): boolean {
    if (store.state.assetState.user) {
      return store.state.assetState.user.permissions.indexOf('ManageUnit') > -1;
    } else {
      return false;
    }
  }

  private async copyHeaterKey(): Promise<void> {
    if (this.selectedAsset) {
      await navigator.clipboard.writeText(this.selectedAsset.key);
    }
  }
}
