
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HelperMethods from '@/shared/helper-methods';
import SearchBar from '@/components/common/search-bar.vue';

@Component({
  name: 'checkbox-list-modal',
  components: {
    SearchBar
  }
})
export default class CheckboxListModal extends Vue {
  @Prop({ required: true })
  private title: string;
  @Prop({ required: false, default: '' })
  private subtitle: string;
  @Prop({ required: true })
  private options: object[];
  @Prop({ default: 'text' })
  private textField: string;
  @Prop({ default: 'value' })
  private valueField: string;
  @Prop({ default: () => [] })
  private selected: any[];

  private localSelected: any[] = [];
  private showModal: boolean = false;
  private filter = '';

  private get filteredOptions(): object[] {
    if (HelperMethods.isStringEmpty(this.filter)) {
      return this.options;
    }
    return this.options.filter((item) => item[this.textField].toLowerCase().includes(this.filter.toLowerCase()));
  }

  public displayModal(): void {
    this.refreshSelected();
    this.showModal = true;
  }

  private clearAll(): void {
    this.localSelected = [];
  }

  private close(): void {
    this.filter = '';
    this.showModal = false;
  }

  private submit(): void {
    this.$emit('change', this.localSelected);
    this.close();
  }

  @Watch('selected', { immediate: true, deep: true })
  private refreshSelected(): void {
    this.localSelected = [...this.selected];
  }
}
