
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import SearchBar from '@/components/common/search-bar.vue';

@Component({
  name: 'type-ahead-dropdown',
  components: {
    SearchBar
  }
})
export default class TypeAheadDropdown extends Vue {
  @Prop({ required: true })
  private dropdownOptions: object[];
  @Prop({ default: 'Select' })
  private selectPlaceholder: string;
  @Prop({ default: 'Search' })
  private searchPlaceholder: string;
  @Prop({ default: 'Add Option' })
  private addOptionText: string;
  @Prop({ default: false })
  private hasAddType: boolean;
  @Prop({ default: 'key' })
  private idProperty: string;
  @Prop({ default: 'name' })
  private displayField: string;
  @Prop({ default: false })
  private hasError: boolean;
  @Model('change')
  private value: string;
  @Prop({ default: false })
  private disabled: boolean;
  @Prop({ default: true })
  private showSearchBar: boolean;

  private filter: string = '';
  private showDropdown: boolean = false;

  private get filteredOptions(): object[] {
    if (this.filter) {
      const results = this.dropdownOptions.filter(
        (item) =>
          (item as any)[this.displayField]?.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 ||
          this.hasFilterTags(item, this.filter)
      );
      return [...results];
    }
    return [...this.dropdownOptions];
  }

  private parentEl: HTMLElement = {} as HTMLElement;

  private mounted(): void {
    this.parentEl = this.$el.closest('#app');
    this.parentEl.addEventListener('click', this.clickedOutside);
  }

  private destroy(): void {
    this.parentEl.removeEventListener('click', this.clickedOutside);
  }

  private toggleDropdown(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
    } else {
      this.showDropdown = false;
    }
    this.filter = '';
  }

  private clickedOutside(event: MouseEvent): void {
    const isDropDown = (this.$refs.typeaheaddropdown as HTMLElement)
      ? (this.$refs.typeaheaddropdown as HTMLElement).contains(event.target as HTMLElement)
      : false;

    if (!isDropDown) {
      this.hideDropDown();
    }
  }

  private select(option: object): void {
    this.$nextTick(() => {
      this.$emit('change', option);
      this.hideDropDown();
    });
  }

  private hideDropDown(): void {
    this.showDropdown = false;
    this.filter = '';
  }

  private hasFilterTags(value: any, search: string): number {
    return value.searchTags?.some((item) => item.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }
}
