import { AxiosInstance } from 'axios';
import { IBlueprint, IGetBlueprintRequest } from '@/components/asset-services/models/blueprint-interfaces';
import BaseService from '@/services/base-service';

export class BlueprintService extends BaseService {
  constructor(axios: AxiosInstance, kesApiUrl: string) {
    super(kesApiUrl, axios);
  }

  public async fetchBlueprints(): Promise<any> {
    return this.httpClient.get<any>('blueprints', {}, 'fetchBlueprints');
  }

  public getBlueprint(requestPayload: IGetBlueprintRequest): Promise<IBlueprint> {
    return this.httpClient.post<IBlueprint>(
      `blueprints/${requestPayload.blueprintIdentifier}/${requestPayload.blueprintState}`
    );
  }
}
