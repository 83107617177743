import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';
import { IErrorBusMessage, ErrorBusMessage, IErrorMessageConfig } from '../../view-models/error-model';

const EVENT_NAME = 'GLOBAL_ERROR';
const APP_TAG = 'Customer_Asset_Editor';

export interface IErrorStoreState {
  error: any;
  showModal: boolean;
  eventBus: any;
}

export interface IErrorStoreGetters extends GetterTree<IErrorStoreState, IRootState> {
  getError(state: IErrorStoreState): any;
}

export interface IErrorStoreMutations extends MutationTree<IErrorStoreState> {
  setError(
    state: IErrorStoreState,
    payload: {error: any, config?: IErrorMessageConfig, uiError?: boolean}
  ): void;
  clearError(state: IErrorStoreState): void;
}

export interface IErrorStoreActions extends ActionTree<IErrorStoreState, IRootState> {}

export type IErrorContext = ActionContext<IErrorStoreState, IRootState>;

export const ErrorStore = {
  namespaced: true,
  state: {
    error: null,
    showModal: false
  } as IErrorStoreState,
  getters: {
    getError(state: IErrorStoreState): any {
      return state.error;
    }
  } as IErrorStoreGetters,
  mutations: {
    setError(
      state: IErrorStoreState,
      payload: {error: any, config?: IErrorMessageConfig, uiError?: boolean}
    ): void {
      emitError(payload.error, payload.config, payload.uiError);
    },
    clearError(state: IErrorStoreState): void {
      state.error = null;
      state.showModal = false;
    }
  } as IErrorStoreMutations,
  actions: {} as IErrorStoreActions
};

const emitError = (error: any, config?: IErrorMessageConfig, uiError?: boolean): void => {
  ErrorStore.state.error = error;
  if ((window as any).eftEventBus && !uiError) {
    const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, error, config);
    (window as any).eftEventBus.$emit(EVENT_NAME, emitEvent);
  } else {
    ErrorStore.state.showModal = true;
  }
};

export class FakeEventBus {
  constructor() {
    (window as any).eftEventBus = {
      $emit(): void {
        // console.log(`${errorMessage.errorTag}: ${errorMessage.error}`);
      }
    };
  }
}
