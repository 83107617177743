import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import {
  Blueprint,
  IBlueprint,
  IBlueprintProperty,
  IGetBlueprintRequest
} from '@/components/asset-services/models/blueprint-interfaces';
import { BlueprintService } from '@/components/asset-services/services/blueprint-service';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { BlueprintState } from '@/components/asset-services/models/blueprint-enums';
import HelperMethods from '@/shared/helper-methods';
import { buildFromJsonSchema, getBlueprintProperties, sortBlueprintTypes } from '@/utils/blueprints-helper';
import jsonschema from 'jsonschema';
import { blueprintSchemas as mockBlueprints } from '@/store/__mocks__/blueprints';

@Module({ stateFactory: true, name: 'blueprintStore', namespaced: true, store })
export default class BlueprintModule extends VuexModule {
  private blueprintService: BlueprintService;
  public originalBlueprint: IBlueprint = new Blueprint();
  public selectedBlueprint: IBlueprint = new Blueprint();
  public blueprints: IBlueprint[] = [];
  public assetBlueprints: IBlueprint[] = [];
  public blueprintsLoading: boolean = false;

  public get publishedBlueprints(): IBlueprint[] {
    return this.blueprints
      .filter((blueprint: IBlueprint) => blueprint.state !== BlueprintState.Draft)
      .sort((a, b) => HelperMethods.sortString(a.title, b.title));
  }

  public get allAssetBlueprints(): IBlueprint[] {
    const assetBlueprints = [...this.assetBlueprints];
    this.blueprints.forEach((blueprint) => {
      const isUnique = !assetBlueprints.some((assetBlueprint) => {
        return assetBlueprint.id === blueprint.id;
      });
      if (isUnique) {
        assetBlueprints.push(blueprint);
      }
    });
    return assetBlueprints;
  }

  @Mutation
  public setBlueprintsLoading(loading: boolean): void {
    this.blueprintsLoading = loading;
  }

  @Mutation
  public setBlueprints(blueprints: IBlueprint[]): void {
    this.blueprints = [];
    blueprints.forEach((item: IBlueprint) => {
      this.blueprints.push(new Blueprint(item));
    });
  }

  @Mutation
  public setAssetBlueprints(blueprints: IBlueprint[]): void {
    this.assetBlueprints = blueprints.map((item: IBlueprint) => new Blueprint(item));
  }

  @Mutation
  public updateBlueprint(blueprint: IBlueprint): void {
    const index = this.blueprints.findIndex((item) => item.id === blueprint.id);
    this.blueprints.splice(index, 1, blueprint);
  }

  @Mutation
  public removeBlueprint(blueprintId: string): void {
    const index = this.blueprints.findIndex((item) => item.id === blueprintId);
    this.blueprints.splice(index, 1);
  }

  @Mutation
  public setSelectedBlueprint(blueprint: IBlueprint): void {
    const blueprintProperties = getBlueprintProperties(blueprint);
    blueprintProperties.forEach((property: IBlueprintProperty) => {
      property.id = uuid();
    });
    sortBlueprintTypes(blueprint);
    this.selectedBlueprint = cloneDeep(blueprint);
    this.originalBlueprint = cloneDeep(blueprint);
  }

  @Action({ rawError: true })
  public async initBlueprintsService(blueprintService?: BlueprintService): Promise<void> {
    if (blueprintService) {
      this.blueprintService = blueprintService;
      return;
    }
    if (this.blueprintService == null) {
      const conf = await ConfigFactory.GetConfig();
      this.blueprintService = new BlueprintService(
        sharedAxiosInstance.sharedCaeAxiosInstance,
        process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL
          ? process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL
          : conf.get('apiBaseUrl')
      );
    }
  }

  @Action({ rawError: true })
  public async fetchBlueprintSchema(): Promise<IBlueprint[]> {
    await this.initBlueprintsService();
    const validator = new jsonschema.Validator();
    // const blueprintSchemas = await this.blueprintService.fetchBlueprints();
    const blueprintSchemas = mockBlueprints.schemas as any[];
    blueprintSchemas.forEach((item) => validator.addSchema(item, `${item.$id.replaceAll('/', '')}`));

    const blueprints = blueprintSchemas.map(
      (item) =>
        ({
          ...item,
          id: item.$id,
          state: item.state,
          searchTags: [],
          structProperties: buildFromJsonSchema(item, validator),
          selected: false
        } as IBlueprint)
    );
    this.setBlueprints(blueprints);
    return blueprints;
  }

  @Action({ rawError: true })
  public async fetchBlueprints(): Promise<IBlueprint[]> {
    await this.initBlueprintsService();
    const blueprints = await this.fetchBlueprintSchema();
    this.setBlueprints(blueprints);
    return blueprints;
  }

  @Action({ rawError: true })
  public async cancelAll(): Promise<void> {
    await this.initBlueprintsService();
    this.blueprintService.cancelAll();
  }

  @Action({ rawError: true })
  public async cancel(tokens: string[]): Promise<void> {
    await this.initBlueprintsService();
    this.blueprintService.cancel([...tokens]);
  }

  @Action({ rawError: true })
  public async loadBlueprint(requestPayload: IGetBlueprintRequest): Promise<IBlueprint> {
    await this.initBlueprintsService();
    this.setBlueprintsLoading(true);
    const blueprint = await this.blueprintService.getBlueprint(requestPayload);
    this.setSelectedBlueprint(blueprint);
    this.setBlueprintsLoading(false);
    return blueprint;
  }
}
