
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import TextEntry from './TextEntry.vue';
import Diameter from './Diameter.vue';
import { ValidationField, validateForm, resetValidations } from '../../utils/Validator';
import store from '../../store';
import { caeEventBus } from '@/eventBus/asset-event-bus';
import { BurnerDrillingEnums } from '@/enums/burnerDrillingEnums';
import { IManifoldViewModel, ITipViewModel, IPortViewModel } from '@/view-models/burner-view-model';
import Tip from './Tip.vue';
import AddDrillingComponent from './AddDrillingComponent.vue';

@Component({
  components: { AddDrillingComponent, Diameter, TextEntry, Tip }
})
export default class Manifold extends Vue {
  @Prop({ required: true })
  private manifold: IManifoldViewModel;
  @Prop({ required: true })
  private saveAsset: () => void;
  @Prop({ required: true })
  private upsertManifoldInState: (config: any, headerIndex: number, manifoldIndex: number) => void;
  @Prop({ required: true })
  private headerIndex: number;
  @Prop({ required: true })
  private manifoldIndex: number;
  @Prop({ required: true })
  private manifoldList: IManifoldViewModel[];

  private isExpanded: boolean = false;
  private unitButtons = ['mm', 'inch'];
  private choiceButtons = ['yes', 'no'];
  private selectedUnit = 0;
  private doubleUnit = 0;
  private selectedChoice = 1;
  private store = store;
  private isFormDirty = false;
  private saving = false;
  private selectedBurner = store.state.assetState.selectedBurner;
  private parentHeaderExistsInState: boolean = false;
  private validateForm = validateForm;
  private resetValidations = resetValidations;
  private validations: { [key: string]: ValidationField } = {
    name: {
      valid: true,
      warning: false,
      value: () => this.manifold.name,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    diameter: {
      valid: true,
      warning: false,
      value: () => this.manifold.diameter,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    diameterUnit: {
      valid: true,
      warning: false,
      value: () => this.manifold.diameterUnit,
      validator: (value: string) => value != null,
      setWarning: () => false
    },
    doubleDropCd: {
      valid: true,
      warning: false,
      value: () => this.manifold.doubleDropCd,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    doubleDropDiameter: {
      valid: true,
      warning: false,
      value: () => this.manifold.doubleDropDiameter,
      validator: (value: string) => value != null,
      setWarning: (value: string) => value === '0'
    },
    doubleDropDiameterUnit: {
      valid: true,
      warning: false,
      value: () => this.manifold.doubleDropDiameterUnit,
      validator: (value: string) => value != null,
      setWarning: () => false
    }
  };

  public created(): void {
    this.selectedChoice = this.manifold.doubleDrop ? 0 : 1;
    this.selectedUnit = this.setUnit(this.manifold.diameterUnit);
    this.doubleUnit = this.setUnit(this.manifold.doubleDropDiameterUnit);
  }

  private mounted(): void {
    this.updateApplyButton();

    caeEventBus.$on(BurnerDrillingEnums.UpdateApplyButtons, () => {
      this.updateApplyButton();
    });
    caeEventBus.$on(BurnerDrillingEnums.CollapseAll, () => {
      this.isExpanded = false;
    });
    caeEventBus.$on(BurnerDrillingEnums.ExpandAll, () => {
      this.isExpanded = true;
    });
  }

  private beforeDestroy(): void {
    caeEventBus.$off(BurnerDrillingEnums.UpdateApplyButtons);
    caeEventBus.$off(BurnerDrillingEnums.CollapseAll);
    caeEventBus.$off(BurnerDrillingEnums.ExpandAll);
  }

  public applyAll(): void {
    this.apply(false);
    this.manifold.tips?.forEach((tip, tipIndex) => {
      const tipReference = `tip-${this.headerIndex}-${this.manifoldIndex}-${tipIndex}`;
      const tipElement = this.$refs[tipReference] as Tip[];
      tipElement[0].applyAll();
    });
  }

  @Watch('selectedUnit')
  private updateUnit(): void {
    this.manifold.diameterUnit = this.selectedUnit === 0 ? 'mm' : 'inch';
  }

  @Watch('doubleUnit')
  private updateDoubleUnit(): void {
    this.manifold.doubleDropDiameterUnit = this.doubleUnit === 0 ? 'mm' : 'inch';
  }

  private setUnit(unit: string): number {
    for (let i = 0; i < this.unitButtons.length; i++) {
      if (unit && unit.toLowerCase() === this.unitButtons[i]) {
        return i;
      }
    }
    return 1;
  }

  private toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  private doubleDrop(index: number): void {
    this.selectedChoice = index;
    if (this.manifold.doubleDrop) {
      this.resetValidations(this.validations, ['doubleDropCd', 'doubleDropDiameter', 'doubleDropDiameterUnit']);
    }
    this.manifold.doubleDrop = index === 0;
  }

  public apply(save: boolean): void {
    this.saving = true;
    const validKeys = this.manifold.doubleDrop ? Object.keys(this.validations) : ['name', 'diameter', 'diameterUnit'];

    if (this.validateForm(this.validations, validKeys)) {
      const configToSave = {
        name: this.manifold.name,
        diameter: this.manifold.diameter,
        diameterUnit: this.manifold.diameterUnit,
        doubleDropCd: this.manifold.doubleDropCd,
        doubleDropDiameter: this.manifold.doubleDropDiameter,
        doubleDropDiameterUnit: this.manifold.doubleDropDiameterUnit,
        doubleDrop: this.manifold.doubleDrop
      };
      this.upsertManifoldInState(configToSave, this.headerIndex, this.manifoldIndex);
      if (save) {
        this.saveAsset();
      }
      this.isFormDirty = false;
      store.commit('navState/updateBurnerTabEnabled', true);
    } else {
      store.commit('errorState/setError', {
        error: 'Please enter a value for all fields.',
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
    this.saving = false;
  }

  @Watch('manifold.name', { deep: true })
  @Watch('manifold.diameter', { deep: true })
  @Watch('manifold.diameterUnit', { deep: true })
  @Watch('manifold.doubleDropCd', { deep: true })
  @Watch('manifold.doubleDropDiameter', { deep: true })
  @Watch('manifold.doubleDropDiameterUnit', { deep: true })
  @Watch('manifold.doubleDrop', { deep: true })
  private formDirty(): void {
    this.isFormDirty = true;
    store.commit('navState/updateBurnerTabEnabled', false);
  }

  private updateApplyButton(): void {
    if (!this.selectedBurner.burnerDetail.drilling[this.headerIndex]) {
      this.parentHeaderExistsInState = false;
    } else {
      this.parentHeaderExistsInState = true;
    }
  }

  private resetValues(): void {
    Object.assign(this.manifold, {
      name: 'Manifold Name',
      doubleDrop: false,
      diameter: '2.067',
      diameterUnit: 'INCH',
      doubleDropDiameterUnit: 'INCH',
      doubleDropCd: '0.9',
      doubleDropDiameter: '0.003175'
    });
    this.selectedUnit = 1;
    this.doubleUnit = 1;
    this.selectedChoice = 1;
  }

  private upsertTipInState(config: any, headerIndex: number, manifoldIndex: number, tipIndex: number): void {
    const tipToUpdate = this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds[manifoldIndex].tips[tipIndex];
    const parentManifold = this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds[manifoldIndex];
    if (tipToUpdate) {
      Object.assign(tipToUpdate, config);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    } else {
      const newTip = config;
      newTip.ports = [];
      parentManifold.tips.push(newTip);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    }
  }

  private emptyTip(): ITipViewModel[] {
    return [
      {
        name: 'Tip Name',
        diameterUnit: 'INCH',
        diameter: '1.063',
        cd: '0.85',
        number: 1,
        doubleDrop: false,
        doubleDropDiameterUnit: 'INCH',
        doubleDropDiameter: '0.003175',
        doubleDropCd: '0.9',
        ports: this.emptyPort()
      }
    ];
  }

  private emptyPort(): IPortViewModel[] {
    return [
      {
        name: 'PortName',
        diameterUnit: 'INCH',
        diameter: '0.0015875',
        number: 1
      }
    ];
  }

/* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit()
  private deleteConfirm(
    unsavedComponentArray: any,
    componentToDelete: any,
    headerIndex?: number,
    manifoldIndex?: number,
    tipIndex?: number,
    portIndex?: number
  ): void {}
}
