import { AxiosInstance } from 'axios';
import {
  IAssetBundle,
  IAssetBundleContextResponse,
  IAssetBundleResponse,
  IAssetExportResponse,
  IAssetImportRequest,
  IAssetImportResponse,
  IAssetInstanceEnumeratedResponse,
  IAssetSaveRequest
} from '@/components/asset-services/models/asset-interfaces';
import BaseService from '@/services/base-service';

export class AssetService extends BaseService {
  constructor(axios: AxiosInstance, kesApiUrl: string) {
    super(kesApiUrl, axios);
  }

  public async getAssetsByCustomer(customerKey: string, format: string): Promise<IAssetInstanceEnumeratedResponse> {
    return this.httpClient.get<IAssetInstanceEnumeratedResponse>(
      `entities/customers/${customerKey}/${format}`,
      {},
      'getAssetsByCustomer'
    );
  }

  public async deleteAsset(assetId: string): Promise<void> {
    return this.httpClient.delete<void>(`hierarchies/${assetId}`);
  }

  public async getAssetBundle(assetId: string): Promise<IAssetBundleContextResponse> {
    return this.httpClient.get<IAssetBundleContextResponse>(`hierarchies/${assetId}`);
  }

  public async validate(bundle: IAssetBundle): Promise<IAssetBundleResponse> {
    return this.httpClient.post<IAssetBundleResponse>(`hierarchies/validate`, bundle);
  }

  public async save(request: IAssetSaveRequest): Promise<IAssetBundleResponse> {
    return this.httpClient.post<IAssetBundleResponse>(`hierarchies/`, request);
  }

  public async cloneAsset(fromAssetId: string, newAssetName: string): Promise<IAssetBundleContextResponse> {
    return this.httpClient.post<IAssetBundleContextResponse>(`hierarchies/${fromAssetId}/clone/${newAssetName}`);
  }

  public async publish(assetId: string): Promise<IAssetBundleResponse> {
    return this.httpClient.post<IAssetBundleResponse>(`hierarchies/${assetId}/publish`);
  }

  public async export(assetId: string): Promise<IAssetExportResponse> {
    const options = {
      headers: {
        accept: 'text/csv'
      },
      responseType: 'blob'
    };
    return this.httpClient.get<any>(`hierarchies/export/${assetId}`, options);
  }

  public async import(request: IAssetImportRequest): Promise<IAssetImportResponse> {
    return this.httpClient.post<IAssetImportResponse>(`hierarchies/import`, request);
  }
}
