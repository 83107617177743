import { NavigationGuard } from 'vue-router';
import store, { getAssetServiceStoreModule } from '@/store';
import * as BlueprintsHelperMethods from '@/utils/blueprints-helper';

export const assetServiceGuard: NavigationGuard = async (to, from, next) => {
  try {
    const assetServiceStore = getAssetServiceStoreModule(store);
    if (assetServiceStore.assetServiceIsEnabled) {
      next();
    } else {
      next({ name: 'LegacyHome' });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error redirect');
  }
};

export const blueprintServiceGuard: NavigationGuard = async (to, from, next) => {
  try {
    if (
      BlueprintsHelperMethods.canViewBlueprints &&
      BlueprintsHelperMethods.canManageBlueprints &&
      BlueprintsHelperMethods.canManageNonSystemBlueprint
    ) {
      next();
    } else {
      next('/asset-services');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error redirect');
  }
};
