export enum SubGroupTypes {
  None = 'None',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary'
}

export enum ControlTypes {
  AirRegister = 'AirRegister',
  FuelLine = 'FuelLine'
}

export enum FuelLineSubTypes {
  None = 'None',
  ThreeWayValve = 'ThreeWayValve'
}
