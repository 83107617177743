
import { v4 as uuid } from 'uuid';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  BlueprintPropertySourceType,
  BlueprintPropertyTypeGroup,
  BlueprintPropertyType,
  BlueprintConstraintType
} from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint, IBlueprintProperty } from '@/components/asset-services/models/blueprint-interfaces';
import IconButton from '@/components/common/dsm/icon-button.vue';
import ListDefinition from '@/components/common/list-definition.vue';
import TypeAheadDropdown from '@/components/common/type-ahead-dropdown.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'blueprint-property',
  components: {
    IconButton,
    ListDefinition,
    TypeAheadDropdown
  }
})
export default class BlueprintProperty extends Vue {
  @Prop({ required: true })
  private id: string;
  @Prop({ required: true })
  private property: IBlueprintProperty;

  private BlueprintPropertyTypeGroup = BlueprintPropertyTypeGroup;
  private localProperty: IBlueprintProperty = {
    id: uuid(),
    name: null,
    propertyTypes: [],
    propertyTypeGroup: null,
    constraintType: BlueprintConstraintType.None,
    validValues: null,
    isList: false,
    isReadOnly: false,
    propertySources: [],
    defaultPropertySource: null,
    isOptional: false,
    isSystem: false
  };

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get propertyTypeOptions(): Array<{ name: string; value: BlueprintPropertyType }> {
    const propertyTypes = Object.keys(BlueprintPropertyType) as BlueprintPropertyType[];
    return propertyTypes.map((type) => ({
      name: this.$tc(`blueprints.propertyTypes.${type}`),
      value: type
    }));
  }

  private get listTypeOptions(): Array<{ name: string; value: BlueprintPropertyType }> {
    const propertyTypes = Object.keys(BlueprintPropertyType) as BlueprintPropertyType[];
    return propertyTypes
      .filter((type) => type === BlueprintPropertyType.blueprint)
      .map((type) => ({
        name: this.$tc(`blueprints.propertyTypes.${type}`),
        value: type
      }));
  }

  private get blueprintOptions(): Array<{ name: string; value: IBlueprint }> {
    return this.blueprintStore.publishedBlueprints
      .filter((blueprint: IBlueprint) => blueprint.id !== this.blueprintStore.selectedBlueprint?.id)
      .map((blueprint) => ({
        name: blueprint.title,
        value: blueprint,
        searchTags: blueprint.searchTags
      }));
  }

  private get propertySourceOptions(): Array<{ name: string; value: BlueprintPropertySourceType }> {
    const propertySources = Object.keys(BlueprintPropertySourceType) as BlueprintPropertySourceType[];
    return propertySources.map((propertySource) => ({
      name: this.$tc(`blueprints.propertySources.${propertySource}`),
      value: propertySource
    }));
  }

  private get defaultPropertySourceOptions(): Array<{ name: string; value: BlueprintPropertySourceType }> {
    return this.localProperty.propertySources.map((propertySource) => ({
      name: this.$tc(`blueprints.propertySources.${propertySource}`),
      value: propertySource
    }));
  }

  private get propertyTypeSelection(): string {
    if (this.localProperty.isList) {
      return this.$tc(`blueprints.propertyTypes.${BlueprintPropertyType.array}`);
    } else if (this.localProperty.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
      return this.$tc(`blueprints.propertyTypes.${BlueprintPropertyType.blueprint}`);
    } else if (this.localProperty.propertyTypeGroup === BlueprintPropertyTypeGroup.Basic) {
      const basicPropertyTypes = this.localProperty.propertyTypes.filter((propertyType) => propertyType.basicType);
      if (basicPropertyTypes.length > 0) {
        return this.propertyTypeOptions.find((option) => option.value === basicPropertyTypes[0].basicType)?.name;
      }
    }
    return null;
  }

  private get listTypeSelection(): string {
    if (this.localProperty.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
      return this.$tc(`blueprints.propertyTypes.${BlueprintPropertyType.blueprint}`);
    } else if (this.localProperty.propertyTypeGroup === BlueprintPropertyTypeGroup.Basic) {
      const basicPropertyTypes = this.localProperty.propertyTypes.filter((propertyType) => propertyType.basicType);
      if (basicPropertyTypes.length > 0) {
        return this.listTypeOptions.find((option) => option.value === basicPropertyTypes[0].basicType)?.name;
      }
    }
    return null;
  }

  private get blueprintSelection(): string {
    const blueprintPropertyTypes = this.localProperty.propertyTypes.filter(
      (propertyType) => propertyType.blueprintType
    );
    if (blueprintPropertyTypes.length > 0) {
      return this.blueprintOptions.find(
        (option) => option.value.id === blueprintPropertyTypes[0].blueprintType.blueprintIdentifier
      )?.name;
    }
    return null;
  }

  private get showValidValues(): boolean {
    return !HelperMethods.isArrayEmpty(this.localProperty.validValues);
  }

  private get showDefaultPropertySource(): boolean {
    return this.defaultPropertySourceOptions.length > 1;
  }

  private propertySourceIconClass(propertySource: BlueprintPropertySourceType): string {
    if (this.localProperty.propertySources.includes(propertySource)) {
      return 'mdi-checkbox-marked';
    }
    return 'mdi-checkbox-blank-outline';
  }

  @Watch('property', { deep: true, immediate: true })
  private updateLocalProperty(property: IBlueprintProperty): void {
    this.localProperty = property;
  }
}
