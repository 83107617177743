
import { Vue, Component, Prop } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { ISubscriptionDetailViewModel } from '../../view-models/subscription-view-model';
import DeleteSubscription from './DeleteSubscription.vue';
import Dropdown from '../utility/Dropdown.vue';
import { IAsset } from '../../view-models/assets-view-models';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

@Component({
  components: {Dropdown, DeleteSubscription }
})
export default class ShowSubscription extends Vue {
  @Prop({ required: true })
  private subscriptionType: string;
  @Prop({ required: true })
  private subscriptionDetail: ISubscriptionDetailViewModel;
  @Prop({ required: true })
  private openModal: () => void;

  private showAlert = false;

  get selectedAsset(): IAsset {
    return store.getters['assetState/getSelectedAsset'];
  }

  private viewHistory(): void {
    store.commit('assetState/updateSelectedSubscription', this.subscriptionType);
    this.$router.push('subscription-history');
  }

  private async saveSubscriptionAlert(): Promise<void> {
    if (this.selectedAsset && this.selectedAsset.subscription) {
      if (this.subscriptionType === 'Fuel Side' && this.selectedAsset.subscription.fuelSide) {
        this.selectedAsset.subscription.fuelSide = this.subscriptionDetail;
      } else if (this.subscriptionType === 'Air Side' && this.selectedAsset.subscription.airSide) {
        this.selectedAsset.subscription.airSide = this.subscriptionDetail;
      } else if (this.subscriptionType === 'Flow Network' && this.selectedAsset.subscription.flowNetwork) {
        this.selectedAsset.subscription.flowNetwork = this.subscriptionDetail;
      }

      try {
        await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
      } catch (err) {
        showError(err.message);
      }
    }
  }

  private deleteSubscription(): void {
    store.commit('assetState/updateSelectedSubscription', this.subscriptionType);
    this.$bvModal.show('deleteSubscription');
  }
}
