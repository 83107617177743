import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IUnit } from '../view-models/customer-view-models';

export class KESCustomerService extends BaseService {
  constructor(axios: AxiosInstance, kesApiUrl: string) {
    super(kesApiUrl, axios);
  }

  public async getAssetUnits(customerKey: string, siteKey: string): Promise<IUnit[]> {
    return this.httpClient.get<IUnit[]>('api/customer/' + customerKey + '/site/' + siteKey + '/assetgroups');
  }

  public async postAssetUnits(
    unitName: string,
    unitKey: string,
    customerKey: string,
    siteKey: string
  ): Promise<string> {
    return this.httpClient.post<string>('api/customer/' + customerKey + '/site/' + siteKey + '/assetgroup', {
      key: unitKey,
      name: unitName
    });
  }
}
