
import { Component, Vue, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/utility/Dropdown.vue';
import SubGroupDetails from '@/components/subGroups/SubGroupDetails.vue';
import { ISubGroup, IBurnerViewModel } from '@/view-models/burner-view-model';
import HelperMethods from '@/shared/helper-methods';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { IAsset } from '@/view-models/assets-view-models';
import store from '@/store';
import { showError } from '@/utils/StoreHelper';
import { caeEventBus } from '@/eventBus/asset-event-bus';

@Component({
  name: 'display-sub-groups',
  components: {
    Dropdown,
    SubGroupDetails,
    OnpointModal
  }
})
export default class DisplaySubGroups extends Vue {
  @Prop({ required: true })
  private subGroup: ISubGroup;
  private showModal: boolean = false;
  private showDeleteProgress: boolean = false;
  private componentKey: number = 0;

  get hasSubGroupKey(): boolean {
    return !HelperMethods.isStringEmpty(this.subGroup.key);
  }

  get currentSubGroup(): ISubGroup {
    return JSON.parse(JSON.stringify(this.subGroup));
  }

  get selectedAsset(): IAsset  {
    return store.state.assetState.selectedAsset;
  }

  get selectedAssetCopy(): IAsset  {
    return JSON.parse(JSON.stringify(this.selectedAsset));
  }

  get selectedBurner(): IBurnerViewModel {
    return store.state.assetState.selectedBurner;
  }

  get subGroups(): ISubGroup[] {
    return store.state.assetState.selectedBurner.burnerDetail.subGroups;
  }

  public mounted(): void {
    if (!this.hasSubGroupKey) {
      this.collapseSection();
    }
  }

  public collapseSection(): void {
    this.$root.$emit('bv::toggle::collapse','collapse-' + this.subGroup.key);
  }

  public updateSubGroup(value: boolean): void {
    if (value) {
      this.collapseSection();
    }
  }

  private hideModal(): void {
    this.showModal = false;
  }

  private async deleteSubgroup(): Promise<void> {
    this.deleteUnsavedSubgroups();
    this.showDeleteProgress = true;
    try {
      const index = this.selectedAssetCopy.burnerList.findIndex((burner) =>
                    burner.burnerKey === this.selectedBurner.burnerKey);
      const subGroups = this.selectedAssetCopy.burnerList[index].burnerDetail.subGroups;
      subGroups.forEach((group) => {
        if (group.parentSubGroupKey === this.currentSubGroup.key) {
          group.parentSubGroupName = '';
          group.parentSubGroupKey = '';
        }
      });
      const subIndex = subGroups.findIndex((item) => item.key === this.currentSubGroup.key);
      if (subIndex > -1) {
        this.selectedAssetCopy.burnerList[index].burnerDetail.subGroups.splice(subIndex, 1);
      }
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAssetCopy)
        .then(() => {
          this.updateSubGroups();
        });
      store.commit('navState/updateBurnerTabEnabled', true);
    } catch (err) {
        showError(err.message);
    }
    this.showDeleteProgress = false;
    this.hideModal();
  }

  private updateSubGroups(): void {
    store.commit('assetState/updateSubGroups');
    caeEventBus.$emit('update-subgroup-children', true);
  }

  private editSubGroup(): void {
    this.deleteUnsavedSubgroups();
    this.componentKey += 1;
  }

  private deleteUnsavedSubgroups(): void {
    const index = this.subGroups.findIndex((item) => HelperMethods.isStringEmpty(item.key));
    if (index > -1) {
      this.subGroups.splice(index, 1);
    }
  }

  private setSubGroupDirty(): void {
    store.commit('navState/updateBurnerTabEnabled', false);
  }
}
