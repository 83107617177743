import { Module, VuexModule, Action, MutationAction, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { IUser } from '@/view-models/user-view-models';
import { IBlueprint, ISelectedItems } from '@/components/asset-services/models/blueprint-interfaces';
import { getUmApiUrl } from '@/services/api-service';
import { UserManagementService } from '@/services/user-management-service';

@Module({ stateFactory: true, name: 'assetServiceStore', namespaced: true, store })
export default class AssetServiceModule extends VuexModule {
  // public assetServiceIsEnabled: boolean = false;
  // public assetServiceGenerateData: boolean = false;
  public selectedParents: Map<string, ISelectedItems<IBlueprint>> = new Map<string, ISelectedItems<IBlueprint>>();
  public user: IUser = null;
  private userService: UserManagementService;


  public get assetServiceIsEnabled(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  public get assetServiceGenerateData(): boolean {
    return process.env.NODE_ENV !== 'production';
  }
  @Mutation
  public setUser(user: IUser): void {
    this.user = user;
  }

  @MutationAction({ mutate: ['selectedParents'], rawError: true })
  public async setSelectedParents(
    selectedParents: Map<string, ISelectedItems<IBlueprint>>
  ): Promise<{ selectedParents: Map<string, ISelectedItems<IBlueprint>> }> {
    return { selectedParents };
  }

  @Action({ rawError: true })
  public async initUserService(userService?: UserManagementService): Promise<void> {
    if (userService) {
      this.userService = userService;
      return;
    }
    if (this.userService == null) {
      const url = await getUmApiUrl();
      this.userService = new UserManagementService(url);
    }
  }

  // this comes from assetState eventually we want to move this logic to here.
  @Action({ rawError: true })
  public async retrieveServiceConfig(): Promise<void> {
    await store.dispatch('assetState/retrieveServiceConfig');
  }

  // this comes from assetState eventually we want to move this logic to here.
  @Action({ rawError: true })
  public async getUser(): Promise<void> {
    await this.initUserService();
    const user = await this.userService.getUser();
    this.setUser(user);
  }
}
