
import { Vue, Component } from 'vue-property-decorator';
import CheckboxListModal from '@/components/common/checkbox-list-modal.vue';

@Component({
  name: 'asset-details-references',
  components: {
    CheckboxListModal
  }
})
export default class AssetDetailsReferences extends Vue {
  private get childReferencesOptions(): Array<{ text: string; value: string }> {
    return [];
  }

  private get childReferencesSelected(): string[] {
    return [];
  }

  private get childReferencesText(): string[] {
    return this.childReferencesOptions
      .filter((option) => this.childReferencesSelected.includes(option.value))
      .map((option) => option.text);
  }

  private displayCheckboxListModal(): void {
    (this.$refs['checkbox-list-modal'] as CheckboxListModal).displayModal();
  }

  private editChildReferences(unusedSelected: string[]): void {
    // TODO: References logic
  }
}
