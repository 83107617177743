import { IPortalAppSdk } from '../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus(): Vue {
  return (window as any).eftEventBus as Vue;
}

export function inParentApp(): boolean {
  return parentEventBus() != null;
}

export const APP_TAG = 'customer-asset-editor' as const;
const APP_ROUTE_BASE = '/utilities/ember/asset-editor/' as const;

export function appRouteBase(): string {
  return inParentApp() ? APP_ROUTE_BASE : '/';
}

export function getParentAppSdk(): IPortalAppSdk {
  return document.getElementById(APP_TAG) as any;
}
