export interface IAgreementViewModel {
  agreementType: string;
  acceptedAgreement: boolean;
  agreementDate?: string | null;
}

export enum EntityType {
  Customer = 'Customer',
  CustomerSite = 'CustomerSite',
  AssetGrouping = 'AssetGrouping',
  Heater = 'Heater',
  Tower = 'Tower'
}

export interface IAssignmentNodeViewModel {
  key: string;
  name: string;
  parentKey?: string;
  orgKey: string;
  customerSiteKey?: string;
  type: EntityType;
}
