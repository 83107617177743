
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IAsset } from '@/view-models/assets-view-models';

Vue.use(BootstrapVue);

@Component
export default class ClearKeys extends Vue {
  @Prop({required: true})
  private selectedAsset: IAsset;

  private async deleteKeys(): Promise<void> {
    if (this.selectedAsset &&
        this.selectedAsset.flowNetwork &&
        this.selectedAsset.flowNetwork.options) {
      this.selectedAsset.flowNetwork.options = [];
      this.$emit('confirm', this.selectedAsset);
    }
    this.$bvModal.hide('clearOptions');
  }
}
