
import { BDropdown } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AssetNodeAction } from '@/components/asset-services/models/asset-enums';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'asset-node-action-menu'
})
export default class AssetNodeActionMenu extends Vue {
  @Prop({ required: true })
  private actionOptions: AssetNodeAction[];
  @Prop({ required: true })
  private nodeId: string;

  private isFocused: boolean = false;

  private get isMultiOptional(): boolean {
    return this.actionOptions.length > 1;
  }

  private get singleOption(): AssetNodeAction {
    return HelperMethods.isArrayEmpty(this.actionOptions) || this.isMultiOptional ? null : this.actionOptions[0];
  }

  private get singleOptionIcon(): string {
    switch (this.singleOption) {
      case AssetNodeAction.AddListItem:
        return 'mdi-plus-circle-outline';
      case AssetNodeAction.EditName:
        return 'mdi-circle-edit-outline';
      case AssetNodeAction.EditOptionalProperties:
        return 'mdi-circle-edit-outline';
      case AssetNodeAction.RemoveListItem:
        return 'mdi-delete';
      default:
        return null;
    }
  }

  private emitSelection(option: AssetNodeAction): void {
    this.$emit('option-selected', option);
  }

  private showMenu(): void {
    (this.$refs[`menu-${this.nodeId}`] as BDropdown).show();
  }
}
