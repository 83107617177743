
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ISubGroup, IBurnerViewModel, IAirRegister, IDrillingViewModel } from '@/view-models/burner-view-model';
import { showError } from '@/utils/StoreHelper';
import store from '@/store';
import { IAsset } from '@/view-models/assets-view-models';
import HelperMethods from '@/shared/helper-methods';
import { SubGroupTypes } from '@/enums/subGroupTypes';
import TextEntry from '@/components/burnerDrilling/TextEntry.vue';
import { IBSelect } from '@/view-models/b-select';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { caeEventBus } from '@/eventBus/asset-event-bus';

@Component({
  name: 'sub-group-details',
  components: {
    TextEntry,
    OnpointModal
  }
})
export default class SubGroupDetails extends Vue {
  @Prop({required: true})
  private subGroup: ISubGroup;
  private subGroupName: string = '';
  private subGroupType: SubGroupTypes = SubGroupTypes.None;
  private selectedParentSubGroup: string = '';
  private showProgress: boolean = false;
  private showError: boolean = false;
  private showModal: boolean = false;

  get selectedAsset(): IAsset  {
    return store.state.assetState.selectedAsset;
  }

  get selectedAssetCopy(): IAsset  {
    return JSON.parse(JSON.stringify(this.selectedAsset));
  }

  get subGroupTypes(): SubGroupTypes[] {
    return Object.values(SubGroupTypes);
  }

  get selectedBurner(): IBurnerViewModel {
    return store.state.assetState.selectedBurner;
  }

  get subGroups(): ISubGroup[] {
    return store.state.assetState.selectedBurner.burnerDetail.subGroups;
  }

  get hasSubGroups(): boolean {
    return this.subGroups && this.subGroups.length === 0;
  }

  get parentSubgroups(): IBSelect[] {
    const groups: IBSelect[] = [];
    if (!this.hasSubGroups) {
      this.subGroups.forEach((item) => {
        if ((item.key !== this.subGroup.key) &&
            !HelperMethods.isStringEmpty(item.key) &&
            !this.isChild(item.key)) {
              groups.push({ value: item.key, text: item.name });
        }
      });
      groups.sort((a,b) => {
        return (HelperMethods.sortString(a.text, b.text));
      });
      groups.unshift({ value: '', text: 'Select a Subgroup' });
    }
    if (HelperMethods.isStringEmpty(this.subGroup.parentSubGroupKey)) {
      this.selectedParentSubGroup = groups[0].value;
    }
    return groups;
  }

  get parentSubGroupName(): string {
    const index = this.parentSubgroups.findIndex((item) => item.value === this.selectedParentSubGroup);
    return this.parentSubgroups[index].text;
  }

  get isSubGroupNameEmpty(): boolean {
    return HelperMethods.isStringEmpty(this.subGroupName);
  }

  get isCurrentSubGroupName(): boolean {
    return HelperMethods.equalsIgnoreCase(this.subGroupName, this.subGroup.name);
  }

  get isInvalidSubGroup(): boolean {
    return !this.hasSubGroups &&
      this.subGroups.findIndex((item: ISubGroup) =>
        HelperMethods.equalsIgnoreCase(item.name, this.subGroupName) &&
        !HelperMethods.isStringEmpty(item.name)) > -1;
  }

  get airRegisters(): IAirRegister[] {
    return this.selectedBurner.burnerDetail.config.airRegisters;
  }

  get fuelTips(): IDrillingViewModel[] {
    return this.selectedBurner.burnerDetail.drilling;
  }

  get isSubgroupMapped(): boolean {
    let found: boolean = false;
    if (!HelperMethods.isStringEmpty(this.selectedParentSubGroup)) {
      found = this.airRegisters.some((item) => item.subGroupKey === this.selectedParentSubGroup);
      if (!found) {
        found = this.fuelTips.some((item) => item.subGroupKey === this.selectedParentSubGroup);
      }
    }
    return found;
  }

  public created(): void {
    this.updateChildren();
  }

  public mounted(): void {
    caeEventBus.$on('update-subgroup-children', (value: boolean) => {
      if (value) {
        this.updateChildren();
      }
    });
    this.setValues();
  }

  public beforeDestroy(): void {
    caeEventBus.$off('update-subgroup-children');
  }

  public setValues(): void {
    if (this.subGroup.key) {
      this.subGroupName = this.subGroup.name;
      this.subGroupType = this.subGroup.type;
      this.selectedParentSubGroup = this.subGroup.parentSubGroupKey;
    }
  }

  public saveSubGroup(): void {
    if (this.isSubGroupNameEmpty) {
      this.showError = true;
    } else {
      this.showError = false;
      if (this.isSubgroupMapped) {
        this.showModal = true;
      } else {
        this.showModal = false;
        this.createAndEditSubGroup();
        store.commit('navState/updateBurnerTabEnabled', true);
      }
    }
  }

  public async createAndEditSubGroup(): Promise<void> {
    this.showProgress = true;
    this.subGroup.name = this.subGroupName;
    this.subGroup.type = this.subGroupType;
    this.subGroup.parentSubGroupName = this.parentSubGroupName === 'Select a Subgroup' ?
                                          '': this.parentSubGroupName;
    this.subGroup.parentSubGroupKey = this.selectedParentSubGroup;
    try {
      const index = this.selectedAssetCopy.burnerList.findIndex((burner) =>
                    burner.burnerKey === this.selectedBurner.burnerKey);
      const subGroupItems = this.selectedAssetCopy.burnerList[index].burnerDetail.subGroups;
      const subIndex = subGroupItems.findIndex((item) => item.key === this.subGroup.key);
      this.updateValues(subGroupItems, subIndex);
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAssetCopy)
        .then(() => {
            this.updateSubGroups();
        });
    } catch (err) {
        showError(err.message);
        this.$emit('update-sub-group', false);
        store.commit('navState/updateBurnerTabEnabled', false);
    }
    this.showProgress = false;
  }

  public updateValues(subGroups: ISubGroup[], subIndex: number): void {
    if (subIndex === 0 && HelperMethods.isStringEmpty(this.subGroup.key)) {
      subGroups.splice(subIndex, 1, this.subGroup);
    } else if ((subIndex === 0 && !HelperMethods.isStringEmpty(this.subGroup.key)) || subIndex > 0) {
      subGroups.splice(subIndex, 1, this.subGroup);
      this.updateChildSubgroups(subGroups);
    } else {
      subGroups.unshift(this.subGroup);
    }
  }

  public updateChildSubgroups(subGroups: ISubGroup[]): void {
    subGroups.forEach((group) => {
      if (group.parentSubGroupKey === this.subGroup.key) {
        group.parentSubGroupName = this.subGroup.name;
      }
    });
  }

  public updateSubGroups(): void {
    store.commit('assetState/updateSubGroups');
    this.updateChildren();
    this.$emit('update-sub-group', true);
    store.commit('navState/updateBurnerTabEnabled', true);
  }

  public cancel(): void {
    if (!this.subGroup.key) {
      this.subGroupName = '';
      this.subGroupType = SubGroupTypes.None;
      this.selectedParentSubGroup = '';
    } else {
      this.setValues();
    }
    this.checkSubGroups();
    this.$emit('update-sub-group', true);
  }

  public checkSubGroups(): void {
    if (!this.subGroup.key) {
      this.subGroups.splice(this.subGroups.findIndex((group) =>
        HelperMethods.isStringEmpty(group.key)), 1);
    }
  }

  public updateChildren(): void {
    this.subGroups.forEach((item) => {
      item.children = [];
      if (!HelperMethods.isStringEmpty(item.key)) {
        item.children = this.getChildren(item.key);
      }
    });
  }

  public findChilds(key: string): string[] {
    return this.subGroups.reduce((childArr: string[], item) => {
      if (key === item.parentSubGroupKey &&  key !== item.key) {
        childArr.push(item.key);
      }
      return childArr;
    }, []);
  }

  public getChildren(key: string): string[] {
    let children = this.findChilds(key);
    for (const child of children) {
      const subChilds = this.findChilds(child);
      children = children.concat(subChilds);
    }
    return children;
  }

  public isChild(key: string): boolean {
    return (this.subGroup.children && this.subGroup.children.length > 0) ?
      this.subGroup.children.includes(key): false;
  }

  private hideModal(): void {
    this.showModal = false;
  }

  @Emit('subGroupValueChange')
  private subGroupValueChange(): void {
    /****/
  }
}
