import axios from 'axios';
import ConfigFactory from './config';
import authInterceptor from './service-interceptor';

const sharedCaeAxiosInstance = axios.create();
sharedCaeAxiosInstance.interceptors.request.use(authInterceptor);

const sharedKesAxiosInstance = axios.create();
sharedKesAxiosInstance.interceptors.request.use(authInterceptor);

const sharedHbAxiosInstance = axios.create();
sharedHbAxiosInstance.interceptors.request.use(authInterceptor);

const sharedUmAxiosInstance = axios.create();
sharedUmAxiosInstance.interceptors.request.use(authInterceptor);

export default {
  sharedCaeAxiosInstance,
  sharedKesAxiosInstance,
  sharedHbAxiosInstance,
  sharedUmAxiosInstance
};

export async function getUmApiUrl(): Promise<string> {
  const conf = await ConfigFactory.GetConfig();
  return process.env.VUE_APP_UM_API_URL ? process.env.VUE_APP_UM_API_URL : conf.get('umApiUrl');
}
