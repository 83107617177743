export enum BlueprintState {
  Draft = 'Draft',
  Published = 'Published'
}

export enum BlueprintConstraintType {
  None = 'None',
  AnyOf = 'AnyOf'
}

export enum BlueprintPropertyTypeGroup {
  Basic = 'Basic',
  Blueprint = 'Blueprint'
}

export enum BlueprintPropertySourceType {
  Static = 'Static',
  Formula = 'Formula',
  CustomerGenerated = 'CustomerGenerated',
  OnPointGenerated = 'OnPointGenerated'
}

export enum BlueprintPropertyType {
  double = 'double',
  bool = 'bool',
  string = 'string',
  int = 'int',
  array = 'array',
  dateTime = 'dateTime',
  blueprint = 'blueprint'
}

export enum BlueprintProperties {
  // Backend Reserved
  _AssetId = '_AssetId',
  _CreatedBy = '_CreatedBy',
  _CreatedDate = '_CreatedDate',
  _Key = '_Key',
  _LegacyKey = '_LegacyKey',
  _UpdatedBy = '_UpdatedBy',
  _UpdatedDate = '_UpdatedDate',
  name = 'name',
  Formulas = 'Formulas',
  Tags = 'Tags',
  // Custom Logic
  CustomerKey = 'CustomerKey',
  SiteKey = 'SiteKey'
}

export enum BlueprintEditStep {
  Details,
  Properties
}

export enum BlueprintPermissions {
  ViewBlueprints = 'ViewBlueprints',
  ManageSystemBlueprints = 'ManageSystemBlueprints',
  ManageNonSystemBlueprint = 'ManageNonSystemBlueprints'
}
