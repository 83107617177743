import { AssetState, NodeType } from '@/components/asset-services/models/asset-enums';
import {
  BlueprintPropertySourceType,
  BlueprintPropertyTypeGroup
} from '@/components/asset-services/models/blueprint-enums';
import { IBlueprintPropertyTypes } from '@/components/asset-services/models/blueprint-interfaces';

export interface IAssetInstanceContext {
  entityState: AssetState;
  entityId: string;
  entityVersion: number;
  blueprintId: string;
  blueprintVersion: string;
  entity: IAssetInstanceFull;
}

export interface IAssetInstanceFull {
  _AssetId?: AssetInstanceProperty;
  _CreatedBy?: AssetInstanceProperty;
  _CreatedDate?: AssetInstanceProperty;
  _Key?: AssetInstanceProperty;
  _LegacyKey?: AssetInstanceProperty;
  name?: AssetInstanceProperty;
  _UpdatedBy?: AssetInstanceProperty;
  _UpdatedDate?: AssetInstanceProperty;
  Tags?: AssetInstanceProperty[];
  Formulas?: AssetInstanceProperty[];
}

export interface IAssetNode {
  nodeId: string;
  blueprintId: string;
  type: NodeType;
  element: IAssetInstanceFull | AssetInstanceProperty[];
  elementId?: string;
  propName?: string;
  propOptional?: boolean;
  listIndex?: number;
  isOptional?: boolean;
}

export class AssetInstance implements IAssetInstanceFull {
  constructor(instance?: IAssetInstanceFull) {
    if (instance != null) {
      Object.assign(this, instance);
    }
  }
}

export class AssetInstanceProperty {
  public propertySource: BlueprintPropertySourceType = null;
  public propertyTypeGroup: BlueprintPropertyTypeGroup = BlueprintPropertyTypeGroup.Basic;
  public propertyType: IBlueprintPropertyTypes = { basicType: null, blueprintType: null };
  public value: any = null;
  public tagName: string = null;
  public formula: string = null;

  constructor(property?: AssetInstanceProperty) {
    if (property != null) {
      Object.assign(this, property);
    }
  }
}

export class AssetDisplayModel {
  public assetId: string = null;
  public name: string = null;
  public state: string = null;
  public link: string = null;
  public createdBy: string = null;
  public createdDate: string = null;
  public updatedBy: string = null;
  public updatedDate: string = null;
  public version: string = null;

  constructor(model?: AssetDisplayModel) {
    if (model != null) {
      Object.assign(this, model);
    }
  }
}

export class AssetPropertyDisplayModel {
  public name: string = null;
  public id: string = null;
  public propertySource: BlueprintPropertySourceType = null;
  public propertySourceOptions: BlueprintPropertySourceType[] = [];
  public value: any = null;
  public formula: string = null;
  public tagName: string = null;
  public validValues: any[] = null;
  public isList: boolean = false;
  public isOptional: boolean = false;
  public isReadonly: boolean = false;
  public isHidden: boolean = false;
  public propertyTypeGroup: BlueprintPropertyTypeGroup = BlueprintPropertyTypeGroup.Basic;
  public types: IBlueprintPropertyTypes[] = [];
  public type: IBlueprintPropertyTypes = null;
  public blueprintIdentifier: string = null;
  public showDetails: boolean = false;
  // eslint:disable-next-line: variable-name
  public get _showDetails(): boolean {
    return this.isList || this.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint;
  }

  constructor(propertyModel?: AssetPropertyDisplayModel) {
    if (propertyModel != null) {
      Object.assign(this, propertyModel);
    }
    this.showDetails = true;
  }
}

export interface IGetAssetResponse {
  entityVersion: number;
  entity: IAssetInstanceFull;
}

export interface IAssetInstanceEnumeratedResponse {
  entities: IAssetInstanceContext[];
  nextToken: string;
}

export interface IAssetBundle {
  asset: IAssetInstanceContext;
  entities: IAssetInstanceContext[];
  relationships: any[];
}

export interface IAssetBundleContextResponse {
  hierarchy: IAssetBundle;
}

export interface IAssetBundleFailure {
  resourceId: string;
  resourceType: string;
  reason: string;
  relationship: any;
}

export interface IAssetSaveRequest {
  hierarchy: IAssetBundle;
}

export interface IAssetBundleResponse {
  failures: IAssetBundleFailure[];
  hierarchy: IAssetBundle;
}

export interface IAssetExportResponse {
  failures: IAssetBundleFailure[];
  fileContents: string;
}

export interface IAssetImportResponse {
  hierarchy: IAssetBundle;
  failures: IAssetBundleFailure[];
}

export interface IAssetImportRequest {
  assetId: string;
  fileContents: string;
}

export interface IAssetOption {
  key: string;
  name: string;
  value: IAssetInstanceContext;
}
