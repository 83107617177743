
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import BurnerConfiguration from './BurnerConfiguration.vue';
import BurnerDrilling from '../burnerDrilling/BurnerDrilling.vue';
import OnpointModal from '../common/OnpointModal.vue';
import BurnerLayoutDesigner from '@/components/burnersPage/BurnerLayoutDesigner.vue';
import SubGroups from '@/components/subGroups/SubGroups.vue';

Vue.use(BootstrapVue);

@Component({
  components: {
    OnpointModal,
    BurnerDrilling,
    BurnerConfiguration,
    BurnerLayoutDesigner,
    SubGroups
  }
})
export default class BurnerDetails extends Vue {
  private store = store;
  private selectedBurner = store.state.assetState.selectedBurner;
  private showWarningModal = false;
  private routeToPush?: string = null;
  private tabToPush?: string = null;

  private navigate(navKey: string): void {
    if (store.state.navState.burnerTabEnabled) {
      if (navKey === '/') {
        store.state.navState.selectedAssetTab = 'info';
        store.state.navState.selectedBurnerTab = 'config';
      }
      this.$router.push(navKey);
      store.state.navState.prevNavKey = 'burner-details';
    } else {
      this.showWarningModal = true;
      this.routeToPush = navKey;
      this.tabToPush = null;
    }
  }

  private checkSelectedTab(tabKey: string): boolean {
    return store.state.navState.selectedBurnerTab === tabKey;
  }

  private setSelectedTab(tabKey: string): void {
    if (store.state.navState.burnerTabEnabled) {
      store.commit('navState/updateSelectedBurnerTab', tabKey);
    } else {
      this.showWarningModal = true;
      this.tabToPush = tabKey;
      this.routeToPush = null;
    }
  }

  private hideWarningModal(): void {
    this.showWarningModal = false;
  }

  private confirmNavigation(): void { // Changes to be done
    if (this.routeToPush) {
      if (this.routeToPush === '/') {
        store.state.navState.selectedAssetTab = 'info';
        store.state.navState.selectedBurnerTab = 'config';
      }
      this.$router.push(this.routeToPush);
      store.state.navState.prevNavKey = 'burner-details';
    } else if (this.tabToPush) {
      store.commit('navState/updateSelectedBurnerTab', this.tabToPush);
    }
    store.commit('assetState/resetBurnerUnsavedChanges');
    store.commit('navState/updateBurnerTabEnabled', true);
    this.hideWarningModal();
  }
}
