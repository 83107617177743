
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IBurnerViewModel } from '../../view-models/burner-view-model';
import { showError } from '@/utils/StoreHelper';
import Loading from '@/components/common/Loading.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'delete-burner',
  components: {
    Loading
  }
})
export default class DeleteBurner extends Vue {
  private store = store;
  private showModal: boolean = false;
  private loading: number = 0;
  private submitted: boolean = false;
  private resultMessage: string = null;

  get selectedBurner(): IBurnerViewModel {
    return store.getters['assetState/getSelectedBurner'];
  }

  get burnerName(): string {
    if (this.selectedBurner) {
      return this.selectedBurner.burnerName;
    }
  }

  private async attemptDelete(): Promise<void> {
    // check if can delete
    this.submitted = true;
    this.loading++;
    const canDelete = await store.dispatch('assetState/canDeleteBurner', this.selectedBurner.burnerKey);
    const burnerName = this.selectedBurner.burnerName;
    if (canDelete) {
      // remove burner from asset
      store.commit('assetState/deleteSelectedBurner', this.selectedBurner.burnerKey);
      // save asset
      try {
        await store.dispatch('assetState/saveSelectedAsset', store.state.assetState.selectedAsset);
        this.resultMessage = `Burner "${burnerName}" successfully deleted.`;
      } catch (err) {
        showError(err.message);
        this.resultMessage = `Failed to delete burner "${burnerName}".`;
      }
    } else {
      this.resultMessage = `Unable to delete burner "${burnerName}" as it is currently in use.`;
    }
    this.loading--;
  }

  private close(): void {
    this.showModal = false;
    this.submitted = false;
    this.resultMessage = null;
    this.loading = 0;
    store.commit('assetState/updateSelectedBurner', undefined);
  }
}
