
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { onInitValidValues, validationRules } from '@/components/asset-services/business-logic/asset-details-logic';
import { BlueprintPropertySourceType, BlueprintPropertyType } from '@/components/asset-services/models/blueprint-enums';
import { IBlueprintProperty } from '@/components/asset-services/models/blueprint-interfaces';
import { AssetInstanceProperty } from '@/components/asset-services/models/asset-interfaces';
import DateTimePickerModal from '@/components/common/date-time-picker-modal.vue';
import ListDefinition from '@/components/common/list-definition.vue';
import TypeAheadDropdown from '@/components/common/type-ahead-dropdown.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'asset-property',
  components: {
    DateTimePickerModal,
    ListDefinition,
    TypeAheadDropdown
  }
})
export default class AssetProperty extends Vue {
  @Prop({ required: true })
  private blueprintProperty: IBlueprintProperty;
  @Prop({ required: true })
  private propertySource: BlueprintPropertySourceType;
  @Prop({ required: true })
  private type: BlueprintPropertyType;
  @Prop({ required: true })
  private id: string;
  @Prop({ required: true })
  private isReadonly: boolean;
  @Prop({ required: true })
  private parentBlueprintId: string;
  @Model('change')
  private value: any;

  private BlueprintPropertyType = BlueprintPropertyType;
  private BlueprintPropertySourceType = BlueprintPropertySourceType;
  private localValue: any = null;

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get validationRules(): any {
    const rules = {} as any;
    if (!this.blueprintProperty.isOptional) {
      rules.required = true;
    }
    // Custom Business Logic
    const customValidationRules = validationRules(this.parentBlueprintId, this.blueprintProperty.name);
    customValidationRules?.forEach((rule) => (rules[rule] = true));
    return rules;
  }

  private get validValuesOptions(): Array<{ name: string; key: string }> {
    let options = this.blueprintProperty.validValues;
    // Custom Business Logic
    const customValidValues = onInitValidValues(this.parentBlueprintId, this.blueprintProperty.name);
    if (customValidValues) {
      options = customValidValues;
    }
    return options?.map((value) => ({ name: value, key: value }));
  }

  private get rawListValues(): any[] {
    if (this.blueprintProperty.isList && !HelperMethods.isArrayEmpty(this.localValue as any[])) {
      return (this.localValue as AssetInstanceProperty[]).map((listValue) => listValue.value);
    }
  }

  private set rawListValues(listValues: any[]) {
    this.localValue = listValues.map((rawListValue) => {
      return new AssetInstanceProperty({
        propertySource: this.propertySource,
        propertyTypeGroup: this.blueprintProperty.propertyTypeGroup,
        propertyType: { basicType: this.type, blueprintType: null },
        value: rawListValue,
        tagName: null,
        formula: null
      });
    });
  }

  private get isPropertyValid(): boolean {
    return !this.$validator.errors.any();
  }

  private get minListLength(): number {
    return this.blueprintProperty.isOptional ? 0 : 1;
  }

  private parsePropertyValue(value: any): any {
    if (HelperMethods.isNullOrUndefined(value)) {
      return null;
    }
    if (this.blueprintProperty.isList) {
      return value as any[];
    }
    if (this.propertySource !== BlueprintPropertySourceType.Static) {
      return value?.toString();
    }
    switch (this.blueprintProperty.propertyTypes[0].basicType) {
      case BlueprintPropertyType.double:
      case BlueprintPropertyType.int:
        return HelperMethods.isStringEmpty(value) ? null : Number(value);
      case BlueprintPropertyType.bool:
        return Boolean(value);
      case BlueprintPropertyType.string:
        return value?.toString();
      default:
        return value;
    }
  }

  private onPropertyValueChange(value: any): void {
    this.localValue = this.parsePropertyValue(value);
  }

  private validate(): Promise<boolean> {
    return this.$validator.validateAll();
  }

  @Watch('value', { immediate: true, deep: true })
  private onValueChange(value: any): void {
    this.localValue = HelperMethods.isNullOrUndefined(value) ? null : value;
  }

  @Watch('localValue', { immediate: true, deep: true })
  private onLocalValueChange(localValue): void {
    if (typeof localValue === 'string' && HelperMethods.isStringEmpty(localValue)) {
      localValue = null;
    }
    this.$emit('change', localValue);
  }
}
