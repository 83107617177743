
import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import OnpointModal from '@/components/common/OnpointModal.vue';
import HelperMethods from '@/shared/helper-methods';
import moment from 'moment';

@Component({
  name: 'date-time-picker-modal',
  components: {
    OnpointModal
  },
  inheritAttrs: true
})
export default class DateTimePickerModal extends Vue {
  @Prop({ required: true })
  private id: string;
  @Prop({ default: false })
  private showTime: boolean;
  @Prop({ default: false })
  private hasError: boolean;
  @Prop({ default: false })
  private disabled: boolean;
  @Model('change')
  private value: string;

  private showDatePicker: boolean = false;
  private date: string = null;
  private time: string = '00:00:00';

  private get dateTime(): string {
    if (HelperMethods.isNullOrUndefined(this.date)) {
      return null;
    } else if (!this.showTime) {
      return this.date;
    }
    return moment(`${this.date}T${this.time}`).format('YYYY-MM-DD[T]HH:mm:ss');
  }

  private get triggerButtonText(): string {
    if (HelperMethods.isStringEmpty(this.value)) {
      return this.$t('date.select').toString();
    }
    if (this.showTime) {
      return moment.utc(this.value).local().format('MM/DD/YYYY hh:mm:ss a');
    }
    return moment.utc(this.value).local().format('MM/DD/YYYY');
  }

  private openDatePicker(): void {
    if (this.disabled) {
      return;
    }
    // convert utc time to local time
    this.dateSelected(moment.utc(this.value).local().format('YYYY-MM-DD'));
    this.time = moment.utc(this.value).local().format('HH:mm:ss');
    this.showDatePicker = true;
  }

  private dateSelected(date: string): void {
    this.date = date;
  }

  private clear(): void {
    this.dateSelected(null);
    this.time = '00:00:00';
  }

  private submit(): void {
    // convert local time to utc time
    this.emitChange(moment(this.dateTime).utc().format('YYYY-MM-DD[T]HH:mm:ss'));
    this.close();
  }

  private close(): void {
    this.clear();
    this.showDatePicker = false;
    this.onValueChange(this.value);
  }

  @Watch('value')
  private onValueChange(value: string): void {
    if (HelperMethods.isNullOrUndefined(value)) {
      this.clear();
    } else {
      this.dateSelected(moment(value).format('YYYY-MM-DD'));
      this.time = moment(value).format('HH:mm:ss');
    }
  }

  @Emit('change')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitChange(dateTime: string): void {}
}
