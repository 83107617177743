export interface ValidationField {
  valid: boolean;
  warning: boolean;
  value(): any;
  validator(value: any): boolean;
  setWarning(value: any): boolean;
}

// validatForm
// Takes the inputted ValidationFields, runs the validators and warnings on each field,
// and modifies the ValidationFields to reflect the validity of the field.
// Returns true if all fields are valid
// Returns false if any of the fields are invalid
export function validateForm(fields: { [key: string]: ValidationField }, keys?: string[]): boolean {
  let formValid = true;
  const inputKeys = keys != null ? keys : Object.keys(fields);

  for (const key of inputKeys) {
    fields[key].valid = fields[key].validator(fields[key].value());
    fields[key].warning = fields[key].setWarning(fields[key].value());
    formValid = formValid && fields[key].valid;
  }
  return formValid;
}

// resetValidations
// Takes the inputted ValidationFields and sets the valid and warning properties to defaults
export function resetValidations(fields: { [key: string]: ValidationField }, keys?: string[]): void {
  const inputKeys = keys != null ? keys : Object.keys(fields);

  for (const key of inputKeys) {
    fields[key].valid = true;
    fields[key].warning = false;
  }
}
