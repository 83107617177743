
import { IDamperValuesViewModel } from '@/view-models/burner-view-model';
import { Component, Model, Vue, Watch } from 'vue-property-decorator';
import OnpointModal from '../common/OnpointModal.vue';
@Component({
  name: 'edit-damper-values',
  components: {
    OnpointModal
  }
})
export default class EditDamperValues extends Vue {
  @Model('change')
  public damperValues: IDamperValuesViewModel[];

  private showAddRange: boolean = false;
  private showAddSingle: boolean = false;
  private localDamperValues: IDamperValuesViewModel[] = [];

  private rangeStart: number = 0;
  private rangeEnd: number = 0;
  private rangeStep: number = 1;
  private singleValueKey: number = 0;
  private singleValueValue: number = 0;

  private created(): void {
    this.$validator.extend('keyIsUnique', {
      getMessage: () => 'Key value must be unique',
      validate: (value, keys) => this.keyIsUnique(value, keys as string[])
    });
  }

  private keyIsUnique(key: number, keys: string[]): boolean {
    return !keys.includes(key.toFixed(2));
  }

  private get keyValues(): string[] {
    return this.localDamperValues.map((setting) => setting.key);
  }

  private emitChanges(): void {
    this.$emit('change', this.localDamperValues);
    this.close();
  }

  private close(): void {
    this.showAddRange = false;
    this.showAddSingle = false;
    this.rangeStart = 0;
    this.rangeEnd = 0;
    this.rangeStep = 1;
    this.singleValueKey = 0;
    this.singleValueValue = 0;
    this.$nextTick(() => {
      this.$validator.reset();
    });
  }

  private async addRange(): Promise<void> {
    const isValid = await this.$validator.validate();
    if (!isValid) {
      return;
    }
    for (let i = this.rangeStart; i <= this.rangeEnd; i += this.rangeStep) {
      const keyValue = i.toFixed(2);
      if (!this.localDamperValues.some((v) => v.key === keyValue)) {
        this.localDamperValues.push({ key: String(i.toFixed(2)), value: '0' });
      }
    }
    this.localDamperValues.sort((a, b) => {
      return Number(a.key) - Number(b.key);
    });
    this.emitChanges();
  }

  private async addSingleValue(): Promise<void> {
    const isValid = await this.$validator.validate();
    if (!isValid) {
      return;
    }
    const keyValue = this.singleValueKey.toFixed(2);
    if (this.localDamperValues.some((v) => v.key === keyValue)) {
      return;
    }
    const newSetting: IDamperValuesViewModel = {
      key: keyValue,
      value: String(this.singleValueValue)
    };
    this.localDamperValues.push(newSetting);
    this.localDamperValues.sort((a, b) => {
      return Number(a.key) - Number(b.key);
    });
    this.emitChanges();
  }

  @Watch('damperValues', { deep: true, immediate: true })
  private onDamperValuesChange(newDamperValues: IDamperValuesViewModel[]): void {
    this.localDamperValues = newDamperValues == null ? [] : JSON.parse(JSON.stringify(newDamperValues));
  }
}
