
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import {IBurnerViewModel} from '../../view-models/burner-view-model';
import DeleteBurner from './DeleteBurner.vue';
import Dropdown from '../utility/Dropdown.vue';

Vue.use(BootstrapVue);

@Component({
  components: {Dropdown, DeleteBurner }
})
export default class BurnersPage extends Vue {
  private store = store;

  private addBurner(): void {
    this.$router.push('burner-creation');
    store.state.navState.prevNavKey = 'asset-details';
  }

  private edit(burnerToEdit: IBurnerViewModel): void {
    store.commit('assetState/updateSelectedBurner', burnerToEdit);
    this.$router.push('burner-edit');
    store.state.navState.prevNavKey = 'asset-details';
  }

  private details(burnerToEdit: IBurnerViewModel): void {
    store.commit('assetState/updateSelectedBurner', burnerToEdit);
    this.$router.push('burner-details');
    store.state.navState.prevNavKey = 'asset-details';
  }

  private deleteBurner(burnerToDelete: IBurnerViewModel): void {
    store.commit('assetState/updateSelectedBurner', burnerToDelete);
    this.$bvModal.show('deleteBurner');
  }
}
