
import {Component, Vue, Watch} from 'vue-property-decorator';
import {BootstrapVue} from 'bootstrap-vue';
import store from '../../store';
import moment from 'moment';
import CustomDateSelector from '../utility/CustomDateSelector.vue';
import {showError} from '@/utils/StoreHelper';
import { cloneDeep } from 'lodash';
import { IAsset } from '@/view-models/assets-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'agreements-page',
  components: {CustomDateSelector}
})
export default class AgreementsPage extends Vue {
  public acceptedAgreement: boolean = false;
  public agreementType: string = 'site-specific';
  public agreementDate: string = '';
  public displayedDate: string = '';
  public showPicker: boolean = false;
  public isDirty = false;
  public isLoading = false;
  public agreementTypeOptions = [
    { value: 'Site-Specific', text: 'Site-Specific' },
    { value: 'Click-Through', text: 'Click-Through' }
  ];

  private selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
  private formValid: boolean = true;
  private formMounted: boolean = false;
  
  public dateFormatter(value: string): string {
    const inputDate: moment.Moment = moment(value);
    let displayDate = '';

    if (inputDate.isValid()) {
      if (this.selectedAsset && this.selectedAsset.agreement) {
        this.selectedAsset.agreement.agreementDate = inputDate.format('MM/DD/YYYY');
      }
      displayDate = inputDate.format('MMMM DD, YYYY');
    } else {
      if (this.selectedAsset && this.selectedAsset.agreement) {
        this.selectedAsset.agreement.agreementDate = null;
      }
    }

    this.validateForm();
    return displayDate;
  }

  public agreementFormValid(): boolean {
    const agreement = this.selectedAsset ? this.selectedAsset.agreement : null;
    if (agreement) {
      if (!agreement.acceptedAgreement) {
        return true;
      } else {
        return agreement.agreementType != null
          && agreement.agreementDate != null
          && agreement.agreementDate.length > 0;
      }
    }
    return false;
  }

  public hidePicker(): void {
    this.showPicker = false;
  }

  @Watch('selectedAsset', {deep: true})
  private async markUnsavedChanges(): Promise<void> {
    this.isDirty = await store.dispatch('assetState/hasUnsavedChanges', this.selectedAsset);
  }

  private validateForm(): void {
    Vue.nextTick(() => {
      this.formValid = this.agreementFormValid();
      store.state.navState.assetTabEnabled = this.formValid;
    });
  }

  @Watch('selectedAsset.agreement.agreementDate')
  private onContext(): void {
    if (this.selectedAsset && this.selectedAsset.agreement && this.selectedAsset.agreement.agreementDate) {
      this.displayedDate = this.dateFormatter(this.selectedAsset.agreement.agreementDate);
    }
  }

  private async saveSelectedAsset(): Promise<void> {
    this.validateForm();
    if (this.formValid && this.formMounted) {
      try {
        store.commit('app/updateIsLoading', true);
        await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
      } catch (err) {
        showError(err.message);
      } finally {
        store.commit('app/updateIsLoading', false);
      }
    }
  }

  private revertSelectedAsset(): void {
    if (this.selectedAsset) {
      this.selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
      store.commit('assetState/updateSelectedAssetSaved', '');
      this.isDirty = false;
    }
  }  

  private mounted(): void {
    const initialDate = this.selectedAsset
      && this.selectedAsset.agreement
      && this.selectedAsset.agreement.agreementDate
      ? this.selectedAsset.agreement.agreementDate : '';
    this.displayedDate = this.dateFormatter(initialDate);

    this.validateForm();

    // Needed to prevent save asset call on component load
    setTimeout(() => {
      this.formMounted = true;
    }, 0);
  }
}
