
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import AddKey from './AddKey.vue';
import RemoveKey from './RemoveKey.vue';
import ClearKeys from './ClearKeys.vue';
import { ISolverCustomFieldViewModel } from '../../view-models/flow-network-view-model';
import { NetworkFlowBooleanEnums, NetworkFlowValueTypeEnums} from '../../enums/networkFlowEnums';
import {showError} from '@/utils/StoreHelper';
import { ValidationField, validateForm } from '@/utils/Validator';
import { cloneDeep } from 'lodash';
import { IAsset } from '@/view-models/assets-view-models';

Vue.use(BootstrapVue);

@Component({
  components: { AddKey, RemoveKey, ClearKeys }
})
export default class FlowNetwork extends Vue {
  public validations: {[key: string]: ValidationField } = this.getValidations();
  private isDirty = false;
  private selectedAsset: IAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
  private todelete: ISolverCustomFieldViewModel = {
    key: '',
    type: '',
    value: '0'
  };
  private ftolSolverOption: ISolverCustomFieldViewModel = {
    key: 'ftol',
    type: 'numeric',
    value: '0.001'
  };
  private solverNames = [
    { value: '', text: 'None' },
    { value: 'L-BFGS-B', text: 'L-BFGS-B' },
    { value: 'Nelder-Mead', text: 'Nelder-Mead' },
    { value: 'TNC', text: 'TNC' },
    { value: 'Powell', text: 'Powell' },
    { value: 'CG', text: 'CG' },
    { value: 'BFGS', text: 'BFGS' }
  ];

  get isFlowNetworkOptional(): boolean {
    if (this.selectedAsset &&
        this.selectedAsset.flowNetwork &&
        this.selectedAsset.flowNetwork.solverName === '' &&
        this.selectedAsset.flowNetwork.config === '') {
      return true;
    }
    return false;
  }

  private validateForm = validateForm;

  public beforeMount(): void {
    if (this.selectedAsset && !this.selectedAsset.flowNetwork) {
      this.selectedAsset.flowNetwork = {
        config: '',
        solverName: '',
        options: []
      };
    }
  }

  public mounted(): void {
    this.validateForm(this.validations);
  }

  public getValidations(): {[key: string]: ValidationField } {
    return {
      config: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset?.flowNetwork?.config,
        validator: (value: string) => (this.selectedAsset?.flowNetwork?.solverName === '') ||
                                      (this.selectedAsset?.flowNetwork?.solverName !== '' && value !== ''),
        setWarning: () => false
      },
      configJson: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset?.flowNetwork?.config,
        validator: (value: string) => {
          if (value !== '') {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
          }
          return true;
        },
        setWarning: () => false
      },
      solverName: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset?.flowNetwork?.solverName,
        validator: (value: string) => (this.selectedAsset?.flowNetwork?.config === '') ||
                                      (this.selectedAsset?.flowNetwork?.config !== '' && value !== ''),
        setWarning: () => false
      }
    };
  }

  @Watch('selectedAsset', {deep: true})
  private async markUnsavedChanges(): Promise<void> {
    const updatedFlowNetwork = this.selectedAsset.flowNetwork;
    const currentFlowNetwork = store.state.assetState.selectedAsset?.flowNetwork;
    const isNewFlowNetwork = updatedFlowNetwork?.config === '' &&
                                updatedFlowNetwork?.solverName === '' &&
                                updatedFlowNetwork?.options?.length === 0 &&
                                currentFlowNetwork == null;
    if (isNewFlowNetwork) {
      return;
    }
    this.isDirty = await store.dispatch('assetState/hasUnsavedChanges', this.selectedAsset);
  }

  private toggleValueField(field: ISolverCustomFieldViewModel): void {
    field.type = field.type === NetworkFlowValueTypeEnums.Numeric ?
        NetworkFlowValueTypeEnums.Boolean : NetworkFlowValueTypeEnums.Numeric;

    if (field.type ===  NetworkFlowValueTypeEnums.Boolean) {
          field.value = NetworkFlowBooleanEnums.False;
    }
  }

  private updateKeys(selectedAsset: IAsset): void {
    this.selectedAsset = cloneDeep(selectedAsset);
  }

  private revertSelectedAsset(): void {
    if (this.selectedAsset) {
      this.selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
      store.commit('assetState/updateSelectedAssetSaved', '');
      this.isDirty = false;
      this.validations = this.getValidations();
    }
  }

  private toggleBooleanField(field: ISolverCustomFieldViewModel, value: string): void {
    field.value =
      value === NetworkFlowBooleanEnums.False ? NetworkFlowBooleanEnums.False : NetworkFlowBooleanEnums.True;
    this.saveSelectedAsset();
  }

  private validateOption(key: ISolverCustomFieldViewModel): string {
    if (key.key && typeof key.value !== 'string') {
      return 'warning-red';
    }
    if (key.value === '0') {
      return 'warning-yellow';
    }
    return '';
  }

  private validateAllOptions(): boolean {
    let valid = true;
    if (this.selectedAsset && this.selectedAsset.flowNetwork && this.selectedAsset.flowNetwork.options) {
      this.selectedAsset.flowNetwork.options.forEach((x) => {
        if (this.validateOption(x) === 'warning-red') {
          valid = false;
        }
      });
    }
    return valid;
  }

  private clearConfig(): void {
    if (this.selectedAsset && this.selectedAsset.flowNetwork) {
      this.selectedAsset.flowNetwork.config = '';
    }
  }

  private copyToClipboard(): void {
    const input = this.$refs.text as HTMLInputElement;
    input.select();
    document.execCommand('copy');
  }

  private confirmDelete(key: ISolverCustomFieldViewModel): void {
    this.todelete = key;
    this.$bvModal.show('removeOption');
  }

  private solverNameChange(): void {
    if (this.selectedAsset && this.selectedAsset.flowNetwork) {
      if (this.selectedAsset.flowNetwork.solverName !== '') {
        if (this.selectedAsset.flowNetwork.options && this.selectedAsset.flowNetwork.options.length === 0) {
          this.selectedAsset.flowNetwork.options.push(this.ftolSolverOption);
        }
      } else {
        this.$bvModal.show('clearOptions');
      }
    }
  }

  private async saveSelectedAsset(): Promise<void> {
    if (this.validateForm(this.validations) && this.validateAllOptions()) {
      try {
        store.commit('app/updateIsLoading', true);
        await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
        store.state.navState.assetTabEnabled = true;
        return;
      } catch (err) {
        showError(err.message);
      } finally {
        store.commit('app/updateIsLoading', false);
      }
    }

    store.state.navState.assetTabEnabled = false;
  }
}
