export const portSizes = [
  '80',
  '79',
  '78',
  '1/64',
  '77',
  '76',
  '75',
  '74',
  '73',
  '72',
  '71',
  '70',
  '69',
  '68',
  '1/32',
  '67',
  '66',
  '65',
  '64',
  '63',
  '62',
  '61',
  '60',
  '59',
  '58',
  '57',
  '56',
  '3/64',
  '55',
  '54',
  '53',
  '1/16',
  '52',
  '51',
  '50',
  '49',
  '48',
  '5/64',
  '47',
  '46',
  '45',
  '44',
  '43',
  '42',
  '3/32',
  '41',
  '40',
  '39',
  '38',
  '37',
  '36',
  '7/64',
  '35',
  '34',
  '33',
  '32',
  '31',
  '1/8',
  '30',
  '29',
  '28',
  '9/64',
  '27',
  '26',
  '25',
  '24',
  '23',
  '5/32',
  '22',
  '21',
  '20',
  '19',
  '18',
  '11/64',
  '17',
  '16',
  '15',
  '14',
  '13',
  '3/16',
  '12',
  '11',
  '10',
  '9',
  '8',
  '7',
  '13/64',
  '6',
  '5',
  '4',
  '3',
  '7/32',
  '2',
  '1',
  'A',
  '15/64',
  'B',
  'C',
  'D',
  '1/4',
  'E',
  'F',
  'G',
  '17/64',
  'H',
  'I',
  'J',
  'K',
  '9/32',
  'L',
  'M',
  '19/64',
  'N',
  '5/16',
  'O',
  'P',
  '21/64',
  'Q',
  'R',
  '11/32',
  'S',
  'T',
  '23/64',
  'U',
  '3/8',
  'V',
  'W',
  '25/64',
  'X',
  'Y',
  '13/32',
  'Z',
  '27/64',
  '7/16',
  '29/64',
  '15/32',
  '31/64',
  '1/2',
  '33/64',
  '17/32',
  '35/64',
  '9/16',
  '37/64',
  '19/32',
  '39/64',
  '5/8',
  '41/64',
  '21/32',
  '43/64',
  '11/16',
  '45/64',
  '23/32',
  '47/64',
  '3/4',
  '49/64',
  '25/32',
  '51/64',
  '13/16',
  '53/64',
  '27/32',
  '55/64',
  '7/8'
];

export const portSizesMeters = [
  0.0003429,
  0.0003683,
  0.0004064,
  0.000396875,
  0.0004572,
  0.000508,
  0.0005334,
  0.0005715,
  0.0006096,
  0.000635,
  0.0006604,
  0.0007112,
  0.00074168,
  0.0007874,
  0.00079375,
  0.0008128,
  0.0008382,
  0.000889,
  0.0009144,
  0.0009398,
  0.0009652,
  0.0009906,
  0.001016,
  0.0010414,
  0.0010668,
  0.0010922,
  0.0011811,
  0.001190625,
  0.0013208,
  0.001397,
  0.0015113,
  0.0015875,
  0.0016129,
  0.0017018,
  0.001778,
  0.0018542,
  0.0019304,
  0.001984375,
  0.0019939,
  0.0020574,
  0.0020828,
  0.0021844,
  0.0022606,
  0.0023749,
  0.00238125,
  0.0024384,
  0.0024892,
  0.0025273,
  0.0025781,
  0.0026416,
  0.0027051,
  0.002778125,
  0.002794,
  0.0028194,
  0.0028702,
  0.0029464,
  0.003048,
  0.003175,
  0.0032639,
  0.0034544,
  0.0035687,
  0.003571875,
  0.0036576,
  0.0037338,
  0.0037973,
  0.0038608,
  0.0039116,
  0.00396875,
  0.0039878,
  0.0040386,
  0.0040894,
  0.0042164,
  0.0043053,
  0.004365625,
  0.0043942,
  0.0044958,
  0.004572,
  0.0046228,
  0.004699,
  0.0047625,
  0.0048006,
  0.0048514,
  0.0049022,
  0.0049784,
  0.0050546,
  0.0051054,
  0.005159375,
  0.0051816,
  0.0052197,
  0.0053086,
  0.0054102,
  0.00555625,
  0.0056134,
  0.0057912,
  0.0059436,
  0.005953125,
  0.0060452,
  0.0061468,
  0.0062484,
  0.00635,
  0.00635,
  0.0065278,
  0.0066294,
  0.006746875,
  0.0067564,
  0.0069088,
  0.0070358,
  0.0071374,
  0.00714375,
  0.007366,
  0.007493,
  0.007540625,
  0.0076708,
  0.0079375,
  0.0080264,
  0.0082042,
  0.008334375,
  0.0084328,
  0.0086106,
  0.00873125,
  0.0088392,
  0.0090932,
  0.009128125,
  0.0093472,
  0.009525,
  0.0095758,
  0.0098044,
  0.009921875,
  0.0100838,
  0.0102616,
  0.01031875,
  0.0104902,
  0.010715625,
  0.0111125,
  0.011509375,
  0.01190625,
  0.012303125,
  0.0127,
  0.013096875,
  0.01349375,
  0.013890625,
  0.0142875,
  0.014684375,
  0.01508125,
  0.015478125,
  0.015875,
  0.016271875,
  0.01666875,
  0.017065625,
  0.0174625,
  0.017859375,
  0.01825625,
  0.018653125,
  0.01905,
  0.019446875,
  0.01984375,
  0.020240625,
  0.0206375,
  0.021034375,
  0.02143125,
  0.021828125,
  0.022225
];

export const portSizesInches = [
  0.013500,
  0.014500,
  0.016000,
  0.015625,
  0.018000,
  0.020000,
  0.021000,
  0.022500,
  0.024000,
  0.025000,
  0.026000,
  0.028000,
  0.029200,
  0.031000,
  0.031250,
  0.032000,
  0.033000,
  0.035000,
  0.036000,
  0.037000,
  0.038000,
  0.039000,
  0.040000,
  0.041000,
  0.042000,
  0.043000,
  0.046500,
  0.046875,
  0.052000,
  0.055000,
  0.059500,
  0.062500,
  0.063500,
  0.067000,
  0.070000,
  0.073000,
  0.076000,
  0.078125,
  0.078500,
  0.081000,
  0.082000,
  0.086000,
  0.089000,
  0.093500,
  0.093750,
  0.096000,
  0.098000,
  0.099500,
  0.101500,
  0.104000,
  0.106500,
  0.109375,
  0.110000,
  0.111000,
  0.113000,
  0.116000,
  0.120000,
  0.125000,
  0.128500,
  0.136000,
  0.140500,
  0.140625,
  0.144000,
  0.147000,
  0.149500,
  0.152000,
  0.154000,
  0.156250,
  0.157000,
  0.159000,
  0.161000,
  0.166000,
  0.169500,
  0.171875,
  0.173000,
  0.177000,
  0.180000,
  0.182000,
  0.185000,
  0.187500,
  0.189000,
  0.191000,
  0.193000,
  0.196000,
  0.199000,
  0.201000,
  0.203125,
  0.204000,
  0.205500,
  0.209000,
  0.213000,
  0.218750,
  0.221000,
  0.228000,
  0.234000,
  0.234375,
  0.238000,
  0.242000,
  0.246000,
  0.250000,
  0.250000,
  0.257000,
  0.261000,
  0.265625,
  0.266000,
  0.272000,
  0.277000,
  0.281000,
  0.281250,
  0.290000,
  0.295000,
  0.296875,
  0.302000,
  0.312500,
  0.316000,
  0.323000,
  0.328125,
  0.332000,
  0.339000,
  0.343750,
  0.348000,
  0.358000,
  0.359375,
  0.368000,
  0.375000,
  0.377000,
  0.386000,
  0.390625,
  0.397000,
  0.404000,
  0.406250,
  0.413000,
  0.421875,
  0.437500,
  0.453125,
  0.468750,
  0.484375,
  0.500000,
  0.515625,
  0.531250,
  0.546875,
  0.562500,
  0.578125,
  0.593750,
  0.609375,
  0.625000,
  0.640625,
  0.656250,
  0.671875,
  0.687500,
  0.703125,
  0.718750,
  0.734375,
  0.750000,
  0.765625,
  0.781250,
  0.796875,
  0.812500,
  0.828125,
  0.843750,
  0.859375,
  0.875000
];
