
import VueI18n from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnpointModal from '@/components/common/OnpointModal.vue';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'rename-entity-modal',
  components: {
    OnpointModal
  }
})
export default class RenameEntityModal extends Vue {
  @Prop({ required: true })
  private name: string;

  private newName: string = null;

  private created(): void {
    this.newName = this.name;
  }

  private validateAndRename(): void {
    this.$validator.validate().then((isValid: boolean) => {
      if (isValid) {
        this.rename();
      }
    });
  }

  private rename(): void {
    this.$emit('rename', this.newName);
    this.closeModal();
  }

  private closeModal(): void {
    this.$emit('close');
  }

  private errorByRule(errors, field: string, regexRule?: string): VueI18n.TranslateResult {
    return HelperMethods.errorByRule(errors, field, this.$i18n, 'Asset', regexRule);
  }
}
