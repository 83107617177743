
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store from '../../store';
import { wholeNumberFormatter } from '@/utils/Formatter';
import {showError} from '@/utils/StoreHelper';
import { ValidationField, validateForm } from '@/utils/Validator';
import { IAsset } from '@/view-models/assets-view-models';
import { cloneDeep } from 'lodash';

Vue.use(BootstrapVue);

@Component({
  name: 'location-page'
})
export default class LocationPage extends Vue {
  private selectedAsset: IAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
  private showProgress: boolean = false;
  private wholeNumberFormatter = wholeNumberFormatter;
  private validateForm = validateForm;
  private validations: {[key: string]: ValidationField} = {};
  private isDirty = false;
  private isValid = false;
  private readonly validValueMessage: string = 'Please enter a valid value for all fields before saving.';
  private readonly validLongitudeMessage: string = 'Invalid value for longitude: -180 <= Longitude <= 180.';
  private readonly validLattitudeMessage: string = 'Invalid value for latitude: -85 <= Latitude <= 85.';

  get isLatLongRequired(): boolean {
    if (this.selectedAsset.location &&
       (!!this.selectedAsset.location.latitude ||
        !!this.selectedAsset.location.longitude)) {
      return true;
    }
    return false;
  }

  public beforeMount(): void {
    if (this.selectedAsset && !this.selectedAsset.location) {
      this.selectedAsset.location = {
        longitude: '',
        latitude: '',
        elevation: 0,
        elevationUnit: 'Meters'
      };
    }

    this.validations = {
      latitude: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset.location?.latitude,
        validator: (value: string) => {
          return (!this.isLatLongRequired || !!value) && this.latitudeIsValid(+value);
        },
        setWarning: () => false
      },
      longitude: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset.location?.longitude,
        validator: (value: string) => {
          return (!this.isLatLongRequired || !!value) && this.longitudeIsValid(+value);
        },
        setWarning: () => false
      },
      elevation: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset.location?.elevation,
        validator: (value: number) => !!value || value === 0,
        setWarning: () => false
      },
      elevationUnit: {
        valid: true,
        warning: false,
        value: () => this.selectedAsset.location?.elevationUnit,
        validator: (value: string) => !!value,
        setWarning: () => false
      }
    };    
  }

  public mounted(): void {
    this.validateForm(this.validations);    
  }

  @Watch('selectedAsset', {deep: true})
  private async markUnsavedChanges(): Promise<void> {
    this.isDirty = await store.dispatch('assetState/hasUnsavedChanges', this.selectedAsset);
    this.validateForm(this.validations);
    this.isValid = this.validations.latitude.valid && 
                   this.validations.longitude.valid && 
                   this.validations.elevationUnit.valid;
  }

  private saveElevationUnit(unit: string): void {
    if (this.selectedAsset && this.selectedAsset.location) {
      this.selectedAsset.location.elevationUnit = unit;
    }
  }

  private async saveSelectedAsset(): Promise<void> {
    if (!this.selectedAsset || !this.selectedAsset.location || !this.validateForm(this.validations)) {
      showError(this.validValueMessage);
      return;
    }

    if (!this.latitudeIsValid(+this.selectedAsset.location.latitude)) {
      showError(this.validLattitudeMessage);
      return;
    }
    if (!this.longitudeIsValid(+this.selectedAsset.location.longitude)) {
      showError(this.validLongitudeMessage);
      return;
    }

    this.showProgress = true;
    try {
      store.commit('app/updateIsLoading', true);
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
    } catch (err) {
      showError(err.message);
    } finally {
      store.commit('app/updateIsLoading', false);
    }
    this.showProgress = false;
  }

  private latitudeIsValid(latitude: number): boolean {
    return +latitude <= 85 && +latitude >= -85;
  }

  private longitudeIsValid(longitude: number): boolean {
    return +longitude <= 180 && +longitude >= -180;
  }

  private revertSelectedAsset(): void {
    if (this.selectedAsset) {
      this.selectedAsset = cloneDeep(store.getters['assetState/getSelectedAsset'] as IAsset);
      store.commit('assetState/updateSelectedAssetSaved', '');
      this.isDirty = false;
    }
  }
}
