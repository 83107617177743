
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component
export default class AddKey extends Vue {
  private selectedAsset = store.state.assetState.selectedAsset;
  private selectedBurner = store.state.assetState.selectedBurner;
  private key = '';
  private value = '';

  private valid = {
    key: true,
    value: true
  };

  private async addCustomField(): Promise<void> {
    if (this.selectedBurner) {
      if (this.validForm() && this.selectedBurner.burnerDetail.config.customFields) {
        this.selectedBurner.burnerDetail.config.customFields.push({key: this.key, value: this.value});
        try {
          await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
        } catch (err) {
          showError(err.message);
        }
        this.key = '';
        this.value = '';
        this.$bvModal.hide('addKey');
      } else {
        store.commit('errorState/setError', {
          error: 'Please enter a value for all fields.',
          config: {
            sendLog: false
          },
          uiError: true
        });
      }
    }
  }

  private validForm(): boolean {
    this.valid = {
      key: this.key !== '',
      value: this.value !== ''
    };
    return this.valid.key && this.valid.value;
  }
}
