
import {Vue, Component, Prop} from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component
export default class AddDrillingComponent extends Vue {
  @Prop({ required: true })
  private text: string;
}
