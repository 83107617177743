
import { BvTableFieldArray } from 'bootstrap-vue';
import { v4 as uuid } from 'uuid';
import { Component, Vue, Watch } from 'vue-property-decorator';
import AssetProperty from '@/components/asset-services/asset-edit/asset-property.vue';
import { isHidden } from '@/components/asset-services/business-logic/asset-details-logic';
import AssetHelperMethods from '@/components/asset-services/business-logic/asset-helper-methods';
import {
  AssetInstanceProperty,
  AssetPropertyDisplayModel,
  IAssetInstanceFull
} from '@/components/asset-services/models/asset-interfaces';
import {
  BlueprintPropertySourceType,
  BlueprintPropertyTypeGroup
} from '@/components/asset-services/models/blueprint-enums';
import {
  IBlueprint,
  IBlueprintProperty,
  IBlueprintPropertyTypes
} from '@/components/asset-services/models/blueprint-interfaces';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { getBlueprintProperties } from '@/utils/blueprints-helper';

@Component({
  name: 'asset-details-table',
  components: {
    AssetProperty
  }
})
export default class AssetDetailsTable extends Vue {
  private assetProperties: AssetPropertyDisplayModel[] = [];

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get blueprint(): IBlueprint {
    return this.blueprintStore.allAssetBlueprints.find(
      (blueprint) => blueprint.id === this.assetStore.selectedNode.blueprintId
    );
  }

  private get asset(): IAssetInstanceFull {
    return this.assetStore.selectedNode.element as IAssetInstanceFull;
  }

  private get blueprintProperties(): IBlueprintProperty[] {
    return getBlueprintProperties(this.blueprint);
  }

  private get assetPropertyFields(): BvTableFieldArray {
    return [
      {
        label: this.$t('assets.fields.propertyName').toString(),
        key: 'name',
        sortable: true
      },
      { label: this.$t('assets.fields.type').toString(), key: 'type' },
      {
        label: this.$t('assets.fields.propertySource').toString(),
        key: 'propertySource'
      },
      { label: this.$t('assets.fields.value').toString(), key: 'value' }
    ];
  }

  private mounted(): void {
    this.initializeDisplayProperties();
  }

  private getDisplayType(type: IBlueprintPropertyTypes, typeGroup: BlueprintPropertyTypeGroup): string {
    if (typeGroup === BlueprintPropertyTypeGroup.Basic) {
      return type.basicType;
    } else {
      const id = type.blueprintType.blueprintIdentifier;
      const blueprint = this.blueprintStore.allAssetBlueprints.find((b) => b.id === id);
      return blueprint?.title ?? blueprint?.id;
    }
  }

  private getPropertyValue(prop: AssetPropertyDisplayModel): any {
    return AssetHelperMethods.getPropertyValue(prop);
  }

  private getBlueprintProperty(propertyName: string): IBlueprintProperty {
    return this.blueprintProperties.find((p) => p.name === propertyName);
  }

  private initializeDisplayProperties(): void {
    this.assetProperties = this.blueprintProperties
      .filter(
        (blueprintProp) =>
          blueprintProp.propertyTypeGroup === BlueprintPropertyTypeGroup.Basic &&
          this.asset[blueprintProp.name] &&
          !isHidden(this.blueprint.id, blueprintProp.name) &&
          !this.isReadonlyWithNoValue(blueprintProp)
      )
      .map((blueprintProp: IBlueprintProperty) => {
        const assetProp = this.asset[blueprintProp.name] as AssetInstanceProperty;
        const type = AssetHelperMethods.getPropertyType(blueprintProp, assetProp);
        const propertySource = AssetHelperMethods.getPropertySource(blueprintProp, assetProp);
        return new AssetPropertyDisplayModel({
          name: blueprintProp.name,
          id: uuid(),
          isList: blueprintProp.isList,
          isOptional: blueprintProp.isOptional,
          types: blueprintProp.propertyTypes,
          propertySourceOptions: blueprintProp.propertySources,
          validValues: blueprintProp.validValues,
          isReadonly: blueprintProp.isReadOnly,
          propertySource,
          value: blueprintProp.isList ? assetProp : assetProp.value,
          formula: assetProp.formula,
          tagName: assetProp.tagName,
          blueprintIdentifier: type.blueprintType?.type,
          propertyTypeGroup: blueprintProp.propertyTypeGroup,
          type
        } as AssetPropertyDisplayModel);
      });
  }

  private isReadonlyWithNoValue(blueprintProp: IBlueprintProperty): boolean {
    const assetProp = this.asset[blueprintProp.name] as AssetInstanceProperty;
    return (
      blueprintProp.isReadOnly &&
      !(blueprintProp.isList ? assetProp : assetProp.value || assetProp.formula || assetProp.tagName)
    );
  }

  private setAssetPropertyType(prop: AssetPropertyDisplayModel, newType: IBlueprintPropertyTypes): void {
    const index = this.assetProperties.findIndex((p) => p.id === prop.id);
    const updatedProp = this.assetProperties[index];
    if (updatedProp.type !== newType) {
      updatedProp.type = newType;
      if (prop.propertyTypeGroup === BlueprintPropertyTypeGroup.Blueprint) {
        updatedProp.blueprintIdentifier = updatedProp.type.blueprintType.blueprintIdentifier;
      }
      this.assetProperties.splice(index, 1, updatedProp);
      if (!prop.isList) {
        (this.asset[updatedProp.name] as AssetInstanceProperty).propertyType = newType;
      } else {
        Vue.set(this.asset, updatedProp.name, []);
        updatedProp.value = [];
      }
    }
  }

  private setAssetPropertySource(prop: AssetPropertyDisplayModel, newPropSource: BlueprintPropertySourceType): void {
    const index = this.assetProperties.findIndex((p) => p.id === prop.id);
    const updatedProp = this.assetProperties[index];
    if (updatedProp.propertySource !== newPropSource) {
      updatedProp.propertySource = newPropSource;
      this.assetProperties.splice(index, 1, updatedProp);
      if (!prop.isList) {
        (this.asset[updatedProp.name] as AssetInstanceProperty).propertySource = newPropSource;
        this.setAssetPropertyValue(updatedProp, null);
      } else {
        (this.asset[updatedProp.name] as AssetInstanceProperty[]).forEach((listEntry) => {
          listEntry.propertySource = newPropSource;
          this.setAssetPropertyValue(updatedProp, null);
        });
      }
    }
  }

  private setAssetPropertyValue(prop: AssetPropertyDisplayModel, newValue: any): void {
    const index = this.assetProperties.findIndex((p) => p.id === prop.id);
    const updatedProp = this.assetProperties[index];
    switch (prop.propertySource) {
      case BlueprintPropertySourceType.Formula:
        AssetHelperMethods.handleFormulaChange(this.asset, updatedProp, newValue);
        break;
      case BlueprintPropertySourceType.CustomerGenerated:
      case BlueprintPropertySourceType.OnPointGenerated:
        AssetHelperMethods.handleTagNameChange(this.asset, updatedProp, newValue);
        break;
      default:
        AssetHelperMethods.handleStaticChange(this.asset, updatedProp, newValue);
        break;
    }
    this.assetProperties.splice(index, 1, updatedProp);
  }

  @Watch('assetStore.selectedNode', { immediate: true })
  private onSelectedNodeChange(): void {
    if (!HelperMethods.isNullOrUndefined(this.asset) && !HelperMethods.isNullOrUndefined(this.blueprint)) {
      this.initializeDisplayProperties();
    }
  }
}
