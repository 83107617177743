
import {Component, Vue, Prop} from 'vue-property-decorator';
import { ControlTypes } from '@/enums/subGroupTypes';

@Component({
  name: 'preview'
})
export default class Preview extends Vue {
  @Prop({required: true})
  private displayOrder: [];
  private controlTypes = ControlTypes;

  public getCurrentFuelTip(name: string): string {
    const currentFuelTipName: string[] = name.split('/');
    return currentFuelTipName[1] ? currentFuelTipName[1]: 'on';
  }
}
