
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IAsset, IIndexable } from '../../view-models/assets-view-models';
import SearchBox from '../search/SearchBox.vue';
import { v4 as uuid } from 'uuid';
import Loading from '../common/Loading.vue';
import { showError } from '@/utils/StoreHelper';
import { EntityType } from '@/view-models/agreement-view-model';

Vue.use(BootstrapVue);

@Component({
  components: { SearchBox, Loading }
})
export default class CreateAssetPage extends Vue {
  private service = store.state.assetState.service;
  private key = '';
  private copyKey = false;
  private isSaving = false;
  private selectedCustomer = store.state.assetState.availableAssignments
    .find((x) => x.type === EntityType.Customer);
  private sites: string[] = store.state.assetState.availableAssignments
    .filter((x) => x.type === EntityType.CustomerSite)
    .map((x) => x.name);
  private assetToCreate: IAsset & IIndexable = {
    key: uuid(),
    equipmentConfigKey: uuid(),
    lastUpdatedDate: this.formattedTime(),
    lastUpdatedBy: store.state.assetState.user ? store.state.assetState.user.name : '',
    customerName: this.selectedCustomer ? this.selectedCustomer.name : '',
    customerKey: this.selectedCustomer ? this.selectedCustomer.key : '',
    site: '',
    siteKey: '',
    equipmentName: '',
    emberName: '',
    publicKey: '',
    loggingLevel: 30,
    equipmentType: 'Fired Heater',
    equipmentSubtype: 'Ethylene',
    allowedToLog: true,
    requireSignature: true,
    staleRecommendationThreshold: 30,
    location: {
      elevation: 0,
      latitude: '',
      longitude: '',
      elevationUnit: 'Meters'
    },
    agreement: {
      agreementType: 'Site-Specific',
      acceptedAgreement: false
    },
    flowNetwork: {
      solverName: '',
      config: '',
      options: []
    },
    burnerList: []
  };

  private created(): void {
    this.$validator.extend('uniqueAssetName', {
      getMessage: (field) => `${field} must be unique for this customer and site.`,
      validate: () => !this.assetNameExists()
    });
    this.$validator.extend('noInvalidCharacters', {
      getMessage: (field) => `${field} cannot use the following special characters: {}|\\^~\[\]\`&<>\/;?:@=+$,%`,
      validate: () => this.assetNameIsValid()
    });
  }

  private mounted(): void {
    this.clearAssetToCreate();
    this.assetToCreate.customerName = this.selectedCustomer ? this.selectedCustomer.name : '';
    this.assetToCreate.customerKey = this.selectedCustomer ? this.selectedCustomer.key : '';
    this.assetToCreate.site = '';
  }

  @Watch('assetToCreate.site', { immediate: true, deep: true })
  private onSiteChanged(): void {
    if (this.selectedCustomer) {
      const site = store.state.assetState.availableAssignments.find(
        (x) => x.type === EntityType.CustomerSite && x.name === this.assetToCreate.site
      );
      if (site) {
        this.assetToCreate.siteKey = site.key;
        this.assetToCreate.site = site.name;
      }
    }
  }

  private cancel(): void {
    if (!this.isSaving) {
      this.$router.push(store.state.navState.prevNavKey);
      store.state.navState.prevNavKey = 'asset-creation';
    }
  }

  private assetNameExists(): boolean {
    return store.state.assetState.assets.some(
      (x) => x.equipmentName === this.assetToCreate.equipmentName && x.siteKey === this.assetToCreate.siteKey
    );
  }

  private assetNameIsValid(): boolean {
    var regex = /^[^{}|\\^~\[\]\`&<>\/;?:@=+$,%]*$/;
    return regex.test(this.assetToCreate.equipmentName);
  }

  private async createAsset(): Promise<void> {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    const fieldsValid = await this.$validator.validateAll();
    if (fieldsValid && this.service) {
      try {
        await store.dispatch('assetState/saveSelectedAsset', this.assetToCreate);
        store.commit('assetState/updateSelectedAsset', this.assetToCreate.key);
        this.clearAssetToCreate();
        await this.$router.push('asset-details');
      } catch (err) {
        showError(err);
      }
    }
    this.isSaving = false;
  }

  private clearAssetToCreate(): void {
    this.assetToCreate = {
      key: uuid(),
      equipmentConfigKey: uuid(),
      lastUpdatedDate: this.formattedTime(),
      lastUpdatedBy: store.state.assetState.user ? store.state.assetState.user.name : '',
      customerName: this.selectedCustomer ? this.selectedCustomer.name : '',
      customerKey: this.selectedCustomer ? this.selectedCustomer.key : '',
      site: '',
      siteKey: '',
      equipmentName: '',
      emberName: '',
      publicKey: '',
      loggingLevel: 30,
      equipmentType: 'Fired Heater',
      equipmentSubtype: 'Ethylene',
      allowedToLog: true,
      requireSignature: true,
      staleRecommendationThreshold: 30,
      location: {
        elevation: 0,
        latitude: '',
        longitude: '',
        elevationUnit: 'Meters'
      },
      flowNetwork: {
        solverName: '',
        config: '',
        options: []
      },
      agreement: {
        agreementType: 'Site-Specific',
        acceptedAgreement: false
      },
      burnerList: []
    };
    this.key = '';
  }

  private formattedTime(): string {
    const currentTime = new Date().toLocaleDateString();
    return (
      currentTime.slice(currentTime.lastIndexOf('/') + 1, currentTime.length) +
      '-' +
      currentTime.slice(0, currentTime.indexOf('/')) +
      '-' +
      currentTime.slice(currentTime.indexOf('/') + 1, currentTime.lastIndexOf('/'))
    );
  }

  private generateKey(): void {
    if (this.service) {
      this.service
        .generateEquipmentKeyPair()
        .then((response) => {
          this.assetToCreate.publicKey = response.publicKey;
          this.key = response.privateKey;
        })
        .catch(() => {
          store.commit('errorState/setError', { error: 'Generate Key Pair Failed: Could not call backend API.' });
        });
    }
  }

  private async copyKeyToClipboard(): Promise<void> {
    await navigator.clipboard.writeText(this.key);
    this.copyKey = true;
  }

  private clearKeys(): void {
    this.copyKey = false;
    this.key = '';
    this.assetToCreate.publicKey = '';
  }
}
