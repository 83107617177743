
import { Component, Emit } from 'vue-property-decorator';
import BlueprintProperty from '@/components/asset-services/blueprint-edit/blueprint-property.vue';
import { BlueprintEditStep } from '@/components/asset-services/models/blueprint-enums';
import { IBlueprintProperty } from '@/components/asset-services/models/blueprint-interfaces';
import NavigationStep from '@/components/common/navigation/navigation-step';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { getBlueprintProperties } from '@/utils/blueprints-helper';

@Component({
  name: 'blueprint-properties-step',
  components: {
    BlueprintProperty
  }
})
export default class BlueprintPropertiesStep extends NavigationStep {
  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get allProperties(): IBlueprintProperty[] {
    return getBlueprintProperties(this.blueprintStore.selectedBlueprint);
  }

  private mounted(): void {
    this.onMount(BlueprintEditStep.Properties);
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onMount(index: BlueprintEditStep): void {}
}
