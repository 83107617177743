import i18n from '@/i18n';

export function getDefaultCrumbs(): Array<{ text: string; href: string }> {
  return [
    {
      text: i18n.t('global.utilities', { action: '' }).toString(),
      href: `${getOrigin()}/utilities`
    }
  ];
}

function getOrigin(): string {
  if (!window.location.origin) {
    const port = window.location.port ? ':' + window.location.port : '';
    return `${window.location.protocol}//${window.location.hostname}${port}`;
  }
  return window.location.origin;
}
