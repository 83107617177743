
import { Vue, Component, Watch } from 'vue-property-decorator';
import store, { getAssetServiceStoreModule } from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IAssetRequiresUpdate, IAsset } from '@/view-models/assets-view-models';
import { Route } from 'vue-router';
import Loading from '../../components/common/Loading.vue';
import {showError} from '@/utils/StoreHelper';
import { ISubscriptionDetailViewModel } from '@/view-models/subscription-view-model';
import HelperMethods from '@/shared/helper-methods';
import AssetServiceModule from '@/store/asset-service-store/asset-service-store';

Vue.use(BootstrapVue);

@Component({
  components: {Loading}
})
export default class HomePage extends Vue {
  private store = store;
  private recent: IAsset[] = [];
  private updateAssets: IAssetRequiresUpdate[] = [];
  private assetsLoaded: boolean = false;
  private thStyle = {
    backgroundColor: '#2A496D',
    border: 0
  };
  private recentFields = [
    {
      key: 'equipmentName',
      label: 'Asset',
      thStyle: this.thStyle
    },
    {
      key: 'site',
      label: 'Site',
      thStyle: this.thStyle
    },
    {
      key: 'customerName',
      label: 'Customer',
      thStyle: this.thStyle
    },
    {
      key: 'lastUpdatedDate',
      label: 'Last Updated',
      thStyle: this.thStyle
    },
    {
      key: 'lastUpdatedBy',
      label: 'Last Updated By',
      thStyle: this.thStyle
    }
  ];
  private requireFields = [
    {
      key: 'asset',
      label: 'Asset',
      thStyle: this.thStyle
    },
    {
      key: 'site',
      label: 'Site',
      thStyle: this.thStyle
    },
    {
      key: 'customer',
      label: 'Customer',
      thStyle: this.thStyle
    },
    {
      key: 'subscription',
      label: 'Subscription',
      thStyle: this.thStyle
    },
    {
      key: 'expirationDate',
      label: 'Expiration Date',
      thStyle: this.thStyle
    }
  ];

  public async mounted(): Promise<void> {
    // Handle route changes from parent
    if (!HelperMethods.isNullOrUndefined((window as any).eftEventBus)) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    await this.refreshData();
  }

  public beforeDestroy(): void {
    if (!HelperMethods.isNullOrUndefined((window as any).eftEventBus)) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  public async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/asset-editor\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/asset-editor\//, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
      }
    }
  }

  get assetServiceStore(): AssetServiceModule {
    return getAssetServiceStoreModule(this.store);
  }

  private async refreshData(): Promise<void> {
    store.state.assetState.assets.length = 0;
    this.assetsLoaded = false;
    try {
      await store.dispatch('assetState/retrieveServiceConfig');
      await store.dispatch('assetState/retrieveAssets');
      this.recentAssets();
      this.assetsToUpdate();
      this.assetsLoaded = true;
    } catch (err) {
      showError(err.message);
    }
  }

  private async assetDetails(assetKey: string): Promise<void> {
    store.commit('assetState/updateSelectedAsset', assetKey);
    await this.$router.push('asset-details');
    store.state.navState.prevNavKey = '/';
  }

  private navigate(navKey: string): void {
    this.$router.push(navKey);
    store.state.navState.prevNavKey = '/';
  }

  @Watch('store.state.assetState.assets.length', { immediate: true, deep: true })
  private recentAssets(): void {
    let assets = store.state.assetState.assets.concat([]);
    assets = assets.sort((x, y) => new Date(y.lastUpdatedDate).getTime() - new Date(x.lastUpdatedDate).getTime());
    this.recent = assets.splice(0, 4);
  }

  /**
   * This function is to create the required data for the "Assets Requiring update table".
   * The expected data needs to have subscriptions for the same asset with the same expiration to be on the same line.
   * Subscriptions for the same asset with different expirations should be on different lines.
   */
  @Watch('store.state.assetState.assets.length', { immediate: true, deep: true })
  private assetsToUpdate(): void {
    let subscriptions: IAssetRequiresUpdate[] = [];
    // Loop through each asset
    store.state.assetState.assets.forEach((asset) => {
      let assetSubscriptions: IAssetRequiresUpdate[] = [];
      if (asset.subscription) {
        // Check date is 90 days before expiration and add to output list if it is.
        if (asset.subscription.fuelSide && this.subscriptionExpiresSoon(asset.subscription.fuelSide)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.fuelSide.expirationDate), 'Fuel Side', asset);
        }
        if (asset.subscription.airSide && this.subscriptionExpiresSoon(asset.subscription.airSide)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.airSide.expirationDate), 'Air Side', asset);
        }
        if (asset.subscription.flowNetwork && this.subscriptionExpiresSoon(asset.subscription.flowNetwork)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.flowNetwork.expirationDate), 'Flow Network', asset);
        }
        if (asset.subscription.flareLogger && this.subscriptionExpiresSoon(asset.subscription.flareLogger)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.flareLogger.expirationDate), 'Flare Logger', asset);
        }
        if (asset.subscription.solex && this.subscriptionExpiresSoon(asset.subscription.solex)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.solex.expirationDate), 'Solex', asset);
        }
        if (asset.subscription.analytics && this.subscriptionExpiresSoon(asset.subscription.analytics)) {
          assetSubscriptions = this.addSubscription(
                  assetSubscriptions, new Date(asset.subscription.analytics.expirationDate), 'Analytics', asset);
        }
      }
      subscriptions = subscriptions.concat(assetSubscriptions);
    });
    subscriptions.sort((x, y) => new Date(x.expirationDate).getTime() - new Date(y.expirationDate).getTime());
    this.updateAssets = subscriptions;
  }

  private subscriptionExpiresSoon(subscription: ISubscriptionDetailViewModel): boolean {
    const expirationTime = new Date(subscription.expirationDate).getTime();
    const now = new Date().getTime();
    const ninetyDaysInMilliseconds = 24 * 60 * 60 * 1000 * 90; // Milliseconds in 90 days

    return expirationTime - ninetyDaysInMilliseconds < now && subscription.expirationAlert;
  }

  private addSubscription(
    assetSubscriptions: IAssetRequiresUpdate[],
    date: Date,
    sub: string,
    asset: IAsset
  ): IAssetRequiresUpdate[] {
    let addSub = true;
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    // Add name of subscription to existing subscription table entry.
    assetSubscriptions.forEach((y) => {
      if (y.expirationDate === date.toLocaleString('en-US', dateOptions)) {
        y.subscription += ', ' + sub;
        addSub = false;
      }
    });
    if (addSub) {
      assetSubscriptions.push({
        asset: asset.equipmentName,
        site: asset.site,
        customer: asset.customerName,
        subscription: sub,
        publicKey: asset.publicKey,
        expirationDate: date.toLocaleString('en-US', dateOptions),
        key: asset.key
      } as IAssetRequiresUpdate);
    }
    return assetSubscriptions;
  }
}
