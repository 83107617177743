
import { BvTableFieldArray } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { IBlueprint } from '@/components/asset-services/models/blueprint-interfaces';
import IconButton from '@/components/common/dsm/icon-button.vue';
import Loading from '@/components/common/Loading.vue';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import SearchBar from '@/components/common/search-bar.vue';
import Dropdown from '@/components/utility/Dropdown.vue';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'blueprint-list-page',
  components: {
    Dropdown,
    IconButton,
    Loading,
    LoadingOverlay,
    OnpointModal,
    SearchBar
  }
})
export default class BlueprintListPage extends Vue {
  private blueprintFilter: string = '';
  private isLoading: boolean = false;
  private searchTerm: string = '';
  private perPageItems = 10;
  private currentPage = 1;

  private get blueprintFields(): BvTableFieldArray {
    return [
      {
        label: this.$t('blueprints.fields.name').toString(),
        key: 'name',
        sortable: true,
        thStyle: { width: '25%' }
      },
      {
        label: this.$t('blueprints.fields.description').toString(),
        key: 'description',
        sortable: true,
        thStyle: { width: '30%' }
      },
      {
        label: this.$t('blueprints.fields.status').toString(),
        key: 'state',
        sortable: true,
        thStyle: { width: '15%' }
      },
      {
        label: this.$t('blueprints.fields.properties').toString(),
        key: 'properties',
        sortable: true,
        formatter: (_value, _key, item) => item.structProperties.length,
        sortByFormatted: true,
        thStyle: { width: '15%' }
      },
      {
        label: this.$t('blueprints.fields.version').toString(),
        key: 'version',
        sortable: true,
        thStyle: { width: '15%' }
      }
    ];
  }

  private get tableLoading(): boolean {
    return this.blueprintStore.blueprintsLoading;
  }

  private get totalRows(): number {
    return this.blueprintsList.length;
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get blueprintsList(): IBlueprint[] {
    if (this.searchTerm) {
      return [
        ...this.blueprintStore.blueprints.filter(
          (item: IBlueprint) =>
            item.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            this.hasFilterTags(item, this.searchTerm)
        )
      ];
    } else {
      return this.blueprintStore.blueprints;
    }
  }

  private filterBlueprints(search: string = ''): void {
    this.currentPage = 1;
    this.searchTerm = search;
  }

  private hasFilterTags(value: IBlueprint, search: string): boolean {
    return value.searchTags.some((item) => item.toLowerCase().includes(search.toLowerCase()));
  }
}
