
import { Vue, Component, Prop } from 'vue-property-decorator';
import Loading from '@/components/common/Loading.vue';

@Component({
  name: 'loading-overlay',
  components: {
    Loading
  }
})
export default class LoadingOverlay extends Vue {
  @Prop({ default: false })
  private showLoader: boolean;
  @Prop()
  private text: boolean;
}
