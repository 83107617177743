
import { v4 as uuid } from 'uuid';
import { FieldError } from 'vee-validate';
import { Component, Vue } from 'vue-property-decorator';
import { IAssetBundleFailure } from '@/components/asset-services/models/asset-interfaces';
import store, { getAssetStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';

@Component({
  name: 'asset-error-preview',
  components: {}
})
export default class AssetErrorPreview extends Vue {
  private hide: boolean = false;

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private errors(): Array<{ msg: string; id: string }> {
    const bundleErrors = this.bundleErrors.map((error) => {
      const msg = error.reason.replace(error.resourceId, this.getElementNameFromId(error.resourceId));
      return { msg, id: uuid() };
    });
    const veeValidateErrors = this.veeValidateErrors.map((error) => ({ msg: error.msg, id: error.id }));
    return [...bundleErrors, ...veeValidateErrors];
  }

  private get veeValidateErrors(): FieldError[] {
    return this.$validator.errors.items;
  }

  private get bundleErrors(): IAssetBundleFailure[] {
    return this.assetStore.assetErrors;
  }

  private getElementNameFromId(id: string): string {
    const context = this.assetStore.assetContexts.find((a) => a.entityId === id);
    if (context) {
      return context.entity?.name.value;
    }
    return id;
  }
}
