import axios, { AxiosInstance } from 'axios';
import convict, { Config } from 'convict';

// Note: currently, local .env files will overwrite config.json
// (see https://cli.vuejs.org/guide/mode-and-env.html) for more details.  Possible
// to redesign config injection pattern in future sprint.  DBB 5/7/2020

export default class ConfigFactory {
  public static async GetConfig(): Promise<Config<unknown>> {
    if (this.config === undefined && this.instance !== undefined) {
      const d = await this.instance.get('config.json');
      this.config = convict(d.data);
    }
    return this.config;
  }

  private static config: Config<unknown>;

  private static readonly instance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_AUTH0_REDIRECT_URI
      ? process.env.VUE_APP_AUTH0_REDIRECT_URI
      : document.getElementById('customer-asset-editor-script')
      ? new URL((document.getElementById('customer-asset-editor-script') as HTMLScriptElement).src).origin
      : '/',
    timeout: 5000
  });
}
