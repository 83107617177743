import { ErrorBag } from 'vee-validate';
import VueI18n from 'vue-i18n';

export default class HelperMethods {
  public static isNullOrUndefined(object: any): boolean {
    return object == null || typeof object === 'undefined';
  }

  public static isStringEmpty(text: string): boolean {
    return this.isNullOrUndefined(text) || text.trim() === '';
  }

  public static equalsIgnoreCase(text1: string, text2: string): boolean {
    return text1.toLowerCase().replace(/\s+/g, ' ').trim() === text2.toLowerCase().replace(/\s+/g, ' ').trim();
  }

  public static isArrayEmpty(array: any[]): boolean {
    return this.isNullOrUndefined(array) || array.length === 0;
  }

  public static sortString(a: string, b: string, alphabetical: boolean = true): number {
    const above = alphabetical ? -1 : 1;
    const below = alphabetical ? 1 : -1;

    if (a == null) {
      return b == null ? 0 : above;
    }
    if (b == null) {
      return below;
    }

    const textA = a.toLowerCase();
    const textB = b.toLowerCase();

    if (textA < textB) {
      return above;
    }
    if (textA > textB) {
      return below;
    }
    return 0;
  }

  public static integerIsValid(value: any): boolean {
    if (HelperMethods.isNullOrUndefined(value)) {
      return false;
    }
    return /^-?\d+$/.test(value);
  }

  public static doubleIsValid(value: any): boolean {
    if (HelperMethods.isNullOrUndefined(value)) {
      return false;
    }
    return /^-?\d*\.?\d+$/.test(value);
  }

  /**
   * Rounds a string to a minimum number of decimal places and returns the result as a string
   * @param number Number as a string to be rounded
   * @param minPlaces Minimum number of decimal places to round to
   */
  public static roundDecimalString(numberString: string, minPlaces: number): string {
    const numberNum = Number(numberString);
    const decimalPlaces = Math.max(minPlaces, this.countDecimalPlaces(numberNum));
    return numberNum.toFixed(decimalPlaces);
  }

  /**
   * Get the number of decimal places a number has
   * @param num : number
   */
  public static countDecimalPlaces(num: number): number {
    if (Math.floor(num.valueOf()) === num.valueOf()) {
      return 0;
    }
    return num.toString().split('.')[1].length || 0;
  }

  public static async reducePromises(promises: Array<Promise<boolean>>): Promise<boolean> {
    const values = await Promise.all(promises);
    return !values.some((v) => !v);
  }

  public static errorByRule(
    errors: ErrorBag,
    field: string,
    translator: VueI18n,
    type: string,
    regexRule?: string
  ): VueI18n.TranslateResult {
    if (errors.firstByRule(field, 'alpha_dash')) {
      return translator.t(`blueprints.fieldAlphaNumericDashes`);
    } else if (errors.firstByRule(field, 'required')) {
      return translator.t(`blueprints.fieldRequired`);
    } else if (errors.firstByRule(field, 'propertyNameUnique') || errors.firstByRule(field, 'isDuplicateName')) {
      return translator.t(`blueprints.uniqueName`, { value: type });
    } else if (errors.firstByRule(field, 'regex') && regexRule === 'alphaNumericDashesSpacesPeriods') {
      return translator.t(`blueprints.fieldAlphaNumericDashesSpacesPeriods`);
    } else {
      return errors.first(field).replace(translator.tc(`global.the`) + ' ' + field, translator.tc(`global.this`));
    }
  }

  public static delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
}
