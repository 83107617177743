import {
  BlueprintConstraintType,
  BlueprintPropertySourceType,
  BlueprintPropertyType,
  BlueprintPropertyTypeGroup,
  BlueprintState
} from '@/components/asset-services/models/blueprint-enums';

export interface IBlueprint {
  id: string;
  state: BlueprintState;
  version: string;
  title: string;
  description: string;
  isSystem: boolean;
  searchTags: string[];
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  isRoot: boolean;
  structProperties: IBlueprintProperty[];
  selected: boolean;
}

export class Blueprint implements IBlueprint {
  public id: string = '';
  public state: BlueprintState = BlueprintState.Draft;
  public version: string = '';
  public title: string = '';
  public description: string = null;
  public isSystem: boolean = false;
  public searchTags: string[] = [];
  public createdDate: string = '';
  public updatedDate: string = '';
  public createdBy: string = '';
  public updatedBy: string = '';
  public isRoot: boolean = false;
  public selected: boolean = false;
  public structProperties: IBlueprintProperty[] = [];

  constructor(blueprint?: IBlueprint) {
    if (blueprint != null) {
      Object.assign(this, blueprint);
    }
  }
}

export interface IBlueprintReference {
  blueprintIdentifier: string;
  referenceType: string;
  version: string;
  type: string;
}

export interface IBlueprintPropertyTypes {
  basicType: BlueprintPropertyType;
  blueprintType: BlueprintReference;
}

export class BlueprintReference implements IBlueprintReference {
  public blueprintIdentifier: string = '';
  public referenceType: string = '';
  public version: string = '';
  public type: string = '';

  constructor(blueprintReference?: IBlueprintReference) {
    if (blueprintReference != null) {
      Object.assign(this, blueprintReference);
    }
  }
}

export interface IBlueprintProperty {
  id?: string;
  name: string;
  isList: boolean;
  isReadOnly: boolean;
  validValues: any[];
  propertyTypes: IBlueprintPropertyTypes[];
  propertyTypeGroup: BlueprintPropertyTypeGroup;
  constraintType: BlueprintConstraintType;
  propertySources: BlueprintPropertySourceType[];
  defaultPropertySource: BlueprintPropertySourceType;
  isOptional: boolean;
  isSystem?: boolean;
}

export interface ISelectedItems<T> {
  name: string;
  value: T;
  selected: boolean;
  visible: boolean;
}

export interface IGetBlueprintRequest {
  blueprintIdentifier: string;
  blueprintState: BlueprintState;
}
