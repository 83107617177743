import { AssetType } from '@/components/asset-services/models/asset-enums';
import { BlueprintProperties } from '@/components/asset-services/models/blueprint-enums';
import store from '@/store';
import { EntityType } from '@/view-models/agreement-view-model';

export function isHidden(identifier: string, propName: string): boolean {
  const allHidden: string[] = [
    BlueprintProperties._AssetId,
    BlueprintProperties._CreatedBy,
    BlueprintProperties._CreatedDate,
    BlueprintProperties._Key,
    BlueprintProperties._LegacyKey,
    BlueprintProperties.name,
    BlueprintProperties._UpdatedBy,
    BlueprintProperties._UpdatedDate,
    BlueprintProperties.Formulas,
    BlueprintProperties.Tags
  ];
  if (identifier === AssetType.Heater) {
    const heaterHidden: string[] = [BlueprintProperties.CustomerKey];
    return heaterHidden.includes(propName) || allHidden.includes(propName);
  }
  return allHidden.includes(propName);
}

export function validationRules(identifier: string, propName: string): string[] {
  if (identifier === AssetType.Heater) {
    if (propName === BlueprintProperties.name) {
      return ['assetNameUnique'];
    }
  }
  return null;
}

export function onInitValue(identifier: string, propName: string): any {
  if (identifier === AssetType.Heater) {
    if (propName === BlueprintProperties.CustomerKey) {
      return store.state.assetState.availableAssignments.find((x) => x.type === EntityType.Customer)?.key;
    }
  }
  return null;
}

export function onInitValidValues(identifier: string, propName: string): any[] {
  if (identifier === AssetType.Heater) {
    if (propName === BlueprintProperties.CustomerKey) {
      return store.state.assetState.availableAssignments
        .filter((x) => x.type === EntityType.Customer)
        .map((x) => x.key);
    } else if (propName === BlueprintProperties.SiteKey) {
      return store.state.assetState.availableAssignments
        .filter((x) => x.type === EntityType.CustomerSite)
        .map((x) => x.key);
    }
  }
  return null;
}
