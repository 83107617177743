import { render, staticRenderFns } from "./bread-crumb.vue?vue&type=template&id=23c09639&scoped=true&"
import script from "./bread-crumb.vue?vue&type=script&lang=ts&"
export * from "./bread-crumb.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./bread-crumb.vue?vue&type=style&index=0&id=23c09639&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23c09639",
  null
  ,true
)

export default component.exports