import { IAsset } from '../../view-models/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '..';
import {
  IBurnerViewModel,
  IDamperValuesViewModel,
  IAirRegister,
  IDrillingViewModel
} from '../../view-models/burner-view-model';
import { CustomerAssetService } from '../../services/customer-asset-service';
import { KESCustomerService } from '../../services/customer-service';
import sharedAxiosInstance from '../../services/api-service';
import ConfigFactory from '../../services/config';
import { IUnit } from '../../view-models/customer-view-models';
import { IUser } from '../../view-models/user-view-models';
import { IBurnerLayoutViewModel } from '@/view-models/burner-layout-view-model';
import {v4 as uuid} from 'uuid';
import { ControlTypes, FuelLineSubTypes } from '@/enums/subGroupTypes';
import { isEqual, cloneDeep } from 'lodash';
import { HierarchyService } from '@/services/hierarchy-service';
import HelperMethods from '@/shared/helper-methods';
import { UserManagementService } from '@/services/user-management-service';
import { IAssignmentNodeViewModel } from '@/view-models/agreement-view-model';

export interface IAssetStoreState {
  assets: IAsset[];
  selectedAsset: IAsset;
  selectedBurner: IBurnerViewModel;
  selectedSubsType: string;
  service: CustomerAssetService;
  customerService: KESCustomerService;
  accountService: UserManagementService;
  hierarchyService: HierarchyService;
  selectedAssetSaved: string;
  user: IUser;
  availableAssignments: IAssignmentNodeViewModel[];
  selectedBurnerBackup: IBurnerViewModel;
  unsavedChanges: boolean;
}

export interface IAssetStoreGetters extends GetterTree<IAssetStoreState, IRootState> {
  getSelectedAsset(state: IAssetStoreState): IAsset;
  getUnsavedChanges(state: IAssetStoreState): boolean;
  getSelectedBurner(state: IAssetStoreState): IBurnerViewModel;
  getSelectedSubsType(state: IAssetStoreState): string;
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreState> {
  updateUnsavedChanges(state: IAssetStoreState, unsavedChanges: boolean): void;
  updateSelectedAsset(state: IAssetStoreState, assetKey: string): void;
  updateUser(state: IAssetStoreState, user: IUser): void;
  updateAssignments(state: IAssetStoreState, assignmentItems: IAssignmentNodeViewModel[]): void;
  updateAssets(state: IAssetStoreState, assets: IAsset[]): void;
  updateService(state: IAssetStoreState, service: CustomerAssetService): void;
  updateCustomerService(state: IAssetStoreState, service: KESCustomerService): void;
  updateAccountService(state: IAssetStoreState, service: UserManagementService): void;
  updateHierarchyService(state: IAssetStoreState, service: HierarchyService): void;
  updateSelectedBurner(state: IAssetStoreState, currentBurner: IBurnerViewModel): void;
  updateSelectedSubscription(state: IAssetStoreState, subscriptionType: string): void;
  updateSelectedAssetSaved(state: IAssetStoreState, saveString: string): void;
  updateSelectedAssetUnits(state: IAssetStoreState, units: IUnit[]): void;
  deleteSelectedBurner(state: IAssetStoreState, burnerKey: string): void;
  deleteSubscription(state: IAssetStoreState, subscritionType: string): void;
  updateAirRegisterDamperK(
    state: IAssetStoreState,
    data: {damperK: IDamperValuesViewModel[], airRegisterNumber: number}
  ): void;
  updateAirRegisterDamperAreas(
    state: IAssetStoreState,
    data: {damperAreas: IDamperValuesViewModel[], airRegisterNumber: number}
  ): void;
  addDamperKAirRegister(state: IAssetStoreState, data: string): void;
  addDamperAreasAirRegister(state: IAssetStoreState, data: string): void;
  deleteAirRegister(state: IAssetStoreState, airRegisterNumber: number): void;
  updateDamperArea(state: IAssetStoreState, data: {damperArea: number, airRegisterNumber: number}): void;
  updateDamperKValue(state: IAssetStoreState, data: {damperKValue: number, airRegisterNumber: number}): void;
  updateAirRegisterName(state: IAssetStoreState, data: {name: string, airRegisterNumber: number}): void;
  updateSubGroup(state: IAssetStoreState, data: {name: string, airRegisterNumber: number}): void;
  selectPrimaryAirRegister(state: IAssetStoreState, data: {isChecked: boolean, airRegisterNumber: number}): void;
  updateSubGroups(state: IAssetStoreState): void;
  resetBurnerUnsavedChanges(state: IAssetStoreState): void;
  updateBurnerLayoutWithAirRegisterOrFuelLines(state: IAssetStoreState, type: string): void;
  updateBurnerLayoutWithDeleteAirRegisterFuelLineChanges(state: IAssetStoreState, key: string): void;
  updateBurnerLayoutWithAirRegisterNameChanges(state: IAssetStoreState, airRegister: IAirRegister): void;
  updateBurnerLayoutWithFuelLineChanges(state: IAssetStoreState, fuelLine: IDrillingViewModel): void;
  updateSelectedAssetWithBurner(state: IAssetStoreState): void;
  emptyBurnerLayout(state: IAssetStoreState): void;
}

export interface IAssetStoreActions extends ActionTree<IAssetStoreState, IRootState> {
  retrieveAssets(context: IAssetContext): Promise<any>;
  saveSelectedAsset(context: IAssetContext, asset: IAsset): Promise<any>;
  retrieveServiceConfig(context: IAssetContext): Promise<any>;
  retrieveAssetUnits(context: IAssetContext, asset: IAsset): Promise<any>;
  createUnit(
    context: IAssetContext,
    data: {unitName: string, unitKey: string, customerKey: string, siteKey: string}
  ): Promise<any>;
  updateUnsavedChanges(context: IAssetContext, unsavedChanges: boolean): Promise<void>;
  hasUnsavedChanges(context: IAssetContext, selectedAsset: IAsset): Promise<boolean>;
  canDeleteBurner({ state }: IAssetContext, burnerKey: string): Promise<boolean>;
}

export type IAssetContext = ActionContext<IAssetStoreState, IRootState>;

export const AssetStore = {
  namespaced: true,
  state: {
    assets: [],
    selectedAsset: undefined,
    selectedBurner: undefined,
    selectedSubsType: '',
    service: undefined,
    customerService: undefined,
    accountService: undefined,
    selectedAssetSaved: '',
    user: undefined,
    availableAssignments: [],
    selectedBurnerBackup: undefined,
    unsavedChanges: false
  } as IAssetStoreState,
  getters:  {
    getSelectedAsset(state: IAssetStoreState): IAsset {
      return state.selectedAsset;
    },
    getUnsavedChanges(state: IAssetStoreState): boolean {
      return state.unsavedChanges;
    },
    getSelectedBurner(state: IAssetStoreState): IBurnerViewModel {
      return state.selectedBurner;
    },
    getSelectedSubsType(state: IAssetStoreState): string {
      return state.selectedSubsType;
    }
  } as IAssetStoreGetters,
  mutations: {
    updateUnsavedChanges(state: IAssetStoreState, unsavedChanges: boolean): void {
      state.unsavedChanges = unsavedChanges;
    },
    updateSelectedAsset(state: IAssetStoreState, assetKey: string): void {
      const selectedAsset = state.assets.find((asset) => asset.key === assetKey);
      if (selectedAsset) {
        state.selectedAsset = selectedAsset;
      }
    },
    updateUser(state: IAssetStoreState, user: IUser): void {
      state.user = user;
    },
    updateAssignments(state: IAssetStoreState, assignmentItems: IAssignmentNodeViewModel[]): void {
      state.availableAssignments = assignmentItems;
    },
    updateAssets(state: IAssetStoreState, assets: IAsset[]): void {
      state.assets = assets;
    },
    updateService(state: IAssetStoreState, service: CustomerAssetService): void {
      state.service = service;
    },
    updateCustomerService(state: IAssetStoreState, service: KESCustomerService): void {
      state.customerService = service;
    },
    updateAccountService(state: IAssetStoreState, service: UserManagementService): void {
      state.accountService = service;
    },
    updateHierarchyService(state: IAssetStoreState, service: HierarchyService): void {
      state.hierarchyService = service;
    },
    updateSelectedBurner(state: IAssetStoreState, currentBurner: IBurnerViewModel): void {
      state.selectedBurner = currentBurner;
      state.selectedBurnerBackup = currentBurner;
    },
    updateSelectedSubscription(state: IAssetStoreState, subscriptionType: string): void {
      state.selectedSubsType = subscriptionType;
    },
    updateSelectedAssetSaved(state: IAssetStoreState, saveString: string): void {
      state.selectedAssetSaved = saveString;
    },
    updateSelectedAssetUnits(state: IAssetStoreState, units: IUnit[]): void {
      state.selectedAsset.unitNames = units;
    },
    deleteSelectedBurner(state: IAssetStoreState, burnerKey: string): void {
      if (state.selectedAsset && state.selectedAsset.burnerList && burnerKey) {
        const updatedBurnerList = state.selectedAsset.burnerList.filter((b) => b.burnerKey !== burnerKey);
        state.selectedAsset.burnerList = updatedBurnerList;
      }
    },
    deleteSubscription(state: IAssetStoreState, subscritionType: string): void {
      if (state.selectedAsset && state.selectedAsset.subscription && subscritionType) {
        if (subscritionType === 'Fuel Side' && state.selectedAsset.subscription.fuelSide) {
          state.selectedAsset.subscription = {};
        } else if (subscritionType === 'Air Side' && state.selectedAsset.subscription.airSide) {
          delete state.selectedAsset.subscription.airSide;
          if (state.selectedAsset.subscription.flowNetwork) {
            delete state.selectedAsset.subscription.flowNetwork;
          }
        } else if (subscritionType === 'Flow Network' && state.selectedAsset.subscription.flowNetwork) {
          delete state.selectedAsset.subscription.flowNetwork;
        }
      }
    },
    updateAirRegisterDamperK(
      state: IAssetStoreState,
      data: {damperK: IDamperValuesViewModel[], airRegisterNumber: number}
    ): void {
      if (state.selectedBurner.burnerDetail.config.airRegisters.length < 1) {
        state.selectedBurner.burnerDetail.config.airRegisters.push(
          {damperKValues: data.damperK, number: data.airRegisterNumber, damperArea: 1});
      }
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.damperKValues = data.damperK;
    },
    updateAirRegisterDamperAreas(
      state: IAssetStoreState,
      data: {damperAreas: IDamperValuesViewModel[], airRegisterNumber: number}
    ): void {
      if (state.selectedBurner.burnerDetail.config.airRegisters.length < 1) {
        state.selectedBurner.burnerDetail.config.airRegisters.push(
          {damperAreas: data.damperAreas, number: data.airRegisterNumber, damperKValue: 1});
      }
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.damperAreas = data.damperAreas;
    },
    addDamperKAirRegister(state: IAssetStoreState, data: string): void {
      const airRegisterNumberForNewRegister = state.selectedBurner.burnerDetail.config.airRegisters.length;
      state.selectedBurner.burnerDetail.config.airRegisters.push(
        {damperKValues: [], number: airRegisterNumberForNewRegister,
        damperArea: 1, isPrimary: airRegisterNumberForNewRegister === 0 ? true : false,
        name: data, subGroupKey: '', key: uuid()});
    },
    addDamperAreasAirRegister(state: IAssetStoreState, data: string): void {
      const airRegisterNumberForNewRegister = state.selectedBurner.burnerDetail.config.airRegisters.length;
      state.selectedBurner.burnerDetail.config.airRegisters.push(
        {damperAreas: [], number: airRegisterNumberForNewRegister,
        damperKValue: 1, isPrimary: airRegisterNumberForNewRegister === 0 ? true : false,
        name: data, subGroupKey: '', key: uuid()});
    },
    deleteAirRegister(state: IAssetStoreState, airRegisterNumber: number): void {
      const foundIndex = state.selectedBurner.burnerDetail.config.airRegisters.findIndex((airReg) =>
      airReg.number === airRegisterNumber);
      if (foundIndex > -1) {
        state.selectedBurner.burnerDetail.config.airRegisters.splice(foundIndex, 1);
      }
      if (state.selectedBurner.burnerDetail.config.airRegisters.length > 0) {
        state.selectedBurner.burnerDetail.config.airRegisters.forEach((item, index) => {
          item.number = index;
        });
      }
    },
    updateDamperArea(state: IAssetStoreState, data: {damperArea: number, airRegisterNumber: number}): void {
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.damperArea = data.damperArea;
    },
    updateDamperKValue(state: IAssetStoreState, data: {damperKValue: number, airRegisterNumber: number}): void {
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.damperKValue = data.damperKValue;
    },
    updateAirRegisterName(state: IAssetStoreState, data: {name: string, airRegisterNumber: number}): void {
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.name = data.name;
    },
    updateSubGroup(state: IAssetStoreState, data: {name: string, airRegisterNumber: number}): void {
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.subGroupKey = data.name;
    },
    selectPrimaryAirRegister(state: IAssetStoreState, data: {isChecked: boolean, airRegisterNumber: number}): void {
      state.selectedBurner.burnerDetail.config.airRegisters.forEach((item)=> {
        item.isPrimary = false;
      });
      const airRegister = state.selectedBurner.burnerDetail.config.airRegisters
        .find((airR) => airR.number === data.airRegisterNumber);
      airRegister.isPrimary = data.isChecked;
    },
    updateSubGroups(state: IAssetStoreState): void {
      const index = state.selectedAsset.burnerList.findIndex((burner) =>
        burner.burnerKey === state.selectedBurner.burnerKey);
      const subGroups = state.selectedAsset.burnerList[index].burnerDetail.subGroups;
      const mappingSubGroups = state.selectedAsset.burnerList[index].burnerDetail.mappingSubGroups;
      state.selectedBurner.burnerDetail.subGroups = subGroups;
      state.selectedBurner.burnerDetail.mappingSubGroups = mappingSubGroups;
    },
    resetBurnerUnsavedChanges(state: IAssetStoreState): void {
      state.selectedBurner = cloneDeep(state.selectedBurnerBackup);
    },
    updateBurnerLayoutWithAirRegisterOrFuelLines(state: IAssetStoreState, type: string): void {
      let newlyAddedItems: IBurnerLayoutViewModel[] = [];
      const length = state.selectedBurner.burnerDetail.burnerLayout.length + 1;
      if (type === ControlTypes.FuelLine) {
        const newlyAddedFuelLines = state.selectedBurner.burnerDetail.drilling.filter((object) =>
          !(state.selectedBurner.burnerDetail.burnerLayout)
            .some((item) => object.headerKey === item.key));
        newlyAddedItems = getFuelLineBurnerLayoutObject(newlyAddedFuelLines, length);
      } else {
        const newlyAddedAirRegisters = state.selectedBurner.burnerDetail.config.airRegisters
          .filter((object) => !(state.selectedBurner.burnerDetail.burnerLayout)
          .some((item) => object.key === item.key));
        newlyAddedItems = getAirRegisterBurnerLayoutObject(newlyAddedAirRegisters, length);
      }
      state.selectedBurner.burnerDetail.burnerLayout = state.selectedBurner.burnerDetail.burnerLayout
        .concat(newlyAddedItems);
    },
    updateBurnerLayoutWithDeleteAirRegisterFuelLineChanges(state: IAssetStoreState, key: string): void {
      const index = state.selectedBurner.burnerDetail.burnerLayout.findIndex((object) => object.key === key);
      if (index > -1) {
        state.selectedBurner.burnerDetail.burnerLayout.splice(index, 1);
        state.selectedBurner.burnerDetail.burnerLayout.forEach((items, itemIndex) => {
          items.sortOrder = itemIndex + 1;
        });
      }
    },
    updateBurnerLayoutWithAirRegisterNameChanges(state: IAssetStoreState, airRegister: IAirRegister): void {
      const index = state.selectedBurner.burnerDetail.burnerLayout.findIndex((object) =>
        object.key === airRegister.key);
      if (index > -1) {
        state.selectedBurner.burnerDetail.burnerLayout[index].name = airRegister.name;
      }
    },
    updateBurnerLayoutWithFuelLineChanges(state: IAssetStoreState, fuelLine: IDrillingViewModel): void {
      const index = state.selectedBurner.burnerDetail.burnerLayout.findIndex((object) =>
        object.key === fuelLine.headerKey);
      if (index > -1) {
        state.selectedBurner.burnerDetail.burnerLayout[index].name = fuelLine.name;
        state.selectedBurner.burnerDetail.burnerLayout[index].referenceKey = fuelLine.referenceKey;
        state.selectedBurner.burnerDetail.burnerLayout[index].subType = fuelLine.referenceKey ?
          FuelLineSubTypes.ThreeWayValve : FuelLineSubTypes.None;
      }
    },
    updateSelectedAssetWithBurner(state: IAssetStoreState): void {
      const index = state.selectedAsset.burnerList.findIndex((burner) =>
        burner.burnerKey === state.selectedBurner.burnerKey);
      state.selectedAsset.burnerList[index] = state.selectedBurner;
    },
    emptyBurnerLayout(state: IAssetStoreState): void {
      const index = state.selectedAsset.burnerList.findIndex((burner) =>
        burner.burnerKey === state.selectedBurner.burnerKey);
      state.selectedAsset.burnerList[index].burnerDetail.burnerLayout = [];
    }
  } as IAssetStoreMutations,
  actions: {
    async retrieveAssets(context: IAssetContext): Promise<any> {
      if (context.state.service && context.state.user) {
        await context.state.service
          .getActiveCustomerAssets(context.state.user.activeCustomerKey).then((response) => {
            // Filter out Flare equipmentTypes here and check that user is assigned to each asset
            const filteredAssets = response.filter((asset) =>
              asset.equipmentType !== 'Flare' &&
              context.state.availableAssignments
                .filter((assignment) => assignment.key === asset.key).length > 0);
            context.commit('updateAssets', filteredAssets);
            return Promise.resolve();
        }).catch((error) => {
          const errorMessage = error.response ? error.response.data.message : error.message;
          throw new Error('Retrieve Assets Failed: ' + errorMessage);
        });
      } else {
        throw new Error('Retrieve Assets Error: Could not resolve service.');
      }
    },
    async saveSelectedAsset(context: IAssetContext, asset: IAsset): Promise<any> {
      // Notify user that the asset is being saved.
      context.commit('updateSelectedAssetSaved', 'Saving');
      if (context.state.service) {
        try {
          asset.lastUpdatedBy = context.state.user ? context.state.user.name : 'USER_NOT_FOUND';
          const savedAsset = await context.state.service.saveAsset(asset);
          // Update assetState assets
          const foundIndex = context.state.assets.findIndex((x) => x.key === savedAsset.key);
          if (foundIndex >= 0) {
            context.state.assets[foundIndex] = savedAsset;
          } else {
            context.state.assets.push(savedAsset);
          }
          // Update Selected Asset
          context.commit('updateSelectedAsset', savedAsset.key);
          context.commit('updateSelectedAssetSaved', 'All changes saved');
          context.state.selectedBurnerBackup =
          savedAsset.burnerList?.find((burner) =>
            burner.burnerKey === context.state.selectedBurnerBackup?.burnerKey);
        } catch (error) {
          const err = error as any;
          const errorMessage = err?.response?.data?.message ?? err?.message;
          if (errorMessage) {
            context.commit('updateSelectedAssetSaved', 'Error');
            throw new Error('Save Asset Failed: ' + errorMessage);
          }
        }
        store.commit('app/updateIsLoading', false);
      } else {
        store.commit('app/updateIsLoading', false);
        throw new Error('Save Asset Failed: Could not resolve service.');
      }
    },
    async retrieveServiceConfig(context: IAssetContext): Promise<any> {
      if (process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL &&
        process.env.VUE_APP_PORTAL_API_URL &&
        process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL
      ) {
        if (!context.state.service) {
          context.commit('updateService', new CustomerAssetService(
            sharedAxiosInstance.sharedCaeAxiosInstance,
            process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL
          ));
        }
        if (!context.state.customerService) {
          context.commit('updateCustomerService', new KESCustomerService(
            sharedAxiosInstance.sharedKesAxiosInstance,
            process.env.VUE_APP_PORTAL_API_URL
          ));
        }
        if (!context.state.accountService) {
          context.commit('updateAccountService', await UserManagementService.factory());
        }
        if (!context.state.hierarchyService) {
          context.commit('updateHierarchyService', new HierarchyService(
            sharedAxiosInstance.sharedHbAxiosInstance,
            process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL
          ));
        }
      } else {
        const conf = await ConfigFactory.GetConfig();
        if (!context.state.service) {
          context.commit('updateService', new CustomerAssetService(
            sharedAxiosInstance.sharedCaeAxiosInstance,
            conf.get('apiBaseUrl')));
        }
        if (!context.state.customerService) {
          context.commit('updateCustomerService', new KESCustomerService(
            sharedAxiosInstance.sharedKesAxiosInstance,
            conf.get('portalApiUrl')));
        }
        if (!context.state.accountService) {
          context.commit('updateAccountService', await UserManagementService.factory());
        }
        if (!context.state.hierarchyService) {
          context.commit('updateHierarchyService', new HierarchyService(
            sharedAxiosInstance.sharedHbAxiosInstance,
            conf.get('hbApiUrl')));
        }
      }
      const umService = await UserManagementService.factory();
      if (context.state.accountService && umService) {
        await context.state.accountService.getUser().then((response) => {
          context.commit('updateUser', response);
          return Promise.resolve();
        }).catch((error) => {
          const errorMessage = error.response ? error.response.data.message : error.message;
          throw new Error('User Retrieval Error: ' + errorMessage);
        });
        await umService.getAssignmentTree()
          .then((response) => {
            context.commit('updateAssignments', response);
            return Promise.resolve();
        }).catch((error) => {
          const errorMessage = error.response ? error.response.data.message : error.message;
          throw new Error('Customer Site Retrieval Error: ' + errorMessage);
        });
      } else {
        throw new Error('Retrieve Config Error: Could not retrieve config.');
      }
    },
    async retrieveAssetUnits(context: IAssetContext, asset: IAsset): Promise<any> {
      if (context.state.customerService) {
        await context.state.customerService
          .getAssetUnits(asset.customerKey, asset.siteKey)
          .then((response) => {
            context.commit('updateSelectedAssetUnits', response);
            return Promise.resolve();
          }).catch((error) => {
            const errorMessage = error.response ? error.response.data.message : error.message;
            throw new Error('Retrieve Units Error: ' + errorMessage);
          });
      } else {
        throw new Error('Retrieve Units Error: Could not resolve service.');
      }
    },
    async createUnit(
      context: IAssetContext,
      data: {unitName: string, unitKey: string, customerKey: string, siteKey: string}
    ): Promise<any> {
      if (context.state.customerService) {
        await context.state.customerService
          .postAssetUnits(data.unitName, data.unitKey, data.customerKey, data.siteKey)
          .then((response) => {
            context.commit('updateSelectedAssetUnits', response);
            return Promise.resolve();
          })
          .catch((error) => {
            const errorMessage = error.response ? error.response.data.message : error.message;
            throw new Error('Save Unit Error: ' + errorMessage);
          });
      } else {
        throw new Error('Save Unit Error: Could not resolve service.');
      }
    },
    async updateUnsavedChanges(context: IAssetContext, unsavedChanges: boolean): Promise<void> {
      context.commit('updateUnsavedChanges', unsavedChanges);
    },
    async hasUnsavedChanges(context: IAssetContext, selectedAsset: IAsset): Promise<boolean> {
      if (!isEqual(context.state.selectedAsset, selectedAsset)) {
        context.commit('updateSelectedAssetSaved', 'Unsaved Changes');
        context.commit('updateUnsavedChanges', true);
        return true;
      } else {
        context.commit('updateSelectedAssetSaved', '');
        context.commit('updateUnsavedChanges', false);
        return false;
      }
    },
    async canDeleteBurner({ state }: IAssetContext, burnerKey: string): Promise<boolean> {
      if (state.service) {
        const hierarchies = await state.hierarchyService
          .getBurnerConfigHierarchies(state.selectedAsset.key, burnerKey);
        return HelperMethods.isArrayEmpty(hierarchies);
      } else {
        throw new Error('Retrieve Assets Error: Could not resolve service.');
      }
    }
  } as IAssetStoreActions
};

export function getAirRegisterBurnerLayoutObject(data: IAirRegister[], length: number): IBurnerLayoutViewModel[] {
  return data.map((item, index) => (
    {
      sortOrder: length > 0 ? length + index : index + 1,
      key: item.key,
      name: item.name,
      type: ControlTypes.AirRegister,
      referenceKey: '',
      subType: FuelLineSubTypes.None
    }
  ));
}

export function getFuelLineBurnerLayoutObject(data: IDrillingViewModel[], length: number): IBurnerLayoutViewModel[] {
  return data.map((item, index) => (
    {
      sortOrder: length > 0 ? length + index : index + 1,
      key: item.headerKey,
      name: item.name,
      type: ControlTypes.FuelLine,
      referenceKey: item.referenceKey ? item.referenceKey : '',
      subType: item.referenceKey ? FuelLineSubTypes.ThreeWayValve: FuelLineSubTypes.None
    }
  ));
}
