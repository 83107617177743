
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IAssetImportResponse } from '@/components/asset-services/models/asset-interfaces';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'import-modal'
})
export default class ImportModal extends Vue {
  private showModal: boolean = false;
  private selectedFile: File = null;
  private importing: boolean = false;
  private fileName: string = null;
  private importErrors: string[] = [];
  private largeFileSizeMB: number = 1;

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetName(): string {
    return this.assetStore.selectedAssetContext.entity.name?.value;
  }

  private get fileSizeMB(): number {
    if (!this.selectedFile) {
      return 0;
    }
    const bytesPerMB = 1000000;
    return this.selectedFile.size / bytesPerMB;
  }

  private close(): void {
    this.showModal = false;
    this.fileName = null;
    this.selectedFile = null;
    this.importErrors = [];
    this.importing = false;
    this.$nextTick(() => this.$validator.reset());
  }

  private async importFile(): Promise<void> {
    this.importing = true;
    const isValid = await this.$validator.validate();
    if (!isValid) {
      this.importing = false;
      return;
    }
    this.assetStore
      .importToSelectedAsset(this.selectedFile)
      .then((response: IAssetImportResponse) => {
        if (!HelperMethods.isArrayEmpty(response.failures)) {
          this.fileName = this.selectedFile.name;
          this.selectedFile = null;
          this.importErrors = response.failures.map((failure) => failure.reason);
          this.$nextTick(() => this.$validator.reset());
        } else {
          this.$bvToast.toast(`File successfully imported to \"${this.assetName}\".`, {
            title: this.$t('toast.importSuccess').toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true
          });
          this.close();
        }
      })
      .catch((err) => showError(err))
      .finally(() => (this.importing = false));
  }

  @Watch('selectedFile')
  private onFileChange(file): void {
    if (file != null) {
      this.importErrors = [];
    }
  }
}
