
import { Component, Vue } from 'vue-property-decorator';
import Preview from '@/components/burnersPage/Preview.vue';
import { IBurnerLayoutViewModel, FuelTipReferences } from '@/view-models/burner-layout-view-model';
import { IAirRegister, IBurnerViewModel, IDrillingViewModel } from '@/view-models/burner-view-model';
import store from '@/store';
import { IAsset } from '@/view-models/assets-view-models';
import { showError } from '@/utils/StoreHelper';
import { getAirRegisterBurnerLayoutObject, getFuelLineBurnerLayoutObject } from '@/store/assetStore/assetStore';
import HelperMethods from '@/shared/helper-methods';
import { FuelLineSubTypes } from '@/enums/subGroupTypes';

@Component({
  name: 'burner-layout-designer',
  components: {
    Preview,
  },
})
export default class BurnerLayoutDesigner extends Vue {
  private layoutItems: IBurnerLayoutViewModel[] = [];
  private showSpinner: boolean = false;
  private fuelLineSubTypes = FuelLineSubTypes;
  private draggingItem = {} as IBurnerLayoutViewModel;

  get selectedAsset(): IAsset {
    return store.state.assetState.selectedAsset;
  }

  get selectedAssetCopy(): IAsset {
    return JSON.parse(JSON.stringify(this.selectedAsset));
  }

  get selectedBurner(): IBurnerViewModel {
    return store.state.assetState.selectedBurner;
  }

  get displayItems(): IBurnerLayoutViewModel[] {
    return this.layoutItems;
  }

  set displayItems(items: IBurnerLayoutViewModel[]) {
    this.layoutItems = items;
  }

  get burnerLayout(): IBurnerLayoutViewModel[] {
    return store.state.assetState.selectedBurner.burnerDetail.burnerLayout;
  }

  get burnerLayoutCopy(): IBurnerLayoutViewModel[] {
    return JSON.parse(JSON.stringify(this.burnerLayout));
  }

  get airRegisters(): IAirRegister[] {
    return this.selectedBurner.burnerDetail.config.airRegisters;
  }

  get fuelTips(): IDrillingViewModel[] {
    return this.selectedBurner.burnerDetail.drilling;
  }

  get displayItemsInEMA(): IBurnerLayoutViewModel[] {
    return JSON.parse(JSON.stringify(this.displayItems));
  }

  get initialLoadItems(): IBurnerLayoutViewModel[] {
    const airRegisters = getAirRegisterBurnerLayoutObject(this.airRegisters, 0);
    const fuelLines = getFuelLineBurnerLayoutObject(this.fuelTips, airRegisters.length + 1);
    return airRegisters.concat(fuelLines);
  }

  get isLayoutEmpty(): boolean {
    return this.getCurrentBurnerData() === null || this.getCurrentBurnerData().length === 0;
  }

  get currentBurner(): IBurnerViewModel {
    return this.selectedAsset.burnerList.find((burner) =>
      burner.burnerKey === this.selectedBurner.burnerKey);
  }

  get referencedFuelTips(): FuelTipReferences {
    return this.displayItems.reduce((references: FuelTipReferences, arr: IBurnerLayoutViewModel) => {
      if (!HelperMethods.isStringEmpty(arr.referenceKey)) {
        references[arr.referenceKey] = [...(references[arr.referenceKey] || []), arr];
      }
      return references;
    }, {});
  }

  public mounted(): void {
    if (this.isLayoutEmpty) {
      this.displayItems = this.initialLoadItems;
    } else {
      this.displayItems = this.burnerLayoutCopy;
    }
    this.displayItems.sort(this.sortByOrder);
    this.checkReferencedTips(this.referencedFuelTips);
    this.formThreeWayTip();
  }

  private referenceCheck(item: IBurnerLayoutViewModel): void {
    const tip = this.getReferencedFuelLine(item);
    if (tip.sortOrder !== item.sortOrder - 1 || tip.sortOrder !== item.sortOrder + 1) {
      this.moveOrder({
        source: tip.sortOrder,
        target: this.getSortOrder(tip, item),
      });
    }
  }

  private getSortOrder(referenceTip: IBurnerLayoutViewModel, sourceItem: IBurnerLayoutViewModel): number {
    if (referenceTip.sortOrder > sourceItem.sortOrder) {
      const prevItem = this.getNextItem(sourceItem);
      return prevItem.sortOrder;
    } else {
      const nextItem = this.getPrevItem(sourceItem);
      return nextItem.sortOrder;
    }
  }

  private moveOrderUpOrDown(order: number, isDown: boolean, item: IBurnerLayoutViewModel): void {
    this.moveOrder({
      source: order,
      target: isDown ? this.getDownOrder(order, item) : this.getUpOrder(order, item),
    });
    if (this.isThreeWayValve(item)) {
      this.referenceCheck(item);
    }
    this.checkReferencedTips(this.referencedFuelTips);
    this.formThreeWayTip();
    store.commit('navState/updateBurnerTabEnabled', false);
  }

  private getDownOrder(order: number, item: IBurnerLayoutViewModel): number {
    const nextItem = this.getNextItem(item);
    if (this.eitherOneisThreeWayValve(item, nextItem) || this.bothAreUnpairedThreeWayValves(item, nextItem)) {
      return this.getReferencedFuelLine(nextItem).sortOrder;
    } else if (this.bothArePairedThreeWayValves(item, nextItem)) {
      return nextItem.sortOrder;
    }
    return order + 1;
  }

  private getUpOrder(order: number, item: IBurnerLayoutViewModel): number {
    const prevItem = this.getPrevItem(item);
    if (this.eitherOneisThreeWayValve(item, prevItem) || this.bothAreUnpairedThreeWayValves(item, prevItem)) {
      return this.getReferencedFuelLine(prevItem).sortOrder;
    } else if (this.bothArePairedThreeWayValves(item, prevItem)) {
      return prevItem.sortOrder;
    }
    return order - 1;
  }

  private getNextItem(item: IBurnerLayoutViewModel): IBurnerLayoutViewModel {
    return this.displayItems.find((line: IBurnerLayoutViewModel) => line.sortOrder === item.sortOrder + 1);
  }

  private getPrevItem(item: IBurnerLayoutViewModel): IBurnerLayoutViewModel {
    return this.displayItems.find((line: IBurnerLayoutViewModel) => line.sortOrder === item.sortOrder - 1);
  }

  private eitherOneisThreeWayValve(sourceItem: IBurnerLayoutViewModel, currentItem: IBurnerLayoutViewModel): boolean {
    return !this.isThreeWayValve(sourceItem) && this.isThreeWayValve(currentItem);
  }

  private bothAreUnpairedThreeWayValves(
    sourceItem: IBurnerLayoutViewModel,
    currentItem: IBurnerLayoutViewModel
  ): boolean {
    return (
      this.isThreeWayValve(sourceItem) &&
      this.isThreeWayValve(currentItem) &&
      !this.isReferencedWithCurrentFuelLine(sourceItem, currentItem)
    );
  }

  private bothArePairedThreeWayValves(
    sourceItem: IBurnerLayoutViewModel,
    currentItem: IBurnerLayoutViewModel
  ): boolean {
    return (
      this.isThreeWayValve(sourceItem) &&
      this.isThreeWayValve(currentItem) &&
      this.isReferencedWithCurrentFuelLine(sourceItem, currentItem)
    );
  }

  private handleDrop(item: IBurnerLayoutViewModel, event: DragEvent): void {
    const data = event.dataTransfer.getData('text/plain');
    if (parseInt(data, 10)) {
      this.moveOrder({
        source: parseInt(data, 10),
        target: item.sortOrder,
      });
    }
    if (this.isThreeWayValve(this.draggingItem)) {
      this.referenceCheck(this.draggingItem);
    }
    this.checkReferencedTips(this.referencedFuelTips);
    this.formThreeWayTip();
    store.commit('navState/updateBurnerTabEnabled', false);
  }

  private moveOrder(payload: { source: number; target: number }): void {
    if (this.displayItems.length === 0) {
      return;
    }
    const sourceItem = this.displayItems[payload.source - 1];
    sourceItem.sortOrder = payload.target;
    this.displayItems.forEach((item) => {
      if (item === sourceItem) {
        return;
      }
      if (payload.source < payload.target) {
        if (item.sortOrder <= payload.target && item.sortOrder > payload.source) {
          item.sortOrder--;
        }
      } else if (item.sortOrder >= payload.target && item.sortOrder < payload.source) {
        item.sortOrder++;
      }
    });
    this.displayItems.sort(this.sortByOrder);
  }

  private sortByOrder(n1: { sortOrder: number }, n2: { sortOrder: number }): number {
    return n1.sortOrder - n2.sortOrder;
  }

  private saveBurnerLayout(): void {
    this.showSpinner = true;
    this.saveSelectedAsset();
  }

  private async saveSelectedAsset(): Promise<void> {
    try {
      const index = this.selectedAssetCopy.burnerList.findIndex(
        (burner) => burner.burnerKey === this.selectedBurner.burnerKey
      );
      this.selectedAssetCopy.burnerList[index].burnerDetail.burnerLayout = this.displayItems;
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAssetCopy).then(() => {
        store.commit('assetState/updateSelectedBurner', this.currentBurner);
        store.commit('navState/updateBurnerTabEnabled', true);
      });
    } catch (err) {
      showError(err.message);
    }
    this.showSpinner = false;
  }

  private resetToLastSavedVersion(): void {
    this.displayItems = JSON.parse(JSON.stringify(this.getCurrentBurnerData()));
    this.checkReferencedTips(this.referencedFuelTips);
    this.formThreeWayTip();
    store.commit('navState/updateBurnerTabEnabled', true);
  }

  private getCurrentBurnerData(): IBurnerLayoutViewModel[] {
    const index = this.selectedAsset.burnerList.findIndex(
      (burner) => burner.burnerKey === this.selectedBurner.burnerKey
    );
    return this.selectedAsset.burnerList[index].burnerDetail.burnerLayout;
  }

  private checkReferencedTips(referenceItems: FuelTipReferences): void {
    if (Object.keys(referenceItems).length > 0) {
      for (const property in referenceItems) {
        if (referenceItems[property][1].sortOrder !== referenceItems[property][0].sortOrder + 1) {
          this.moveOrder({
            source: referenceItems[property][1].sortOrder,
            target: referenceItems[property][0].sortOrder + 1,
          });
        }
      }
    }
  }

  private formThreeWayTip(): void {
    const tips = this.referencedFuelTips;
    for (const property in tips) {
      if (Object.keys(tips).length > 0) {
        const tipIndex1: number = this.displayItemsInEMA.findIndex(
          (item: IBurnerLayoutViewModel) => item.name === tips[property][0].name
        );
        const tipIndex2: number = this.displayItemsInEMA.findIndex(
          (item: IBurnerLayoutViewModel) => item.name === tips[property][1].name
        );
        this.displayItemsInEMA[tipIndex1].name += '/' + this.displayItemsInEMA[tipIndex2].name;
        this.displayItemsInEMA.splice(tipIndex2, 1);
      }
    }
  }

  private getReferencedFuelLineName(item: IBurnerLayoutViewModel): string {
    return 'Linked with ' + this.getReferencedFuelLine(item).name;
  }

  private isThreeWayValve(item: IBurnerLayoutViewModel): boolean {
    return !HelperMethods.isStringEmpty(item.referenceKey) && item.subType === FuelLineSubTypes.ThreeWayValve;
  }

  private getReferencedFuelLine(item: IBurnerLayoutViewModel): IBurnerLayoutViewModel {
    return this.displayItems.find((line: IBurnerLayoutViewModel) => this.isReferencedWithCurrentFuelLine(item, line));
  }

  private isReferencedWithCurrentFuelLine(
    currentFuelLine: IBurnerLayoutViewModel,
    item: IBurnerLayoutViewModel
  ): boolean {
    return (
      !HelperMethods.isStringEmpty(item.referenceKey) &&
      item.referenceKey === currentFuelLine.referenceKey && item.key !== currentFuelLine.key
    );
  }
}
