
import { Vue, Component } from 'vue-property-decorator';
import {
  IAssetInstanceContext,
  IAssetOption,
  IAssetBundleContextResponse
} from '@/components/asset-services/models/asset-interfaces';
import { BlueprintState } from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint } from '@/components/asset-services/models/blueprint-interfaces';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import TypeAheadDropdown from '@/components/common/type-ahead-dropdown.vue';
import Dropdown from '@/components/utility/Dropdown.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'create-asset-modal',
  components: {
    Dropdown,
    LoadingOverlay,
    OnpointModal,
    TypeAheadDropdown
  }
})
export default class CreateAssetModal extends Vue {
  private showModal: boolean = false;
  private selectedAssetContext: IAssetInstanceContext = null;
  private selectedBlueprint: IBlueprint = null;
  private buildFromExisting: boolean = false;
  private loading: boolean = false;
  private newAssetName: string = null;

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get assetOptions(): IAssetOption[] {
    const options = this.assetStore.assetContexts.map((context) => ({
      key: context.entityId,
      name: context.entity.name.value,
      value: context
    }));
    options.sort((a: IAssetOption, b: IAssetOption) => HelperMethods.sortString(a.name, b.name));
    return options;
  }

  private get blueprintOptions(): IBlueprint[] {
    const options = this.blueprintStore.blueprints.filter(
      (blueprint) => blueprint.state === BlueprintState.Published && blueprint.isRoot
    );
    options.sort((a: IBlueprint, b: IBlueprint) => HelperMethods.sortString(a.title, b.title));
    return options;
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get okDisabled(): boolean {
    return this.loading || this.$validator.errors.any() || HelperMethods.isNullOrUndefined(this.newAssetName);
  }

  private created(): void {
    this.$validator.extend('assetNameUnique', {
      getMessage: () => `Asset Name must be unique`,
      validate: this.assetNameUnique
    });
  }

  private assetNameUnique(name: string): boolean {
    const assetNames = this.assetStore.assetContexts.map((c) => c.entity.name.value);
    return !assetNames.includes(name);
  }

  private close(): void {
    this.showModal = false;
  }

  public showCreateModal(): void {
    this.selectedAssetContext = null;
    this.selectedBlueprint = this.blueprintOptions.length === 1 ? this.blueprintOptions[0] : null;
    this.newAssetName = null;
    this.buildFromExisting = false;
    this.showModal = true;
  }

  private setSelectedAssetContext(selectedOption: IAssetOption): void {
    this.selectedAssetContext = selectedOption.value;
  }

  private setSelectedBlueprint(blueprintItem: IBlueprint): void {
    this.selectedBlueprint = blueprintItem;
  }

  private async createAsset(): Promise<void> {
    // validate
    const formValid = await this.$validator.validateAll();
    if (this.loading || !formValid) {
      return;
    }
    // create new asset
    if (this.buildFromExisting) {
      this.loading = true;
      this.buildFromAsset()
        .then((response) => {
          const clonedAssetId = response.hierarchy.asset.entityId;
          const blueprintId = this.selectedAssetContext.blueprintId;
          this.$router.push(`/asset-services/asset/edit/${blueprintId}/${clonedAssetId}`);
          this.close();
        })
        .catch((err) => showError(err))
        .finally(() => (this.loading = false));
    } else {
      this.assetStore.setSelectedAsset(null);
      this.$router.push({
        path: `/asset-services/asset/create/${this.selectedBlueprint.title}`,
        query: { name: this.newAssetName }
      });
      this.close();
    }
  }

  private async buildFromAsset(): Promise<IAssetBundleContextResponse> {
    return this.assetStore.cloneAsset({ assetId: this.selectedAssetContext.entityId, newName: this.newAssetName });
  }
}
