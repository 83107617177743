import { render, staticRenderFns } from "./icon-button.vue?vue&type=template&id=152997f1&scoped=true&"
import script from "./icon-button.vue?vue&type=script&lang=ts&"
export * from "./icon-button.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./icon-button.vue?vue&type=style&index=0&id=152997f1&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "152997f1",
  null
  ,true
)

export default component.exports