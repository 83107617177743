
import { v4 as uuid } from 'uuid';
import { Vue, Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import AssetDataStep from '@/components/asset-services/asset-edit/asset-data-step.vue';
import AssetErrorPreview from '@/components/asset-services/asset-edit/asset-error-preview.vue';
import { getDefaultCrumbs } from '@/components/asset-services/bread-crumb-routes';
import AssetHelperMethods from '@/components/asset-services/business-logic/asset-helper-methods';
import { AssetState, ResourceType } from '@/components/asset-services/models/asset-enums';
import {
  AssetInstance,
  IAssetBundle,
  IAssetInstanceFull,
  IAssetInstanceContext,
  IAssetBundleResponse
} from '@/components/asset-services/models/asset-interfaces';
import { IBlueprint } from '@/components/asset-services/models/blueprint-interfaces';
import BreadCrumb, { IBreadCrumbItem } from '@/components/common/bread-crumb.vue';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetServiceStoreModule, getAssetStoreModule, getBlueprintStoreModule } from '@/store';
import AssetServiceModule from '@/store/asset-service-store/asset-service-store';
import AssetModule from '@/store/asset-service-store/asset-store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'asset-edit-page',
  components: {
    AssetDataStep,
    AssetErrorPreview,
    BreadCrumb,
    LoadingOverlay
  },
  beforeRouteLeave(to, from, next): void {
    const self = this as AssetEditPage;
    if (self.leaveConfirmationNeeded) {
      self.attemptedLeaveRoute = to;
      self.showConfirmLeaveModal = true;
      next(false);
    } else {
      next();
    }
  }
})
export default class AssetEditPage extends Vue {
  private showConfirmLeaveModal: boolean = false;
  private leavePageConfirmed: boolean = false;
  private attemptedLeaveRoute: Route = null;
  private AssetState = AssetState;

  private get breadCrumbs(): IBreadCrumbItem[] {
    return [
      ...getDefaultCrumbs(),
      {
        text: this.$t('assetService.title', { action: '' }).toString(),
        to: '/asset-services/asset'
      },
      {
        text: this.isEdit ? 'Edit' : 'Create'
      }
    ];
  }

  private get assetIsLoading(): boolean {
    return this.assetStore.assetsLoadingMessage != null;
  }

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetServiceStore(): AssetServiceModule {
    return getAssetServiceStoreModule(store);
  }

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get selectedBlueprint(): IBlueprint {
    return this.blueprintStore.selectedBlueprint;
  }
  private get isEdit(): boolean {
    return this.assetStore.selectedAssetContext?.entityVersion != null;
  }
  private get isInvalid(): boolean {
    return !HelperMethods.isArrayEmpty(this.assetStore.assetErrors);
  }

  private get assetName(): string {
    const nameProp = this.assetStore.selectedAssetContext?.entity?.name;
    return nameProp ? nameProp.value : null;
  }

  private get assetState(): string {
    return this.assetStore.selectedAssetContext?.entityState;
  }

  private get hasUnsavedChanges(): boolean {
    const currentAsset = this.assetStore.selectedAssetContext?.entity;
    if (HelperMethods.isNullOrUndefined(this.assetStore.originalAsset)) {
      return currentAsset != null;
    }
    const currentStringified = JSON.stringify(currentAsset);
    const oldStringified = JSON.stringify(this.assetStore.originalAsset);
    return currentStringified !== oldStringified;
  }

  private get leaveConfirmationNeeded(): boolean {
    return this.hasUnsavedChanges && !this.leavePageConfirmed;
  }

  private assetNameUnique(name: string): boolean {
    const assetNames = this.assetStore.assetContexts
      .filter((context) => context.entityId !== this.assetStore.selectedAssetContext.entityId)
      .map((c) => c.entity.name.value);
    return !assetNames.includes(name);
  }

  private destroyed(): void {
    this.assetStore.setSelectedAsset(null);
    this.assetStore.setOriginalAsset(null);
    this.assetStore.setSelectedNode(null);
    this.assetStore.setAssetErrors([]);
  }

  private async created(): Promise<void> {
    this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.loading'));
    this.$validator.extend('integerIsValid', {
      getMessage: () => 'Field must be of type Integer',
      validate: HelperMethods.integerIsValid
    });
    this.$validator.extend('doubleIsValid', {
      getMessage: () => 'Field must be of type Double',
      validate: HelperMethods.doubleIsValid
    });
    this.$validator.extend('assetNameUnique', {
      getMessage: () => `Name must be unique`,
      validate: this.assetNameUnique
    });
    const assetKey = this.$route.params.assetKey;
    const blueprintKey = this.$route.params.blueprintKey;

    // Get information needed for business logic population
    if (!store.state.assetState.availableAssignments.length) {
      await store.dispatch('assetState/retrieveServiceConfig');
    }
    // if (HelperMethods.isArrayEmpty(this.assetStore.assetContexts)) {
    //   await this.assetServiceStore.getUser();
    //   await this.assetStore.loadAssets(this.assetServiceStore.user?.activeCustomerKey);
    // }

    const loadSteps = [];
    if (this.$route.name === 'EditAsset') {
      loadSteps.push(this.loadAsset(assetKey));
    }
    loadSteps.push(this.loadBlueprints());
    Promise.all(loadSteps)
      .then(() => {
        const topLevelBlueprint = this.blueprintStore.blueprints.find((blueprint) => blueprint.title === blueprintKey);
        this.blueprintStore.setSelectedBlueprint(topLevelBlueprint);
        if (this.$route.name === 'CreateAsset') {
          const queryName = this.$route.query.name as string;
          this.initializeAssetFromBlueprint(queryName);
        }
        this.assetStore.setAssetsLoadingMessage(null);
      })
      .catch((err) => {
        showError(err);
        HelperMethods.delay(2500).then(() => {
          this.$router.replace(`/asset-services/asset`);
        });
      });
  }

  private loadAsset(assetKey: string): Promise<IAssetInstanceContext> {
    return this.assetStore.loadAsset(assetKey);
  }

  private loadBlueprints(): Promise<IBlueprint[]> {
    return this.blueprintStore.fetchBlueprintSchema();
  }

  private initializeAssetFromBlueprint(assetName: string): void {
    const entity: IAssetInstanceFull = new AssetInstance();
    const blueprints = this.blueprintStore.blueprints;
    AssetHelperMethods.setAssetProperties(entity, this.selectedBlueprint, blueprints);
    if (!HelperMethods.isNullOrUndefined(assetName) && !HelperMethods.isNullOrUndefined(entity.name)) {
      entity.name.value = assetName;
    }
    const assetContext: IAssetInstanceContext = {
      blueprintId: this.selectedBlueprint.id,
      blueprintVersion: this.selectedBlueprint.version,
      entityVersion: null,
      entityId: 'temp_' + uuid(),
      entity,
      entityState: AssetState.Draft
    };
    this.assetStore.setSelectedAsset(assetContext);
    this.assetStore.setSelectedNode(null);
    this.assetStore.setOriginalAsset(null);
  }

  private saveDraft(): void {
    this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.saving'));
    const asset = this.assetStore.selectedAssetContext.entity;
    if (HelperMethods.isStringEmpty(asset.name?.value)) {
      showError('Asset must have a valid name to save as draft.');
      this.assetStore.setAssetsLoadingMessage(null);
      return;
    }
    const bundle: IAssetBundle = {
      asset: this.assetStore.selectedAssetContext,
      entities: [],
      relationships: []
    };
    this.assetStore.setAssetErrors([]);
    this.assetStore
      .saveAsset(bundle)
      .then((response: IAssetBundleResponse) => {
        if (this.$route.name !== 'EditAsset') {
          this.leavePageConfirmed = true;
          const assetId = response.hierarchy.asset.entityId;
          const blueprintId = response.hierarchy.asset.blueprintId;
          this.$router.push(`/asset-services/asset/edit/${blueprintId}/${assetId}`);
          this.leavePageConfirmed = false;
        }
        const relationshipErrors = response.failures.filter(
          (error) => error.resourceType === ResourceType.Relationship
        );
        if (relationshipErrors.length > 0) {
          showError(relationshipErrors.map((err) => err.reason).join('; '));
        } else {
          this.$bvToast.toast(this.$t('assets.successSave').toString(), {
            title: this.$t('global.saveDraft').toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true
          });
        }
      })
      .catch((err) => showError(err))
      .finally(() => this.assetStore.setAssetsLoadingMessage(null));
  }

  private validate(): void {
    const asset = AssetHelperMethods.cleanAssetForBundle(this.assetStore.selectedAssetContext);
    const bundle: IAssetBundle = {
      asset,
      entities: [],
      relationships: []
    };
    this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.validating'));
    this.assetStore.setAssetErrors([]);
    this.assetStore
      .validateAsset(bundle)
      .then((results) => {
        if (results.failures.length > 0) {
          this.assetStore.setAssetErrors(results.failures);
        } else {
          this.$bvToast.toast(this.$t('assets.successValidate').toString(), {
            title: this.$t('global.validate').toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true
          });
        }
      })
      .catch((err) => showError(err))
      .finally(() => this.assetStore.setAssetsLoadingMessage(null));
  }

  private validateAndPublish(): void {
    const asset = AssetHelperMethods.cleanAssetForBundle(this.assetStore.selectedAssetContext);
    const isFirstPublish = this.assetState === AssetState.Published;
    const bundle: IAssetBundle = {
      asset,
      entities: [],
      relationships: []
    };
    this.assetStore.setAssetsLoadingMessage(
      isFirstPublish ? this.$t('assets.loading.saving') : this.$t('assets.loading.publishing')
    );
    this.assetStore.setAssetErrors([]);
    this.assetStore
      .saveAsset(bundle)
      .then((saveResult) => {
        if (saveResult.failures.length > 0) {
          this.assetStore.setAssetErrors(saveResult.failures);
          throw new Error('Unable to publish asset due to errors on the asset. Please resolve them before publishing.');
        }
        const assetId = saveResult.hierarchy.asset.entityId;
        return this.assetStore.publishAsset(assetId);
      })
      .then((publishResult) => {
        if (publishResult.failures.length > 0) {
          showError(publishResult.failures.map((err) => err.reason).join('; '));
        } else {
          const successTitle = isFirstPublish ? this.$t('assets.publish') : this.$t('assets.save');
          const successMessage = isFirstPublish ? this.$t('assets.successPublish') : this.$t('assets.successSave');
          this.$bvToast.toast(successMessage.toString(), {
            title: successTitle.toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true
          });
        }
      })
      .catch((err) => showError(err))
      .finally(() => {
        this.assetStore.setAssetsLoadingMessage(null);
      });
  }

  private leave(): void {
    this.leavePageConfirmed = true;
    this.$router.push(this.attemptedLeaveRoute.path);
  }

  private saveAndLeave(): void {
    this.assetStore.setAssetsLoadingMessage(this.$t('assets.loading.saving'));
    const bundle: IAssetBundle = {
      asset: this.assetStore.selectedAssetContext,
      entities: [],
      relationships: []
    };
    this.assetStore
      .saveAsset(bundle)
      .then(() => this.leave())
      .catch((err) => showError(err))
      .finally(() => this.assetStore.setAssetsLoadingMessage(null));
  }
}
