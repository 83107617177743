
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import ShowSubscription from './ShowSubscription.vue';
import AddSubscription from './AddSubscription.vue';
import { IAsset } from '../../view-models/assets-view-models';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

@Component({
  components: { ShowSubscription, AddSubscription }
})
export default class SubscriptionsPage extends Vue {
  private showAddSubscription = 'none';

  get selectedAsset(): IAsset {
    return store.getters['assetState/getSelectedAsset'];
  }

  private checkToggle(sub: string): void {
    if (sub === 'Flow Network') {
      if (this.selectedAsset &&
          this.selectedAsset.subscription &&
          this.selectedAsset.subscription.airSide &&
          this.selectedAsset.subscription.fuelSide) {
        this.toggleSelect(sub);
      } else {
        store.commit('errorState/setError',
          {error: 'Flow Network Subscription Requires Fuel Side and Air Side Subscriptions.', uiError: true});
      }
    } else if (sub === 'Air Side') {
      if (this.selectedAsset &&
          this.selectedAsset.subscription &&
          this.selectedAsset.subscription.fuelSide) {
        this.toggleSelect(sub);
      } else {
        store.commit('errorState/setError',
          {error: 'Air Side Subscription Requires Fuel Side Subscription.', uiError: true});
      }
    } else {
      this.toggleSelect(sub);
    }
  }

  private toggleSelect(sub: string): void {
    if (this.showAddSubscription === sub) {
      this.showAddSubscription = 'none';
    } else if (this.showAddSubscription === 'none') {
      this.showAddSubscription = sub;
    }
  }

  private openAddFuelSide(): void {
    if (this.showAddSubscription === 'none') {
      this.showAddSubscription = 'Fuel Side';
    }
  }

  private openAddAirSide(): void {
    if (this.showAddSubscription === 'none') {
      this.showAddSubscription = 'Air Side';
    }
  }

  private openAddFlowNetwork(): void {
    if (this.showAddSubscription === 'none') {
      this.showAddSubscription = 'Flow Network';
    }
  }

  private ok(): void {
    this.showAddSubscription = 'none';
  }
}
