
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import CustomDateSelector from '../utility/CustomDateSelector.vue';
import moment from 'moment';
import { ISubscriptionDetailViewModel, IPurchaseViewModel } from '../../view-models/subscription-view-model';
import { ValidationField, validateForm } from '../../utils/Validator';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component({
  components: {CustomDateSelector}
})
export default class AddSubscriptions extends Vue {
  public displayedDate: string = '';
  public showPicker: boolean = false;

  private asset = store.state.assetState.selectedAsset;
  @Prop({required: true})
  private subscriptionType: string;
  @Prop({required: true})
  private ok: () => void;
  private minDate = moment().format('YYYY-MM-DD');
  private subscriptionDetail: ISubscriptionDetailViewModel = {
    expirationDate: '',
    purchases: [],
    expirationAlert: true,
  };
  private validateForm = validateForm;
  private validations: {[key: string]: ValidationField} = {
    expirationDate: {
      valid: true,
      warning: false,
      value: () => this.subscriptionDetail.expirationDate,
      validator: (value: string) => moment(value).isValid(),
      setWarning: () => false
    }
  };

  public mounted(): void {
    this.setDisplayDate();
  }

  public dateFormatter(value: string): string {
    const inputDate: moment.Moment = moment(value);
    let displayDate = '';
    if (inputDate.isValid()) {
      this.subscriptionDetail.expirationDate = inputDate.format('YYYY-MM-DD');
      displayDate = inputDate.format('MMMM DD, YYYY');
    } else {
      this.subscriptionDetail.expirationDate = '';
    }

    return displayDate;
  }

  public setDisplayDate(): void {
    switch (this.subscriptionType) {
    case 'Fuel Side':
      this.displayedDate = this.dateFormatter(this.asset.subscription.fuelSide.expirationDate);
      break;
    case 'Air Side':
      this.displayedDate = this.dateFormatter(this.asset.subscription.airSide.expirationDate);
      break;
    case 'Flow Network':
      this.displayedDate = this.dateFormatter(this.asset.subscription.flowNetwork.expirationDate);
      break;
    default:
      this.displayedDate = '';
    }
  }

  public hidePicker(): void {
    if (this.showPicker === true) {
      this.showPicker = false;
    }
  }

  @Watch('subscriptionDetail.expirationDate')
  private onContext(): void {
    this.displayedDate = this.dateFormatter(this.subscriptionDetail.expirationDate);
  }

  private resetModal(): void {
    this.subscriptionDetail = {
      expirationDate: '',
      purchases: [],
      expirationAlert: true,
    };
  }

  private async addSubscriptions(): Promise<void> {
    const formIsValid = this.validateForm(this.validations, Object.keys(this.validations));
    if (formIsValid && typeof this.asset !== 'undefined') {
      if (!this.asset.subscription) {
        this.asset.subscription = {};
      }

      const msInDay = 24 * 60 * 60 * 1000;
      const today = new Date();
      const lengthOfPurchase = new Date(this.subscriptionDetail.expirationDate).getTime() - today.getTime();

      const purchaseRecord: IPurchaseViewModel = {
        length: Math.floor(lengthOfPurchase / msInDay),
        purchaseDate: today.toISOString().slice(0, 10),
      };
      switch (this.subscriptionType) {
        case 'Fuel Side':
          if (this.asset.subscription.fuelSide) {
            this.asset.subscription.fuelSide.expirationDate = this.subscriptionDetail.expirationDate;
            this.asset.subscription.fuelSide.purchases.push(purchaseRecord);
            this.asset.subscription.fuelSide.expirationAlert = this.subscriptionDetail.expirationAlert;
          } else {
            this.subscriptionDetail.purchases.push(purchaseRecord);
            this.asset.subscription.fuelSide = this.subscriptionDetail;
          }
          break;
        case 'Air Side':
          if (!this.asset.subscription.fuelSide) {
            store.commit('errorState/setError',
              {error: 'Add Air Side Subscription - Requires Fuel Side Subscription.'});
          } else {
            if (this.asset.subscription.airSide) {
              this.asset.subscription.airSide.expirationDate = this.subscriptionDetail.expirationDate;
              this.asset.subscription.airSide.purchases.push(purchaseRecord);
              this.asset.subscription.airSide.expirationAlert = this.subscriptionDetail.expirationAlert;
            } else {
              this.subscriptionDetail.purchases.push(purchaseRecord);
              this.asset.subscription.airSide = this.subscriptionDetail;
            }
          }
          break;
        case 'Flow Network':
          if (!this.asset.subscription.fuelSide || !this.asset.subscription.airSide) {
            store.commit('errorState/setError', {error: 'Add Flow Network Subscription - Requires Fuel Side and Air Side Subscriptions.'});
          } else {
            if (this.asset.subscription.flowNetwork) {
              this.asset.subscription.flowNetwork.expirationDate = this.subscriptionDetail.expirationDate;
              this.asset.subscription.flowNetwork.purchases.push(purchaseRecord);
              this.asset.subscription.flowNetwork.expirationAlert = this.subscriptionDetail.expirationAlert;
            } else {
              this.subscriptionDetail.purchases.push(purchaseRecord);
              this.asset.subscription.flowNetwork = this.subscriptionDetail;
            }
          }
          break;
        default:
          break;
      }
      try {
        store.commit('app/updateIsLoading', true);
        await store.dispatch('assetState/saveSelectedAsset', this.asset);
      } catch (err) {
        showError(err.message);
      } finally {
        store.commit('app/updateIsLoading', false);
      }
      this.resetModal();
      this.ok();
    } else {
      store.commit('errorState/setError', {
        error: 'Please input a valid expiration date.',
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
  }
}
