import Vue from 'vue';

export default abstract class NavigationStep extends Vue {
  protected abstract onMount(index: number): void;
}

export class NavigationStepConfig {
  public title: string;
  public tagLine: string;
  public index: number;
}
