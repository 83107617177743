
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import TextEntry from './TextEntry.vue';
import store from '../../store';
import { v4 as uuid } from 'uuid';
import { ValidationField, validateForm } from '../../utils/Validator';
import { caeEventBus } from '@/eventBus/asset-event-bus';
import { BurnerDrillingEnums } from '@/enums/burnerDrillingEnums';
import {
  IDrillingViewModel,
  IFilterOptions,
  IManifoldViewModel,
  IPortViewModel,
  ITipViewModel
} from '@/view-models/burner-view-model';
import HelperMethods from '@/shared/helper-methods';
import { IBurnerLayoutViewModel } from '@/view-models/burner-layout-view-model';
import Manifold from './Manifold.vue';
import AddDrillingComponent from './AddDrillingComponent.vue';

@Component({
  components: { AddDrillingComponent, Manifold, TextEntry }
})
export default class Header extends Vue {
  @Prop({ required: true })
  private header: IDrillingViewModel;
  @Prop({ required: true })
  private saveAsset: () => void;
  @Prop({ required: true })
  private upsertHeaderInState: (config: any) => void;
  @Prop({ required: true })
  private headerIndex: number;
  @Prop({ required: true })
  private headersList: IDrillingViewModel[];

  private isExpanded: boolean = false;
  private choiceButtons = ['yes', 'no'];
  private selectedChoice = 1;
  private store = store;
  private isFormDirty = false;
  private saving = false;
  private selectedBurner = store.state.assetState.selectedBurner;
  private headerExistsInState: boolean = false;
  private showErrorMessage: boolean = false;
  private validateForm = validateForm;
  private filterOptions: IFilterOptions[] = [];
  private threeWayOptions: IFilterOptions[] = [];
  private threeWayValue: string = '';
  private errorMessage: string = 'Fuel line is required to pair for a 3-way valve.';

  private validations: { [key: string]: ValidationField } = {
    name: {
      valid: true,
      warning: false,
      value: () => this.header.name,
      validator: (value: string) => {
        const nameIndex = this.headersList.findIndex((header) => {
          if (header.headerKey !== this.header.headerKey) {
            return HelperMethods.equalsIgnoreCase(header.name ? header.name : '', value ? value : '');
          }
        });
        return value !== '' && nameIndex < 0;
      },
      setWarning: () => false
    },
    isCriticalFuelLine: {
      valid: true,
      warning: false,
      value: () => this.header.isCriticalFuelLine,
      validator: (value: boolean) => {
        return value != null;
      },
      setWarning: () => false
    },
    referenceKey: {
      valid: true,
      warning: false,
      value: () => this.header.referenceKey,
      validator: (value: string) => {
        if (this.header.checked && value === '') {
          return false;
        } else if (!this.header.checked && value === '') {
          return true;
        }
        return value !== '';
      },
      setWarning: () => false
    }
  };

  get hasBurnerLayout(): boolean {
    return this.burnerLayout !== null;
  }

  get hasMappingSubGroups(): boolean {
    return this.selectedBurner.burnerDetail.mappingSubGroups
      ? this.selectedBurner.burnerDetail.mappingSubGroups.length > 0
      : false;
  }

  get getFilterOptions(): IFilterOptions[] {
    if (this.hasMappingSubGroups) {
      this.selectedBurner.burnerDetail.mappingSubGroups.forEach((subGroup) => {
        this.filterOptions.push({
          value: subGroup.key,
          text: subGroup.name
        });
      });
    }
    this.filterOptions.sort((a, b) => {
      return HelperMethods.sortString(a.text, b.text);
    });
    this.filterOptions.unshift({
      value: '',
      text: 'Select a Subgroup'
    });
    return this.filterOptions;
  }

  get getFuelOptions(): IFilterOptions[] {
    this.threeWayOptions = [];
    this.threeWayOptions.push({
      value: '',
      text: 'Select Fuel Line'
    });

    this.headersList.forEach((h) => {
      if (
        (h.referenceKey === this.header.referenceKey || h.referenceKey === '') &&
        h.headerKey !== this.header.headerKey
      ) {
        this.threeWayOptions.push({
          value: h.headerKey,
          text: h.name
        });
      }
    });
    return this.threeWayOptions;
  }

  get getThreeWayValue(): string {
    if (HelperMethods.isStringEmpty(this.header.referenceKey)) {
      this.threeWayValue = '';
    } else {
      const threeWayHeader = this.headersList.find(
        (h) => h.headerKey !== this.header.headerKey && h.referenceKey === this.header.referenceKey
      );
      this.threeWayValue = threeWayHeader ? threeWayHeader.headerKey : '';
    }
    return this.threeWayValue;
  }

  set getThreeWayValue(val) {
    this.threeWayValue = val;
  }

  get subGroupKeyValue(): string {
    if (
      this.hasMappingSubGroups &&
      this.selectedBurner.burnerDetail.mappingSubGroups.some((map) => map.key === this.header.subGroupKey)
    ) {
      return this.header.subGroupKey;
    }
    return '';
  }

  set subGroupKeyValue(val: string) {
    this.header.subGroupKey = val;
  }

  get burnerLayout(): IBurnerLayoutViewModel[] {
    return store.state.assetState.selectedBurner.burnerDetail.burnerLayout;
  }

  get referencedFuelHeader(): IDrillingViewModel {
    return this.headersList.find(
      (h) => h.headerKey !== this.header.headerKey && h.referenceKey === this.header.referenceKey
    );
  }

  public created(): void {
    this.selectedChoice = this.header.isCriticalFuelLine === true ? 0 : 1;
  }

  public mounted(): void {
    this.updateApplyButton();

    caeEventBus.$on(BurnerDrillingEnums.UpdateApplyButtons, () => {
      this.updateApplyButton();
    });
    caeEventBus.$on(BurnerDrillingEnums.CollapseAll, () => {
      this.isExpanded = false;
    });
    caeEventBus.$on(BurnerDrillingEnums.ExpandAll, () => {
      this.isExpanded = true;
    });
  }

  private beforeDestroy(): void {
    caeEventBus.$off(BurnerDrillingEnums.UpdateApplyButtons);
    caeEventBus.$off(BurnerDrillingEnums.CollapseAll);
    caeEventBus.$off(BurnerDrillingEnums.ExpandAll);
  }

  public applyAll(): void {
    this.apply(false);
    this.header.manifolds?.forEach((manifold, manifoldIndex) => {
      const manifoldReference = `manifold-${this.headerIndex}-${manifoldIndex}`;
      const manifoldElement = this.$refs[manifoldReference] as Manifold[];
      manifoldElement[0].applyAll();
    });
  }

  private toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  private isCriticalFuelLine(index: number): void {
    this.selectedChoice = index;
    this.header.isCriticalFuelLine = !this.header.isCriticalFuelLine;
    this.isFormDirty = true;
  }
  public apply(save: boolean): void {
    this.saving = true;
    if (this.validateForm(this.validations)) {
      const configToSave = {
        headerKey: this.header.headerKey,
        name: this.header.name,
        isCriticalFuelLine: this.header.isCriticalFuelLine,
        grouping: this.header.grouping,
        subGroupKey: this.header.subGroupKey,
        referenceKey: this.header.referenceKey
      };
      this.upsertHeaderInState(configToSave);
      if (save) {
        this.updateFuelHeader(this.header);
        this.saveAsset();
      }
      this.isFormDirty = false;
      store.commit('navState/updateBurnerTabEnabled', true);
    } else {
      store.commit('errorState/setError', {
        error: 'Please enter valid values for all fields.',
        config: {
          sendLog: false
        },
        uiError: true
      });
      if (this.header.checked && this.header.referenceKey === '') {
        this.showErrorMessage = true;
      }
    }
    this.saving = false;
  }

  @Watch('header.name', { deep: true })
  @Watch('header.isCriticalFuelLine', { deep: true })
  @Watch('header.grouping', { deep: true })
  @Watch('header.subGroupKey', { deep: true })
  @Watch('header.referenceKey', { deep: true })
  private formDirty(): void {
    this.isFormDirty = true;
    store.commit('navState/updateBurnerTabEnabled', false);
  }

  private updateApplyButton(): void {
    if (!this.selectedBurner.burnerDetail.drilling[this.headerIndex]) {
      this.headerExistsInState = false;
    } else {
      this.headerExistsInState = true;
    }
  }

  private resetValues(): void {
    this.header.name = 'Fuel Gas';
    this.header.isCriticalFuelLine = true;
    this.header.grouping = '';
    this.selectedChoice = 0;
    this.subGroupKeyValue = '';
    this.header.checked = false;
    this.clearThreeWaySelection();
  }

  private clearThreeWaySelection(): void {
    if (this.header.referenceKey !== '' || this.header.referenceKey !== null) {
      this.headersList.forEach((h: any) => {
        if (h.referenceKey === this.header.referenceKey) {
          h.referenceKey = '';
          h.checked = false;
        }
      });
    }
  }

  private setSubGroupValue(data: string): void {
    this.subGroupKeyValue = data;
  }

  private makeThreeWay(val: boolean): void {
    this.isFormDirty = true;
    if (val === false) {
      this.setThreeWayValve('', false);
      this.showErrorMessage = false;
    } else if (val && this.getFuelOptions.length < 2) {
      this.$bvModal.show(this.header.headerKey);
    }
  }

  public setThreeWayValve(val: string, currentHeader: boolean): void {
    if (val !== '') {
      this.clearOtherReferences();
      this.showErrorMessage = false;
      const refKey = uuid();
      const threeWayHeader = this.headersList.find((data) => data.headerKey === val);
      if (threeWayHeader) {
        this.updateFuelHeaderReference(threeWayHeader, true, refKey);
      }
      this.header.referenceKey = refKey;
    } else if (val === '') {
      const threeWayHeader = this.referencedFuelHeader;
      if (threeWayHeader) {
        this.updateFuelHeaderReference(threeWayHeader, false, '');
      }
      this.header.referenceKey = '';
      if (currentHeader) {
        this.header.checked = true;
        this.showErrorMessage = true;
      }
    }
    this.getThreeWayValue = val;
    this.selectedBurner.burnerDetail.drilling = this.headersList;
    this.updateFuelHeader(this.header);
    store.commit('assetState/updateSelectedAssetWithBurner');
  }

  private updateFuelHeaderReference(threeWayHeader: IDrillingViewModel, checkedValue: boolean, keyValue: string): void {
    threeWayHeader.checked = checkedValue;
    threeWayHeader.referenceKey = keyValue;
    this.updateFuelHeader(threeWayHeader);
  }

  private clearOtherReferences(): void {
    if (!HelperMethods.isStringEmpty(this.header.referenceKey)) {
      this.updateFuelHeaderReference(this.referencedFuelHeader, false, '');
    }
  }

  private closeThreeWay(): void {
    this.header.checked = false;
    this.$bvModal.hide(this.header.headerKey);
  }

  private updateFuelHeader(header: IDrillingViewModel): void {
    if (this.burnerLayout !== null) {
      store.commit('assetState/updateBurnerLayoutWithFuelLineChanges', header);
    }
  }

  private upsertManifoldInState(config: any, headerIndex: number, manifoldIndex: number): void {
    const manifoldToUpdate = this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds[manifoldIndex];
    if (manifoldToUpdate) {
      Object.assign(manifoldToUpdate, config);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    } else {
      const newManifold = config;
      newManifold.tips = [];
      this.selectedBurner.burnerDetail.drilling[headerIndex].manifolds.push(newManifold);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    }
  }
  private emptyManifold(): IManifoldViewModel[] {
    return [
      {
        name: 'Manifold Name',
        doubleDrop: false,
        diameter: '2.067',
        diameterUnit: 'INCH',
        doubleDropDiameterUnit: 'INCH',
        doubleDropCd: '0.9',
        doubleDropDiameter: '0.003175',
        tips: this.emptyTip()
      }
    ];
  }

  private emptyTip(): ITipViewModel[] {
    return [
      {
        name: 'Tip Name',
        diameterUnit: 'INCH',
        diameter: '1.063',
        cd: '0.85',
        number: 1,
        doubleDrop: false,
        doubleDropDiameterUnit: 'INCH',
        doubleDropDiameter: '0.003175',
        doubleDropCd: '0.9',
        ports: this.emptyPort()
      }
    ];
  }

  private emptyPort(): IPortViewModel[] {
    return [
      {
        name: 'PortName',
        diameterUnit: 'INCH',
        diameter: '0.0015875',
        number: 1
      }
    ];
  }

/* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit()
  private deleteConfirm(
    unsavedComponentArray: any,
    componentToDelete: any,
    headerIndex?: number,
    manifoldIndex?: number,
    tipIndex?: number,
    portIndex?: number
  ): void {}
}
