
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import { IBurnerViewModel } from '../../view-models/burner-view-model';
import SearchBox from '../search/SearchBox.vue';
import { ValidationField, validateForm } from '../../utils/Validator';
import Loading from '../common/Loading.vue';
import {showError} from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component({
  components: {
    SearchBox,
    Loading
  }
})
export default class BurnerEdit extends Vue {
  public showPicker: boolean = false;
  private isSaving = false;
  private selectedAsset = store.state.assetState.selectedAsset;
  private burners: string[] = [];
  private burnerTypes = ['flowelements.burner_k_factor'];
  private burnerToEdit = store.state.assetState.selectedBurner;
  private placeholderBurner: IBurnerViewModel = {
    burnerKey: '',
    burnerName: '',
    burnerDetail: {zoneType: '', config: {}}
  };
  private validateForm = validateForm;
  private validations: {[key: string]: ValidationField} = {
    burnerName: {
      valid: true,
      warning: false,
      value: () => this.placeholderBurner.burnerName,
      validator: (value: string) => value !== '',
      setWarning: () => false
    },
    zoneType: {
      valid: true,
      warning: false,
      value: () => this.placeholderBurner.burnerDetail.zoneType,
      validator: (value: string) => value !== '',
      setWarning: () => false
    }
  };

  constructor() {
    super();
    if (typeof this.selectedAsset !== 'undefined' && typeof this.selectedAsset.burnerList !== 'undefined') {
      this.burners = this.selectedAsset.burnerList.map((x) => x.burnerName);
      this.burnerTypes = this.selectedAsset.burnerList.map((x) => x.burnerDetail.zoneType) as string[];
      this.burnerTypes.push('flowelements.burner_k_factor');
      this.burnerTypes = Array.from(new Set(this.burnerTypes));
    }
    if (typeof this.burnerToEdit !== 'undefined') {
      this.placeholderBurner.burnerKey =
        (typeof this.burnerToEdit.burnerKey !== 'undefined') ? this.burnerToEdit.burnerKey : '';
      this.placeholderBurner.burnerName =
        (typeof this.burnerToEdit.burnerName !== 'undefined') ? this.burnerToEdit.burnerName : '';
      this.placeholderBurner.burnerDetail.zoneType =
        (typeof this.burnerToEdit.burnerDetail.zoneType !== 'undefined') ? this.burnerToEdit.burnerDetail.zoneType : '';
    }
  }

  public hidePicker(): void {
    if (this.showPicker === true) {
      this.showPicker = false;
    }
  }

  private cancel(): void {
    if (!this.isSaving) {
      this.clearPlaceholderBurner();
      this.$router.push(store.state.navState.prevNavKey);
      store.state.navState.prevNavKey = 'burner-edit';
    }
  }

  private async saveBurner(): Promise<void> {
    if (this.isSaving) {
      return;
    } else {
      this.isSaving = true;
      if (this.validateForm(this.validations, Object.keys(this.validations)) && this.selectedAsset &&
        this.burnerToEdit) {
        const previousValues = {
          burnerKey: this.burnerToEdit.burnerKey,
          zoneType: this.burnerToEdit.burnerDetail.zoneType,
          burnerName: this.burnerToEdit.burnerName
        };
        this.burnerToEdit.burnerKey = this.placeholderBurner.burnerKey;
        this.burnerToEdit.burnerDetail.zoneType = this.placeholderBurner.burnerDetail.zoneType;
        this.burnerToEdit.burnerName = this.placeholderBurner.burnerName;
        try {
          await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
          store.commit('assetState/updateSelectedAsset', this.selectedAsset.key);
          store.commit('assetState/updateSelectedBurner', this.burnerToEdit);
          this.clearPlaceholderBurner();
          await this.$router.push(store.state.navState.prevNavKey);
          store.state.navState.prevNavKey = 'burner-edit';
        } catch (err) {
          // Need reset burner edits here
          if (this.burnerToEdit) {
            this.burnerToEdit.burnerKey = previousValues.burnerKey;
            this.burnerToEdit.burnerDetail.zoneType = previousValues.zoneType;
            this.burnerToEdit.burnerName = previousValues.burnerName;
          }
          showError(err.message);
        }
      } else {
        store.commit('errorState/setError', {
          error: 'Form Validation Failed: Please input a valid value for all fields.',
          config: {
            sendLog: false
          },
          uiError: true
        });
      }
      this.isSaving = false;
    }
  }

  private clearPlaceholderBurner(): void {
    this.placeholderBurner = {
      burnerKey: '',
      burnerName: '',
      burnerDetail: {zoneType: '', config: {}}
    };
  }
}
