
import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import Header from './Header.vue';
import Manifold from './Manifold.vue';
import Tip from './Tip.vue';
import Port from './Port.vue';
import AddDrillingComponent from './AddDrillingComponent.vue';
import {
  IDrillingViewModel,
  IManifoldViewModel,
  IPortViewModel,
  ITipViewModel
} from '../../view-models/burner-view-model';
import Dropdown from '../utility/Dropdown.vue';
import { v4 as uuid } from 'uuid';
import * as FXP from 'fast-xml-parser';
import { caeEventBus } from '@/eventBus/asset-event-bus';
import { BurnerDrillingEnums } from '@/enums/burnerDrillingEnums';
import { portSizesMeters } from '@/assets/configs/drillSizes';
import { showError } from '@/utils/StoreHelper';
import HelperMethods from '@/shared/helper-methods';
import { IBurnerLayoutViewModel } from '@/view-models/burner-layout-view-model';
import { ControlTypes } from '@/enums/subGroupTypes';
import OnpointModal from '@/components/common/OnpointModal.vue';

Vue.use(BootstrapVue);

@Component({
  components: {
    Dropdown,
    AddDrillingComponent,
    Header,
    Manifold,
    Tip,
    Port,
    OnpointModal
  }
})
export default class BurnerDrilling extends Vue {
  private store = store;
  private selectedAsset = store.state.assetState.selectedAsset;
  private selectedBurner = store.state.assetState.selectedBurner;
  private selectedComponent: any = { name: '' };
  private savedComponent: any = {};
  private savedComponentArray: any = [];
  private unsavedComponentArray: any = [];
  private showReviewMessage: boolean = false;
  private previousDrilling: IDrillingViewModel[] = [];
  private headers: IDrillingViewModel[] = [];
  private showModal: boolean = false;
  private showProgress: boolean = false;
  private isExpanded: boolean = false;
  private pairFuelLines: {[key: string]: {[key: string]: string|boolean|number} }=  {};

  get burnerLayout(): IBurnerLayoutViewModel[] {
    return store.state.assetState.selectedBurner.burnerDetail.burnerLayout;
  }

  get hasBurnerLayout(): boolean {
    return this.burnerLayout !== null;
  }

  public created(): void {
    if (this.selectedBurner) {
      if (!this.selectedBurner.burnerDetail.drilling) {
        this.selectedBurner.burnerDetail.drilling = [];
      }
      if (this.selectedBurner.burnerDetail.drilling.length < 1) {
        this.selectedBurner.burnerDetail.drilling.push(this.emptyHeader());
      }
      this.headers = JSON.parse(JSON.stringify(this.selectedBurner.burnerDetail.drilling));
      this.checkReferencedFuelHeaders();
    }
  }

  private checkReferencedFuelHeaders(): void {
    this.selectedBurner.burnerDetail.drilling.forEach((header) => {
      header.checked = false;
      if (!HelperMethods.isStringEmpty(header.referenceKey)) {
        header.checked = true;
      }
    });
  }

  public expandCollapse(): void {
    if (this.isExpanded) {
      caeEventBus.$emit(BurnerDrillingEnums.CollapseAll);
    } else {
      caeEventBus.$emit(BurnerDrillingEnums.ExpandAll);
    }
    this.isExpanded = !this.isExpanded;
  }

  @Watch('headers', { deep: true, immediate: true })
  private setFormDirty(val: IDrillingViewModel[], oldVal: IDrillingViewModel[]): void {
    if (oldVal && this.selectedBurner && this.selectedBurner.burnerDetail.drilling) {
      if (this.headers.every((header) => header.headerKey && this.findHeaderInState(header.headerKey))) {
        this.showReviewMessage = false;
      } else {
        store.commit('navState/updateBurnerTabEnabled', false);
        this.showReviewMessage = true;
      }
    }
  }

  @Watch('selectedBurner.burnerDetail.drilling', { deep: true, immediate: true })
  private onDrillingChange(): void {
    if (this.headers.every((header) => header.headerKey && this.findHeaderInState(header.headerKey))) {
      this.showReviewMessage = false;
    } else {
      store.commit('navState/updateBurnerTabEnabled', false);
      this.showReviewMessage = true;
    }
  }

  private emptyHeader(): IDrillingViewModel {
    let fuelHeaderName = 'Fuel Gas';
    const fhList = this.headers.map((item) => {
      const fhName = item.name.split('-');
      return item.name ? parseInt(fhName[fhName.length - 1].trim(), 10) || 0 : 0;
    });
    const arName = fhList.length > 0 ? Math.max(...fhList) + 1 : 1;
    fuelHeaderName = 'Fuel Gas - ' + arName;
    return {
      headerKey: uuid(),
      name: fuelHeaderName,
      isCriticalFuelLine: true,
      grouping: '',
      manifolds: this.emptyManifold(),
      subGroupKey: '',
      referenceKey: '',
      checked: false
    };
  }

  private emptyManifold(): IManifoldViewModel[] {
    return [
      {
        name: 'Manifold Name',
        doubleDrop: false,
        diameter: '2.067',
        diameterUnit: 'INCH',
        doubleDropDiameterUnit: 'INCH',
        doubleDropCd: '0.9',
        doubleDropDiameter: '0.003175',
        tips: this.emptyTip()
      }
    ];
  }

  private emptyTip(): ITipViewModel[] {
    return [
      {
        name: 'Tip Name',
        diameterUnit: 'INCH',
        diameter: '1.063',
        cd: '0.85',
        number: 1,
        doubleDrop: false,
        doubleDropDiameterUnit: 'INCH',
        doubleDropDiameter: '0.003175',
        doubleDropCd: '0.9',
        ports: this.emptyPort()
      }
    ];
  }

  private emptyPort(): IPortViewModel[] {
    return [
      {
        name: 'PortName',
        diameterUnit: 'INCH',
        diameter: '0.0015875',
        number: 1
      }
    ];
  }

  private deleteConfirm(
    unsavedComponentArray: any,
    componentToDelete: any,
    headerIndex?: number,
    manifoldIndex?: number,
    tipIndex?: number,
    portIndex?: number
  ): void {
    const drilling = this.selectedBurner.burnerDetail.drilling;
    if (
      typeof portIndex === 'number' &&
      typeof tipIndex === 'number' &&
      typeof manifoldIndex === 'number' &&
      typeof headerIndex === 'number'
    ) {
      if (
        drilling[headerIndex] &&
        drilling[headerIndex].manifolds[manifoldIndex] &&
        drilling[headerIndex].manifolds[manifoldIndex].tips[tipIndex]
      ) {
        this.savedComponentArray = drilling[headerIndex].manifolds[manifoldIndex].tips[tipIndex].ports;
        this.savedComponent = this.savedComponentArray[portIndex];
      } else {
        this.savedComponentArray = [];
        this.savedComponent = {};
      }
    } else if (typeof tipIndex === 'number' && typeof manifoldIndex === 'number' && typeof headerIndex === 'number') {
      if (drilling[headerIndex] && drilling[headerIndex].manifolds[manifoldIndex]) {
        this.savedComponentArray = drilling[headerIndex].manifolds[manifoldIndex].tips;
        this.savedComponent = this.savedComponentArray[tipIndex];
      } else {
        this.savedComponentArray = [];
        this.savedComponent = {};
      }
    } else if (typeof manifoldIndex === 'number' && typeof headerIndex === 'number') {
      if (drilling[headerIndex]) {
        this.savedComponentArray = drilling[headerIndex].manifolds;
        this.savedComponent = this.savedComponentArray[manifoldIndex];
      } else {
        this.savedComponentArray = [];
        this.savedComponent = {};
      }
    } else if (typeof headerIndex === 'number' && drilling[headerIndex]) {
      this.savedComponentArray = drilling;
      this.savedComponent = this.savedComponentArray[headerIndex];
    } else {
      this.savedComponentArray = [];
      this.savedComponent = {};
      this.clearThreeWaySelection(componentToDelete);
    }

    this.selectedComponent = componentToDelete;
    this.unsavedComponentArray = unsavedComponentArray;
    this.$bvModal.show('deleteComponent');
  }

  private clearThreeWaySelection(header: any): void {
    if (!HelperMethods.isStringEmpty(header.referenceKey)) {
      const threeWayHeader = this.headers.find(
        (h) => h.headerKey !== header.headerKey && h.referenceKey === header.referenceKey
      );
      if (threeWayHeader) {
        threeWayHeader.checked = false;
        threeWayHeader.referenceKey = '';
        store.commit('assetState/updateBurnerLayoutWithFuelLineChanges', threeWayHeader);
      }

      header.checked = false;
      header.referenceKey = '';
    }
    this.selectedBurner.burnerDetail.drilling = this.headers;
  }

  private async removeComponent(): Promise<void> {
    this.showProgress = true;
    const indexInDisplay = this.unsavedComponentArray.indexOf(this.selectedComponent, 0);
    const indexInState = this.savedComponentArray.indexOf(this.savedComponent, 0);
    const savedLength = this.savedComponentArray.length;

    if (indexInDisplay > -1) {
      this.unsavedComponentArray.splice(indexInDisplay, 1);
    }

    if (indexInState > -1) {
      // check to make sure you don't remove the component twice
      if (savedLength === this.savedComponentArray.length) {
        this.savedComponentArray.splice(indexInState, 1);
      }
      this.clearThreeWaySelection(this.selectedComponent);
      this.updateBurnerLayout('delete', this.selectedComponent);
      store.commit('assetState/updateSelectedBurner', this.selectedBurner);
      await this.saveSelectedAsset();
    }
    this.showProgress = false;
    this.$bvModal.hide('deleteComponent');
  }

  private async saveSelectedAsset(value?: boolean): Promise<void> {
    store.commit('assetState/updateSelectedAssetWithBurner');
    try {
      await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
      if (this.hasBurnerLayout && value) {
        this.showModal = true;
      }
      store.commit('navState/updateBurnerTabEnabled', true);
    } catch (err) {
      showError(err.message);
    }
  }

  private importFile(event: Event): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;

    if (!input || !input.files || !input.files.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = async () => {
      const xml: string = reader.result as string;
      const json = FXP.parse(xml);
      await this.mapJsonToDrilling(json);
      await this.mapPairFuelLines();
      this.showReviewMessage = true;
      caeEventBus.$emit(BurnerDrillingEnums.ExpandAll);      
    };

    reader.onerror = () => {
      store.commit('errorState/setError', { error: 'Upload Failed: Could not read file.' });
    };
  }
  
  private async mapPairFuelLines(): Promise<void> {
    const referenceKeys = {};
    this.headers.forEach((header) => {
      referenceKeys[header.name] = header.headerKey;
    });
    // tslint:disable-next-line:forin
    for (const item in this.pairFuelLines) {
      this.pairFuelLines[item].referenceKey = referenceKeys[this.pairFuelLines[item].referenceKey as string];
    }
    // Set the 3 way valve and the pair fuel line
    this.headers.forEach((header, headerIndex) => {
      const headerReference = `header-${headerIndex}`;
      const headerElement = this.$refs[headerReference] as Header[];
      if (this.pairFuelLines[header.name]?.isThreeWayValue ) {
        Vue.set(header, 'checked', this.pairFuelLines[header.name].isThreeWayValue);
        headerElement[0].setThreeWayValve(this.pairFuelLines[header.name].referenceKey as string, 
        this.pairFuelLines[header.name].isThreeWayValue as boolean);
      }
    });
  }

  private async mapJsonToDrilling(json: any): Promise<void> {
    // store copies of data in case of reset
    this.previousDrilling = JSON.parse(JSON.stringify(this.selectedBurner.burnerDetail.drilling));
    // reset before mapping
    this.pairFuelLines = {};
    
    const newHeaders: IDrillingViewModel[] = [];
    const jsonHeaders = json.PyFuelCalc.Burners.Header;
    if (HelperMethods.isNullOrUndefined(jsonHeaders)) {
      return;
    }
    const headers = Array.isArray(jsonHeaders)
      ? jsonHeaders.filter((h) => !HelperMethods.isNullOrUndefined(h) && h !== '')
      : jsonHeaders;
    if (headers.length) {
      headers.forEach((header: { Name: string, IsThreeWayValve: string, 
        ThreeWayValveOtherHeader: string, Grouping: string }) => {
        const newHeader: IDrillingViewModel = {
          headerKey: uuid(),
          name: header.Name,
          isCriticalFuelLine: true,
          grouping: header.Grouping,
          manifolds: this.mapJsonToManifolds(header),
        };

        if (header?.IsThreeWayValve != null) {
          this.pairFuelLines[header.Name] = {};
          this.pairFuelLines[header.Name].isThreeWayValue = (header.IsThreeWayValve.toLowerCase() === 'true');
          this.pairFuelLines[header.Name].referenceKey = header.ThreeWayValveOtherHeader;
        }

        newHeaders.push(newHeader);
      });
    } else {
      const newHeader: IDrillingViewModel = {
        headerKey: uuid(),
        name: headers.Name,
        isCriticalFuelLine: true,
        grouping: '',
        manifolds: this.mapJsonToManifolds(headers),
      };
      newHeaders.push(newHeader);
    }

    // headers is now new headers and drilling in state is empty
    this.headers = newHeaders;
    this.selectedBurner.burnerDetail.drilling = [];
    this.selectedBurner.burnerDetail.burnerLayout = this.selectedBurner.burnerDetail.burnerLayout
      .map((value) => value).filter((item) => item.type !== ControlTypes.FuelLine);
  }

  private mapJsonToManifolds(header: any): IManifoldViewModel[] {
    const manifoldsArray: IManifoldViewModel[] = [];
    const jsonManifolds = header.Manifolds.Manifold;
    if (HelperMethods.isNullOrUndefined(jsonManifolds)) {
      return [];
    }
    const manifolds = Array.isArray(jsonManifolds)
      ? jsonManifolds.filter((m) => !HelperMethods.isNullOrUndefined(m) && m !== '')
      : jsonManifolds;
    if (manifolds.length) {
      manifolds.forEach((manifold: any) => {
        const doubleDropValue = this.convertToBoolean(manifold.Double_Drop);
        const doubleDropDiameterValue = this.getPortSize(manifold.Double_Drop_Diameter);
        const diameterValue = manifold.Diameter;
        const newManifold: IManifoldViewModel = {
          doubleDrop: doubleDropValue,
          doubleDropDiameter: doubleDropDiameterValue,
          doubleDropDiameterUnit: manifold.Double_Drop_Diameter_DisplayUnit,
          doubleDropCd: manifold.Double_Drop_Cd,
          diameter: diameterValue,
          diameterUnit: manifold.Diameter_DisplayUnit,
          name: manifold.Name,
          tips: this.mapJsonToTips(manifold)
        };
        manifoldsArray.push(newManifold);
      });
    } else {
      const doubleDropValue = this.convertToBoolean(manifolds.Double_Drop);
      const doubleDropDiameterValue = this.getPortSize(manifolds.Double_Drop_Diameter);
      const diameterValue = manifolds.Diameter;
      const newManifold: IManifoldViewModel = {
        doubleDrop: doubleDropValue,
        doubleDropDiameter: doubleDropDiameterValue,
        doubleDropDiameterUnit: manifolds.Double_Drop_Diameter_DisplayUnit,
        doubleDropCd: manifolds.Double_Drop_Cd,
        diameter: diameterValue,
        diameterUnit: manifolds.Diameter_DisplayUnit,
        name: manifolds.Name,
        tips: this.mapJsonToTips(manifolds)
      };
      manifoldsArray.push(newManifold);
    }

    return manifoldsArray;
  }

  private mapJsonToTips(manifold: any): ITipViewModel[] {
    const tipsArray: ITipViewModel[] = [];
    const jsonTips = manifold.Tips.Tip;
    if (HelperMethods.isNullOrUndefined(jsonTips)) {
      return [];
    }
    const tips = Array.isArray(jsonTips)
      ? jsonTips.filter((t) => !HelperMethods.isNullOrUndefined(t) && t !== '')
      : jsonTips;
    if (tips.length) {
      tips.forEach((tip: any) => {
        const doubleDropValue = this.convertToBoolean(tip.Double_Drop);
        const doubleDropDiameterValue = this.getPortSize(tip.Double_Drop_Diameter);
        const diameterValue = tip.Diameter;
        const newTip = {
          cd: tip.Cd,
          number: tip.Number,
          doubleDrop: doubleDropValue,
          doubleDropCd: tip.Double_Drop_Cd,
          doubleDropDiameter: doubleDropDiameterValue,
          doubleDropDiameterUnit: tip.Double_Drop_Diameter_DisplayUnit,
          diameter: diameterValue,
          diameterUnit: tip.Diameter_DisplayUnit,
          name: tip.Name,
          ports: this.mapJsonToPorts(tip)
        };
        tipsArray.push(newTip);
      });
    } else {
      const doubleDropValue = this.convertToBoolean(tips.Double_Drop);
      const doubleDropDiameterValue = this.getPortSize(tips.Double_Drop_Diameter);
      const diameterValue = tips.Diameter;
      const newTip = {
        cd: tips.Cd,
        number: tips.Number,
        doubleDrop: doubleDropValue,
        doubleDropCd: tips.Double_Drop_Cd,
        doubleDropDiameter: doubleDropDiameterValue,
        doubleDropDiameterUnit: tips.Double_Drop_Diameter_DisplayUnit,
        diameter: diameterValue,
        diameterUnit: tips.Diameter_DisplayUnit,
        name: tips.Name,
        ports: this.mapJsonToPorts(tips)
      };
      tipsArray.push(newTip);
    }
    return tipsArray;
  }

  private mapJsonToPorts(tip: any): IPortViewModel[] {
    const portsArray: IPortViewModel[] = [];
    const jsonPorts = tip.Ports.Port;
    if (HelperMethods.isNullOrUndefined(jsonPorts)) {
      return [];
    }
    const ports = Array.isArray(jsonPorts)
      ? jsonPorts.filter((p) => !HelperMethods.isNullOrUndefined(p) && p !== '')
      : jsonPorts;
    if (ports.length) {
      ports.forEach((port: any) => {
        const diameterValue = this.getPortSize(port.Diameter);
        const newPort = {
          number: port.Number,
          diameter: diameterValue,
          diameterUnit: port.Diameter_DisplayUnit,
          name: port.Name
        };
        portsArray.push(newPort);
      });
    } else {
      const diameterValue = this.getPortSize(ports.Diameter);
      const newPort = {
        number: ports.Number,
        diameter: diameterValue,
        diameterUnit: ports.Diameter_DisplayUnit,
        name: ports.Name
      };
      portsArray.push(newPort);
    }

    return portsArray;
  }

  private async applyAllChanges(): Promise<void> {
    this.headers.forEach((header, headerIndex) => {
      const headerReference = `header-${headerIndex}`;
      const headerElement = this.$refs[headerReference] as Header[];
      headerElement[0].applyAll();
    });
    this.updateBurnerLayout('add');
    await this.saveSelectedAsset();
    this.showReviewMessage = false;
    caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
  }

  private resetAll(): void {
    this.headers = this.previousDrilling;
    caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    this.showReviewMessage = false;
    caeEventBus.$emit(BurnerDrillingEnums.CollapseAll);
  }

  private upsertHeaderInState(config: any): void {
    const headerToUpdate = this.findHeaderInState(config.headerKey);
    if (headerToUpdate) {
      Object.assign(headerToUpdate, config);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    } else {
      const newHeader = config;
      newHeader.manifolds = [];
      this.selectedBurner.burnerDetail.drilling.push(newHeader);
      caeEventBus.$emit(BurnerDrillingEnums.UpdateApplyButtons);
    }
  }

  private findHeaderInState(key: string): IDrillingViewModel {
    return this.selectedBurner.burnerDetail.drilling.find((header) => {
      return header.headerKey === key;
    });
  }

  private convertToBoolean(value: string): boolean {
    return !HelperMethods.isNullOrUndefined(value) && value.toLowerCase() === 'true';
  }

  private getPortSize(value: number): string {
    if (HelperMethods.isNullOrUndefined(value)) {
      return null;
    }
    const roundedValue = Math.round(value * 100000000) / 100000000;
    for (const portSize of portSizesMeters) {
      if (portSize >= roundedValue) {
        return portSize.toString();
      }
    }
    return value.toString();
  }

  private updateBurnerLayout(type: string, header?: IDrillingViewModel): void {
    if (this.hasBurnerLayout) {
      if (type === 'add') {
        store.commit('assetState/updateBurnerLayoutWithAirRegisterOrFuelLines', ControlTypes.FuelLine);
      } else {
        store.commit('assetState/updateBurnerLayoutWithDeleteAirRegisterFuelLineChanges', header.headerKey);
      }
    }
    this.updateSelectedAsset();
  }

  private updateSelectedAsset(): void {
    store.commit('assetState/updateSelectedAssetWithBurner');
  }

  private hideModal(): void {
    this.showModal = false;
  }

  private updateBurner(): void {
    this.selectedBurner.burnerDetail.drilling = this.headers;
    this.updateBurnerLayout('add');
    store.commit('assetState/updateSelectedBurner', this.selectedBurner);
  }
}
