
import { Component, Vue } from 'vue-property-decorator';
import { IAssetBundle, IAssetExportResponse } from '@/components/asset-services/models/asset-interfaces';
import Loading from '@/components/common/Loading.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import HelperMethods from '@/shared/helper-methods';
import store, { getAssetStoreModule } from '@/store';
import AssetModule from '@/store/asset-service-store/asset-store';
import { showError } from '@/utils/StoreHelper';

@Component({
  name: 'export-modal',
  components: {
    Loading,
    OnpointModal
  }
})
export default class ExportModal extends Vue {
  private showModal: boolean = false;
  private exporting: boolean = false;
  private hasUnsavedChanges: boolean = false;

  private get assetStore(): AssetModule {
    return getAssetStoreModule(store);
  }

  private get assetName(): string {
    return this.assetStore.selectedAssetContext.entity.name?.value;
  }

  private get assetKey(): string {
    return this.assetStore.selectedAssetContext.entityId;
  }

  private close(): void {
    this.showModal = false;
    this.exporting = false;
  }

  private showExportModal(): void {
    const currentAsset = this.assetStore.selectedAssetContext?.entity;
    if (HelperMethods.isNullOrUndefined(this.assetStore.originalAsset)) {
      this.hasUnsavedChanges = true;
    } else {
      const currentStringified = JSON.stringify(currentAsset);
      const oldStringified = JSON.stringify(this.assetStore.originalAsset);
      this.hasUnsavedChanges = currentStringified !== oldStringified;
    }
    this.showModal = true;
  }

  private async saveAndExport(): Promise<void> {
    this.exporting = true;
    if (this.hasUnsavedChanges) {
      // save asset
      const selectedAssetContext = this.assetStore.selectedAssetContext;
      const bundle: IAssetBundle = {
        asset: selectedAssetContext,
        entities: [],
        relationships: []
      };
      await this.assetStore.saveAsset(bundle);
      this.assetStore.setOriginalAsset(selectedAssetContext.entity);
    }
    this.assetStore
      .exportSelectedAsset()
      .then((response: IAssetExportResponse) => {
        if (!HelperMethods.isArrayEmpty(response.failures)) {
          const errors = response.failures.map((failure) => failure.reason);
          const failureString = errors.join(', ');
          showError(failureString);
        } else {
          const blob = new Blob([response.fileContents], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const fileName = this.assetName ? `${this.assetName}.csv` : `${this.assetKey}.csv`;
          link.download = fileName;
          link.click();
          this.$bvToast.toast(`Asset exported to ${fileName}.`, {
            title: this.$t('toast.exportSuccess').toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true
          });
          this.close();
        }
      })
      .catch((err) => showError(err))
      .finally(() => (this.exporting = false));
  }
}
