import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import {
  IAssetBundle,
  IAssetBundleFailure,
  IAssetBundleResponse,
  IAssetInstanceContext,
  IAssetInstanceFull,
  IAssetBundleContextResponse,
  IAssetNode,
  IAssetExportResponse,
  IAssetImportResponse,
  IAssetImportRequest,
  IAssetSaveRequest
} from '@/components/asset-services/models/asset-interfaces';
import HelperMethods from '@/shared/helper-methods';
import { AssetService } from '@/components/asset-services/services/asset-service';
import { AssetState } from '@/components/asset-services/models/asset-enums';
import { TranslateResult } from 'vue-i18n';

@Module({ stateFactory: true, name: 'assetStore', namespaced: true, store })
export default class AssetModule extends VuexModule {
  private assetService: AssetService;
  public assetContexts: IAssetInstanceContext[] = [];
  public selectedAssetContext: IAssetInstanceContext = null;
  public originalAsset: IAssetInstanceFull = null;
  public selectedNode: IAssetNode = null;
  public assetErrors: IAssetBundleFailure[] = [];
  public assetsLoadingMessage: string = null;

  @Mutation
  public setAssetContexts(contexts: IAssetInstanceContext[]): void {
    this.assetContexts = contexts;
  }

  @Mutation
  public setAssetsLoadingMessage(message: string | TranslateResult): void {
    this.assetsLoadingMessage = message?.toString();
  }

  @Mutation
  public setAssetErrors(errors: IAssetBundleFailure[]): void {
    this.assetErrors = errors.map((error) => {
      return { ...error, relationship: { ...error.relationship } };
    });
  }

  @Mutation
  public setSelectedNode(node: IAssetNode): void {
    this.selectedNode = node ? { ...node } : null;
  }

  @Mutation
  public setSelectedAsset(asset: IAssetInstanceContext): void {
    this.selectedAssetContext = asset;
  }

  @Mutation
  public setOriginalAsset(asset: IAssetInstanceFull): void {
    this.originalAsset = asset != null ? JSON.parse(JSON.stringify(asset)) : null;
  }

  @Mutation
  public removeAsset(assetId: string): void {
    const index = this.assetContexts.findIndex((item) => item.entityId === assetId);
    this.assetContexts.splice(index, 1);
  }

  @Action({ rawError: true })
  public async initAssetService(assetService?: AssetService): Promise<void> {
    if (assetService) {
      this.assetService = assetService;
      return;
    }
    if (this.assetService == null) {
      const conf = await ConfigFactory.GetConfig();
      this.assetService = new AssetService(
        sharedAxiosInstance.sharedCaeAxiosInstance,
        process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL
          ? process.env.VUE_APP_CUSTOMER_ASSET_EDITOR_API_BASE_URL
          : conf.get('apiBaseUrl')
      );
    }
  }

  @Action({ rawError: true })
  public async loadAssets(customerKey: string): Promise<IAssetInstanceContext[]> {
    await this.initAssetService();
    const response = await this.assetService.getAssetsByCustomer(customerKey, 'Normal');
    this.setAssetContexts(response.entities);
    return response.entities;
  }

  @Action({ rawError: true })
  public async loadAsset(assetId: string): Promise<IAssetInstanceContext> {
    await this.initAssetService();
    const assetBundle = await this.assetService.getAssetBundle(assetId);

    const selectedAssetContext = assetBundle.hierarchy.asset;
    this.setSelectedAsset(assetBundle.hierarchy.asset);
    const originalAsset = selectedAssetContext.entity;
    this.setOriginalAsset(originalAsset);
    this.setSelectedNode(null);

    return assetBundle.hierarchy.asset;
  }

  @Action({ rawError: true })
  public async saveAsset(context: IAssetBundle): Promise<IAssetBundleResponse> {
    await this.initAssetService();
    const request: IAssetSaveRequest = {
      hierarchy: context
    };
    const response = await this.assetService.save(request);
    const hierarchy = response.hierarchy;
    this.setOriginalAsset(hierarchy.asset.entity);
    this.setSelectedAsset(hierarchy.asset);
    this.setSelectedNode(null);
    return response;
  }

  @Action({ rawError: true })
  public async validateAsset(context: IAssetBundle): Promise<IAssetBundleResponse> {
    await this.initAssetService();
    return this.assetService.validate(context);
  }

  @Action({ rawError: true })
  public async publishAsset(assetId: string): Promise<IAssetBundleResponse> {
    await this.initAssetService();
    const publishBundle = await this.assetService.publish(assetId);
    if (HelperMethods.isArrayEmpty(publishBundle.failures)) {
      this.selectedAssetContext.entityState = AssetState.Published;
    }
    return publishBundle;
  }

  @Action({ rawError: true })
  public async deleteAsset(assetId: string): Promise<void> {
    await this.initAssetService();
    return this.assetService.deleteAsset(assetId);
  }

  @Action({ rawError: true })
  public async cloneAsset(cloneRequest: { assetId: string; newName: string }): Promise<IAssetBundleContextResponse> {
    await this.initAssetService();
    return this.assetService.cloneAsset(cloneRequest.assetId, cloneRequest.newName);
  }

  @Action({ rawError: true })
  public async exportSelectedAsset(): Promise<IAssetExportResponse> {
    await this.initAssetService();
    return this.assetService.export(this.selectedAssetContext.entityId);
  }

  @Action({ rawError: true })
  public async importToSelectedAsset(file: File): Promise<IAssetImportResponse> {
    const text = await file.text();
    await this.initAssetService();
    const request: IAssetImportRequest = {
      assetId: this.selectedAssetContext.entityId,
      fileContents: text
    };
    const response = await this.assetService.import(request);
    const hierarchy = response.hierarchy;
    if (hierarchy != null) {
      this.setSelectedAsset(hierarchy.asset);
      const originalAsset = hierarchy.asset.entity;
      this.setOriginalAsset(originalAsset);
      this.setSelectedNode(null);
    }
    return response;
  }

  @Action({ rawError: true })
  public async cancelAll(): Promise<void> {
    await this.initAssetService();
    this.assetService.cancelAll();
  }

  @Action({ rawError: true })
  public async cancel(tokens: string[]): Promise<void> {
    await this.initAssetService();
    this.assetService.cancel([...tokens]);
  }
}
