
import { Component, Vue } from 'vue-property-decorator';
import BlueprintDetailsStep from '@/components/asset-services/blueprint-edit/blueprint-details-step.vue';
import BlueprintPropertiesStep from '@/components/asset-services/blueprint-edit/blueprint-properties-step.vue';
import { getDefaultCrumbs } from '@/components/asset-services/bread-crumb-routes';
import { BlueprintEditStep, BlueprintState } from '@/components/asset-services/models/blueprint-enums';
import { IBlueprint, IGetBlueprintRequest } from '@/components/asset-services/models/blueprint-interfaces';
import BreadCrumb, { IBreadCrumbItem } from '@/components/common/bread-crumb.vue';
import Loading from '@/components/common/Loading.vue';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import { NavigationStepConfig } from '@/components/common/navigation/navigation-step';
import NavigationSteps from '@/components/common/navigation/navigation-steps.vue';
import StepProgress from '@/components/common/step-progress/step-progress.vue';
import store, { getBlueprintStoreModule } from '@/store';
import BlueprintModule from '@/store/asset-service-store/blueprint-store';

@Component({
  name: 'blueprint-edit-page',
  components: {
    BlueprintDetailsStep,
    BlueprintPropertiesStep,
    BreadCrumb,
    Loading,
    LoadingOverlay,
    NavigationSteps,
    StepProgress
  }
})
export default class BlueprintEditPage extends Vue {
  private BlueprintEditStep = BlueprintEditStep;
  private currentStep: BlueprintEditStep = BlueprintEditStep.Details;
  private navigationSteps: NavigationStepConfig[] = [];
  private isLoading: boolean = false;

  private get blueprintStore(): BlueprintModule {
    return getBlueprintStoreModule(store);
  }

  private get blueprint(): IBlueprint {
    return this.blueprintStore.selectedBlueprint;
  }

  private get breadCrumbs(): IBreadCrumbItem[] {
    const currentPageTitle = this.blueprint?.title;
    const currentPage: IBreadCrumbItem = { text: currentPageTitle };
    return [
      ...getDefaultCrumbs(),
      {
        text: this.$t('assetService.title', { action: '' }).toString(),
        to: '/asset-services/blueprint'
      },
      currentPage
    ];
  }

  private async created(): Promise<void> {
    // Fetch blueprints list if user is directly routing to create/edit
    if (this.blueprintStore.blueprints.length === 0) {
      this.isLoading = true;
      await this.blueprintStore.fetchBlueprintSchema().finally(() => (this.isLoading = false));
    }
    const key = this.$route.params.blueprintKey;
    const state = this.$route.params.blueprintState as BlueprintState;
    const requestPayload: IGetBlueprintRequest = {
      blueprintIdentifier: key,
      blueprintState: state
    };
    this.isLoading = true;
    const loadedBlueprint = this.blueprintStore.blueprints.find(
      (blueprint) => blueprint.title === requestPayload.blueprintIdentifier
    );
    this.blueprintStore.setSelectedBlueprint(loadedBlueprint);
    this.isLoading = false;
  }

  private async selectStep(index: BlueprintEditStep): Promise<void> {
    this.currentStep = index;
  }

  private stepMounted(stepIndex: BlueprintEditStep): void {
    const stepConfig = new NavigationStepConfig();
    stepConfig.title = this.getStepTitle(stepIndex);
    stepConfig.tagLine = null;
    stepConfig.index = stepIndex;
    const index = this.navigationSteps.findIndex((s) => s.index === stepIndex);
    if (index >= 0) {
      this.navigationSteps.splice(index, 1, stepConfig);
    } else {
      this.navigationSteps.push(stepConfig);
    }
  }

  private getStepTitle(index: BlueprintEditStep): string {
    switch (index) {
      case BlueprintEditStep.Details:
        return 'Details';
      case BlueprintEditStep.Properties:
        return 'Properties';
      default:
        return null;
    }
  }
}
