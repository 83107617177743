
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { ISolverCustomFieldViewModel } from '../../view-models/flow-network-view-model';
import {showError} from '@/utils/StoreHelper';
import { IAsset } from '@/view-models/assets-view-models';

Vue.use(BootstrapVue);

@Component
export default class RemoveKey extends Vue {
  @Prop({required: true})
  private selectedAsset: IAsset;
  @Prop({required: true})
  private todelete: ISolverCustomFieldViewModel;

  private async deleteKey(): Promise<void> {
    if (this.selectedAsset && this.selectedAsset.flowNetwork &&
      this.selectedAsset.flowNetwork.options) {
      const index = this.selectedAsset.flowNetwork.options.indexOf(this.todelete, 0);
      if (index > -1) {
        this.selectedAsset.flowNetwork.options.splice(index, 1);
        try {
          this.$emit('removeKey', this.selectedAsset);
        } catch (err) {
          showError(err.message);
        }
      }
    }
    this.$bvModal.hide('removeOption');
  }
}
