
import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { ValidationField, validateForm } from '../../utils/Validator';
import { BootstrapVue } from 'bootstrap-vue';
import Loading from '../common/Loading.vue';
import { showError } from '@/utils/StoreHelper';

Vue.use(BootstrapVue);

@Component({
  components: { Loading }
})
export default class EditAsset extends Vue {
  private service = store.state.assetState.service;
  private selectedAsset = store.state.assetState.selectedAsset;
  private eqName = '';
  private emName = '';
  private isSaving = false;
  private validateForm = validateForm;
  private validations: { [key: string]: ValidationField } = {
    eqName: {
      valid: true,
      warning: false,
      value: () => this.getEqName(),
      validator: (value: string) => value !== '',
      setWarning: () => false,
    },
    emName: {
      valid: true,
      warning: false,
      value: () => this.getEmName(),
      validator: (value: string) => value !== '' && value.length <= 64,
      setWarning: () => false,
    },
  };

  public mounted(): void {
    if (this.selectedAsset) {
      this.eqName = this.selectedAsset.equipmentName;
      this.emName = this.selectedAsset.emberName;
    } else {
      this.$router.push('/');
    }
  }

  private getEqName(): string {
    return this.eqName;
  }

  private getEmName(): string {
    return this.emName;
  }

  private async save(): Promise<void> {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    if (this.selectedAsset) {
      if (this.validateForm(this.validations, Object.keys(this.validations)) && this.service) {
        const prevValues = {
          equipmentName: this.selectedAsset.equipmentName,
          emberName: this.selectedAsset.emberName,
        };
        this.selectedAsset.equipmentName = this.eqName;
        this.selectedAsset.emberName = this.emName;
        try {
          await store.dispatch('assetState/saveSelectedAsset', this.selectedAsset);
          store.commit('assetState/updateSelectedAsset', this.selectedAsset.key);
          store.state.navState.prevNavKey = 'asset-edit';
          await this.$router.push('asset-details');
        } catch (err) {
          // Need to reset selected asset equipmentName and emberName with previous values
          this.selectedAsset.equipmentName = prevValues.equipmentName;
          this.selectedAsset.emberName = prevValues.emberName;
          showError(err.message);
        }
      } else {
        let errorMessage = 'Form Validation Failed: Please input a valid value for all fields.';
        if (!this.validations.emName.valid) {
          errorMessage += ' Ember Name must be less than 64 characters.';
        }
        store.commit('errorState/setError', {
          error: errorMessage,
          config: {
            sendLog: false,
          },
          uiError: true,
        });
      }
    }
    this.isSaving = false;
  }

  private cancel(): void {
    if (!this.isSaving) {
      this.$router.push(store.state.navState.prevNavKey);
      store.state.navState.prevNavKey = 'asset-edit';
    }
  }
}
