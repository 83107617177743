
import { Vue, Component, Emit, Prop, Model } from 'vue-property-decorator';
import { NavigationStepConfig } from '@/components/common/navigation/navigation-step';

@Component({
  name: 'navigation-steps'
})
export default class NavigationSteps extends Vue {
  @Model()
  private steps: NavigationStepConfig[];
  @Prop({ required: true })
  private currentStepIndex: number;

  private get orderedSteps(): NavigationStepConfig[] {
    return this.steps.slice().sort((a: NavigationStepConfig, b: NavigationStepConfig) => {
      return a.index - b.index;
    });
  }

  private isActiveStep(index: number): boolean {
    return index === this.currentStepIndex;
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private selectStep(index: number): void {}
}
