
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'bread-crumb'
})
export default class BreadCrumb extends Vue {
  @Prop({ required: true })
  private breadCrumbItems: IBreadCrumbItem[];
}

export interface IBreadCrumbItem {
  text: string;
  to?: string;
  href?: string;
  active?: boolean;
}
