
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import Dropdown from '@/components/utility/Dropdown.vue';
import DisplaySubGroups from '@/components/subGroups/DisplaySubGroups.vue';
import { ISubGroup } from '@/view-models/burner-view-model';
import HelperMethods from '@/shared/helper-methods';
import { SubGroupTypes } from '@/enums/subGroupTypes';

@Component({
  name: 'sub-groups',
  components: {
    Dropdown,
    DisplaySubGroups
  }
})
export default class SubGroups extends Vue {
  get subGroups(): ISubGroup[] {
    return store.state.assetState.selectedBurner.burnerDetail.subGroups;
  }

  get hasSubGroups(): boolean {
    return this.subGroups && this.subGroups.length === 0;
  }

  get hasCreateSubGroup(): boolean {
    return this.subGroups.some((group) => HelperMethods.isStringEmpty(group.key));
  }

  get selectedBurnerName(): string {
    return store.state.assetState.selectedBurner.burnerName;
  }

  public addSubGroup(): void {
    this.subGroups.unshift({
      key: '',
      name: '',
      type: SubGroupTypes.None,
      parentSubGroupKey: '',
      parentSubGroupName: '',
      children: []
    });
    store.commit('navState/updateBurnerTabEnabled', false);
  }
}
