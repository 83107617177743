
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component
export default class SearchBox extends Vue {
  @Model('change')
  private value: string;
  @Prop({ required: true })
  private items: string[];
  @Prop({ required: true })
  private header: string;
  @Prop({ required: true })
  private placeholder: string;
  @Prop({ required: true })
  private addItemSelection: boolean;
  @Prop({ default: true })
  private validState: boolean;

  private showSearch: boolean = false;
  private searchQuery: string = '';
  private localValue: string = null;

  private get filteredItems(): string[] {
    return this.items.filter((item, index) => {
      return item.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 && this.items.indexOf(item) === index;
    });
  }

  private searchOff(): void {
    this.searchQuery = '';
    this.showSearch = false;
  }

  private searchOn(): void {
    this.showSearch = true;
  }

  private selectItem(item: string): void {
    this.localValue = item;
    this.searchOff();
  }

  private selectItemAfterEnter(target: HTMLElement): void {
    if (this.items.indexOf(this.searchQuery) >= 0) {
      this.selectItem(this.searchQuery);
      this.searchOff();
    } else {
      this.addItem();
    }
    target.blur();
  }

  private addItem(): void {
    if (
      this.searchQuery !== '' &&
      this.addItemSelection &&
      this.items.filter((x) => x === this.searchQuery).length < 1
    ) {
      if (this.items) {
        this.items.push(this.searchQuery);
      } else {
        this.items = [this.searchQuery];
      }
      this.localValue = this.searchQuery;
    }
    this.searchOff();
  }

  @Watch('value', { immediate: true })
  private onValueChange(): void {
    this.localValue = this.value;
  }

  @Watch('localValue', { immediate: true })
  private onLocalValueChange(): void {
    this.$emit('change', this.localValue);
  }
}
