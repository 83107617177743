import BaseService from './base-service';
import Axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { IAsset, IEquipmentKey } from '../view-models/assets-view-models';

export class CustomerAssetService extends BaseService {
  private cancelToken: CancelTokenSource;

  constructor(axios: AxiosInstance, caeApiUrl: string) {
    super(caeApiUrl, axios);
  }

  public async generateEquipmentKeyPair(): Promise<IEquipmentKey> {
    return this.httpClient.get<IEquipmentKey>('assets/equipment-keypair');
  }

  public async getActiveCustomerAssets(customerKey: string): Promise<IAsset[]> {
    return this.httpClient.get<IAsset[]>('customers/' + customerKey + '/asset-details');
  }

  public async saveAsset(asset: IAsset): Promise<IAsset> {
    let options;
    if (this.cancelToken != null) {
      this.cancelToken.cancel();
    }

    if (Axios?.CancelToken) {
      this.cancelToken = Axios.CancelToken.source();
      options = {
        cancelToken: this.cancelToken.token
      };
    }

    return this.httpClient.post<IAsset>('assets', asset, options);
  }
}
