
import {Vue, Component, Prop, Emit, Watch, Model} from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IDrillingViewModel } from '@/view-models/burner-view-model';
import HelperMethods from '@/shared/helper-methods';

Vue.use(BootstrapVue);

@Component
export default class TextEntry extends Vue {
  @Prop({ required: true })
  private header: string;
  @Prop({ required: true })
  private placeholder: string;
  @Prop({ default: 'text' })
  private type: string;
  @Prop()
  private formatter: any;
  @Prop({ default: true })
  private validState: boolean;
  @Prop({ default: false })
  private warningState: boolean;
  @Prop({ default: false})
  private showRequired: boolean;
  @Prop()
  private headersList: IDrillingViewModel[];
  @Prop()
  private currentHeader: IDrillingViewModel;

  @Model('change')
  private selected: any;

  private selectedItem: any = null;
  private errorMessage: string = '';
  private showErrorMessage: boolean = false;


  private get formattedSelectedItem(): any {
    if (HelperMethods.isNullOrUndefined(this.selectedItem)) {
      return null;
    }
    if (this.type === 'number') {
      return Number.parseFloat(this.selectedItem);
    }
    return this.selectedItem;
  }

  private validateInput(): void {
    if (this.header === 'HEADER NAME') {
      const nameIndex = this.headersList.findIndex((item) => {
        if (item.headerKey !== this.currentHeader.headerKey) {
          return HelperMethods.equalsIgnoreCase(item.name ? item.name : '', this.selectedItem ? this.selectedItem : '');
        }
      });
      if (this.selectedItem === '') {
        this.showErrorMessage = true;
        this.errorMessage = 'Fuel Header name is required';
      } else if (nameIndex > -1) {
        this.showErrorMessage = true;
        this.errorMessage = 'Fuel Header name already exists';
      } else {
        this.showErrorMessage = false;
      }
    } else if (this.selectedItem === '') {
      this.showErrorMessage = true;
      switch (this.header) {
        case 'MANIFOLD NAME':
          this.errorMessage = 'Manifold name is required';
          break;
        case 'TIP NAME':
          this.errorMessage = 'Tip name is required';
          break;
        case 'PORT NAME':
          this.errorMessage = 'Port name is required';
          break;
        default:
          this.showErrorMessage = false;
          break;
      }
    } else {
      this.showErrorMessage = false;
    }
  }

  @Watch('selectedItem')
  @Watch('validState')
  private checkInput(): void {
    this.validateInput();
    this.$emit('change', this.formattedSelectedItem);
  }

  @Watch('selected', { immediate: true, deep: true })
  private onSelectedChange(): void {
    this.selectedItem = this.selected == null ? null : JSON.parse(JSON.stringify(this.selected));
  }

  @Emit('change')
  private change(): void { /***/ }
}
